import IconCmp from '../ui/Icon'
import startIcon from '../../assets/img/startJourneyIcon.svg'
import * as redux from '../../redux'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import format from 'date-fns/format'
import { useNavigate, useParams } from 'react-router-dom'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { SectionTitle, SectionSmallBold, SectionText } from '../Text'
import {  spaces } from '../../theme'
import endIcon from '../../assets/img/endJourneyIcon.svg'
import arrowRight from '../../assets/img/arrowRight.svg'
import { useTheme } from '@mui/material'

const JourneyItem = ({ journey, index }: { journey, index?: number }) => {
    const navigate = useNavigate()
    const theme = useTheme()
    const { search } = useLocation()
    let params = useParams()
    const vehicleId = parseInt(params.vehicleId || '0')
    const start = journey.start
    const stop = journey.stop

    const startAddress = useSelector(
        redux.positions.selectors.getForCoordinates({ lat: start.lat, lng: start.lng })
    )

    const stopAddress = useSelector(
        redux.positions.selectors.getForCoordinates({ lat: stop.lat, lng: stop.lng })
    )

    const onItemClick = React.useCallback(() => {
        if(search && search.length > 0) {
            navigate(`/${vehicleId}/journeys/${journey.journey_id}/filter${search}`)
        } else {
            navigate(`${journey.journey_id}`)
        }
    }, [journey.journey_id, navigate, search, vehicleId])


    return (
        <div
            onClick={onItemClick}
            style={{
                flexDirection  : 'row',
                alignItems     : 'center',
                justifyContent : 'space-between',
                padding        : spaces.headerLine[1],
                display        : 'flex',
                borderBottom   : `1px solid ${theme.custom.journeyPointsDivider.border}`,
                cursor         : 'pointer',
                flex           : 1,
            }}>
            <div style={{ flexDirection: 'row', alignItems: 'center', width: '95%' }}>
                <div style={{
                    display       : 'flex',
                    flexDirection : 'column',
                }}>
                    <div style={{
                        display       : 'flex',
                        flexDirection : 'row',
                        alignItems    : 'center',
                    }}>
                        <IconCmp
                            source={startIcon}
                            size={26}
                            alt={''}
                        />
                        <SectionTitle
                            sx={{
                                marginLeft: `${spaces.textLine[0]}px`,
                            }}
                        >
                            {format(new Date(start.moment), 'HH:mm')}
                        </SectionTitle>
                        <div style={{
                            display      : 'inline-block',
                            whiteSpace   : 'nowrap',
                            textOverflow : 'ellipsis',
                            overflow     : 'hidden',
                            paddingRight : spaces.textLine[0],
                            marginLeft   : spaces.textLine[0],
                        }}>
                            <SectionText
                                sx={{
                                    color: theme.palette.secondary.main,
                                }}
                            >
                                {!isEmpty(startAddress) ?
                                    startAddress?.display_name :
                                    redux.i18n.t('(unknown address)')}
                            </SectionText>
                        </div>
                    </div>
                    <div style={{
                        height          : spaces.headerLine[1],
                        width           : 2,
                        backgroundColor : theme.custom.journeyPointsDivider.color,
                        marginTop       : spaces.textLine[2],
                        marginBottom    : spaces.textLine[2],
                        marginLeft      : 12,
                    }} />
                    <div style={{
                        display       : 'flex',
                        flexDirection : 'row',
                        alignItems    : 'center',
                    }}>
                        <div style={{
                            position   : 'relative',
                            alignItems : 'center',
                        }}>
                            <IconCmp
                                source={endIcon}
                                size={26}
                                alt={''}
                            />
                            <div
                                style={{
                                    position       : 'absolute',
                                    alignSelf      : 'center',
                                    alignItems     : 'center',
                                    justifyContent : 'center',
                                    width          : '100%',
                                    zIndex         : 9999,
                                    top            : 6,
                                }}
                            >
                                <SectionSmallBold
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                >
                                    {index}
                                </SectionSmallBold>
                            </div>
                        </div>
                        <SectionTitle
                            sx={{
                                marginLeft: `${spaces.textLine[0]}px`,
                            }}
                        >
                            {format(new Date(stop.moment), 'HH:mm')}
                        </SectionTitle>
                        <div style={{
                            display      : 'inline-block',
                            whiteSpace   : 'nowrap',
                            textOverflow : 'ellipsis',
                            overflow     : 'hidden',
                            paddingRight : spaces.textLine[0],
                            marginLeft   : spaces.textLine[0],
                        }}>
                            <SectionText
                                sx={{
                                    color: theme.palette.secondary.main,
                                }}
                            >
                                {!isEmpty(stopAddress) ?
                                    stopAddress?.display_name :
                                    redux.i18n.t('(unknown address)')}
                            </SectionText>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <IconCmp
                    source={arrowRight}
                    size={16}
                    alt={''}
                />
            </div>
        </div>
    )
}

export default JourneyItem