import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { VehicleModel } from '@safefleet/react-common'
import * as React from 'react'
import azimuth from '../assets/img/azimuth.svg'
import { getStatus } from '../hooks/vehicle'
import { useTheme } from '@mui/material/styles'
import { colorFromVehicleStatus } from '../utils'
import { get } from 'lodash'

const K_WIDTH = 130
const K_HEIGHT = 28

type VehicleMarkerProps = {
    vehicle: VehicleModel,
    lat: number,
    lng: number,
    onClick: (arg0:number) => any,
}

const VehicleMarker: React.FC<VehicleMarkerProps> = ({ vehicle, onClick }) => {
    const cInfo = vehicle.current_info
    const status = getStatus(vehicle)
    const theme = useTheme()
    const backgroundColor = colorFromVehicleStatus(status,theme)

    if(!cInfo){
        return null
    }
    const rotateDegree = get(vehicle, 'current_info.azimuth', 0) - 45

    //TODO: improve styling
    const minHeight = "28px"
    const borderRadius = "8px"
    const minWidth = "130px"

    return (
        <Box
            key={vehicle.vehicle_id}
            className='vehicle-marker'
            sx={{
                position   : 'absolute',
                minWidth   : K_WIDTH,
                minHeight  : K_HEIGHT,
                left       : -K_WIDTH / 2,
                top        : -K_WIDTH / 2 + 13,
                backgroundColor,
                borderRadius,
                boxShadow  : "rgba(100, 100, 111, 0.3) 0px 7px 29px 0px",
                alignItems : 'center',
            }}
        >
            <div
                style={{
                    position       : 'absolute',
                    alignSelf      : 'center',
                    bottom         : -13,
                    marginLeft     : K_WIDTH / 2 - 10,
                    transform      : 'rotate(45deg)',
                    alignItems     : 'center',
                    justifyContent : 'center',
                }}
            >
                <span style={{
                    fontSize : "20px",
                    color    : backgroundColor,
                }}>&#9698;</span>
            </div>
            <Button
                sx={{
                    minWidth,
                    minHeight,
                    backgroundColor,
                    borderRadius,
                    justifyContent: 'flex-start',
                }}
                onClick={() => {onClick(vehicle.vehicle_id)}}
            >
                <img
                    src={azimuth}
                    loading="lazy"
                    alt={azimuth}
                        //TODO: REPLACE THIS WITH FONT
                    style={{
                        width     : 13,
                        filter    : "invert(100%) brightness(500%)",
                        transform : `rotate(${rotateDegree}deg)`,
                    }}


                />
                <Typography
                    variant="subtitle2"
                    sx={{ paddingLeft: "5px",flex: 1,color: 'white' }}
                >
                    {vehicle.license_plate}
                </Typography>
            </Button>
        </Box>
    )

}


export default VehicleMarker
