import { SectionText } from '../components/Text'
import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import constants from '../constants'
import * as RX from '../redux'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { spaces } from '../theme'
import { i18n } from '../redux'
import MapOptionsButton from './MapOptionsButton'

const CheckBoxLabeled = ({ value = false, handleChange, label }) => {
    return (
        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
            <Checkbox
                checked={value}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            <SectionText
                sx={{
                    marginLeft: `${spaces.textLine[0]}px'`,
                }}
            >
                {label}
            </SectionText>
        </div>
    )
}

type JourneysOptionsProps = {

}

const JourneysOptions: React.FC<JourneysOptionsProps> = () => {
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const [showAllEventsValue, setShowAllEvents] = React.useState(false)
    const [showAllContactsValue, setShowAllContacts] = React.useState(false)
    const selectedEvents: any = useSelector(RX.events.selectors.getSelectedEvents)
    const selectedContacts: any = useSelector(RX.contacts.selectors.getSelectedContacts)
    const showJourneysPoiValue = useSelector(RX.context.selectors.getShowJourneysPois)
    const journeysSliderValues = useSelector(RX.context.selectors.getJourneysSliderValues)
    const startDate = new Date(journeysSliderValues[2])
    const minValue = new Date(journeysSliderValues[2]).setSeconds(startDate.getSeconds() + journeysSliderValues[0])
    const maxValue = new Date(journeysSliderValues[2]).setSeconds(startDate.getSeconds() + journeysSliderValues[1])
    const vehicleId = pathname.split('/')[1]

    const showAllEvents = React.useCallback(() => {
        const newValues = []
        constants.eventsType.forEach((event) => {
            if(!showAllEventsValue) {
                dispatch({
                    type    : RX.events.actions.GET_EVENTS,
                    payload : {
                        vehicleId,
                        startMoment : new Date(minValue),
                        stopMoment  : new Date(maxValue),
                        type        : event.type,
                    },
                })
            }

            if(!showAllEventsValue) {
                newValues[event.type] = true
            } else {
                newValues[event.type] = false
            }

            return true
        })

        dispatch(RX.events.actions.setSelectedEvents(newValues))

        setShowAllEvents(!showAllEventsValue)
    }, [dispatch, maxValue, minValue, showAllEventsValue, vehicleId])

    const showAllContacts = React.useCallback(() => {
        const newValues: any = []
        constants.contactsType.forEach((event) => {
            if(!showAllEventsValue) {
                dispatch({
                    type    : RX.contacts.actions.GET_CONTACTS,
                    payload : {
                        vehicleId,
                        startMoment : new Date(minValue),
                        stopMoment  : new Date(maxValue),
                        type        : event.id,
                    },
                })
            }

            if(!showAllContactsValue) {
                newValues[event.id] = true
            } else {
                newValues[event.id] = false
            }

            return true
        })

        dispatch(RX.contacts.actions.setSelectedContacts(newValues))

        setShowAllContacts(!showAllContactsValue)
    }, [dispatch, maxValue, minValue, showAllContactsValue, showAllEventsValue, vehicleId])

    const handleChange = React.useCallback((type, event) => {
        const newValues: any = selectedEvents
        newValues[type] = event.target.checked
        dispatch(RX.events.actions.setSelectedEvents(newValues))

        if(event.target.checked) {
            dispatch({
                type    : RX.events.actions.GET_EVENTS,
                payload : {
                    vehicleId,
                    startMoment : new Date(minValue),
                    stopMoment  : new Date(maxValue),
                    type,
                },
            })
        }
    }, [dispatch, maxValue, minValue, selectedEvents, vehicleId])

    const handleChangeContacts = React.useCallback((type, event) => {
        const newValues: any = selectedContacts
        newValues[type] = event.target.checked

        dispatch(RX.contacts.actions.setSelectedContacts(newValues))

        if(event.target.checked) {
            dispatch({
                type    : RX.contacts.actions.GET_CONTACTS,
                payload : {
                    vehicleId,
                    startMoment : new Date(minValue),
                    stopMoment  : new Date(maxValue),
                    type,
                },
            })
        }
    }, [dispatch, maxValue, minValue, selectedContacts, vehicleId])

    const handleChangeShowPois = React.useCallback(() => {
        dispatch(RX.context.actions.setShowJourneysPois(!showJourneysPoiValue))
    }, [dispatch, showJourneysPoiValue])

    return (
        <MapOptionsButton
            height="75vh"
        >
            <CheckBoxLabeled
                label={i18n.t('Show all events')}
                value={showAllEventsValue}
                handleChange={showAllEvents}
            />
            <div
                style={{
                    marginLeft : spaces.headerLine[1],
                    columns    : '2',
                }}
            >
                {
                    constants.eventsType.map((event) => {
                        return (
                            <CheckBoxLabeled
                                label={event.label}
                                value={selectedEvents?.[event.type] || false}
                                handleChange={(e) => handleChange(event.type, e)}
                            />
                        )
                    })
                }
            </div>
            <CheckBoxLabeled
                label={i18n.t('Show all contacts')}
                value={showAllContactsValue}
                handleChange={showAllContacts}
            />
            <div
                style={{
                    marginLeft : spaces.headerLine[1],
                    columns    : '2',
                    alignItems : 'flex-start',
                }}
            >
                {
                    constants.contactsType.map((event) => {
                        return (
                            <CheckBoxLabeled
                                label={event.label}
                                value={selectedContacts?.[event.id] || false}
                                handleChange={(e) => handleChangeContacts(event.id, e)}
                            />
                        )
                    })
                }
            </div>
            <CheckBoxLabeled
                label={i18n.t('Show POIs')}
                value={showJourneysPoiValue}
                handleChange={handleChangeShowPois}
            />
        </MapOptionsButton>
    )
}

export default JourneysOptions
