import React from 'react'
import { useSelector } from 'react-redux'
import { UserUnits } from '@safefleet/react-common/src/enums'
import * as RX from '../redux'
import * as constants from '../constants'

export function useUserUnit() {
    const userId = useSelector(RX.context.selectors.getUserId)
    const user = useSelector(RX.users.selectors.getForID(userId || 0))
    const [unit, setUnit] = React.useState<UserUnits>(user?.units || 1)

    React.useEffect(() => {
        setUnit(user?.units || 1)
    }, [user?.units])

    const toUserSpeed = (speed: number | undefined, precision = 2) => {
        /* Since null == undefined is true, the following statements will catch both null and undefined */
        if (speed == null) {
            return RX.i18n.t('N/A')
        }

        return `${RX.commonUtils.speedToUserUnit(speed, unit, precision)}
        ${RX.i18n.t(constants.userUnitSpeedUnit[unit])}`
    }
    const toUserDistance = (distance: number | undefined, precision = 2) => {
        /* Since null == undefined is true, the following statements will catch both null and undefined */
        if (distance == null) {
            return RX.i18n.t('N/A')
        }

        return `${RX.commonUtils.distanceToUserUnit(distance, unit, precision)} ${
            RX.i18n.t(constants.userUnitDistUnit[unit])}`
    }

    return { toUserSpeed, toUserDistance }
}

export function useSelectedUser() {
    const userId = useSelector(RX.context.selectors.getUserId)
    const user = useSelector(RX.users.selectors.getForID(userId || 0))

    return user
}
