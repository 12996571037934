import * as React from 'react'
import { styled } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputBase from '@mui/material/InputBase'
import ListItemText from '@mui/material/ListItemText'
import Icon from '../ui/Icon'
import carsIcon from '../../assets/img/cars.svg'
import { useSelector,useDispatch  } from 'react-redux'
import * as RX from '../../redux'
import * as RC from '@safefleet/react-common'
import { style } from '../Text'
import { Theme } from '@mui/material/styles'
import { spaces } from '../../theme'

type FleetDesc = {
    id: number,
    name: string,
    level: number,
}

function extractFleets(fleets: RC.FleetStructureModel[],level: number): FleetDesc[]{
    return fleets.reduce((acc: FleetDesc[],x: RC.FleetStructureModel)=>{
        return [...acc,{ id: x.fleet_id,name: x.name,level },...extractFleets(x.fleets,level+1)]
    },[])
}

const useCompanyIfNoFleet = (
    companyStructure: RC.CompanyStructureModel | undefined
) => {
    return React.useMemo(()=>{
        if(companyStructure?.fleets.length){
            return companyStructure.fleets
        }else if(companyStructure){

            return  [{
                fleet_id    : companyStructure.company_id,
                vehicle_ids : [],
                fleets      : [],
                name        : companyStructure.name,
            }]
        }
    },[companyStructure])
}
const useFleets = () => {
    const fleetId = useSelector(RX.context.selectors.getFleetId)
    const companyId = useSelector(RX.context.selectors.getCompanyId)
    const myCompanyId = useSelector(RX.companyStructures.selectors.getMyCompanyId)

    const companyStructure = useSelector(RX.companyStructures.selectors.getForID(
        companyId === 'mine' ? (myCompanyId || undefined) : companyId
    ))

    const fleets = useCompanyIfNoFleet(companyStructure)

    return React.useMemo(()=>{
        if(!fleets ){
            return { elements: [],fleetDescs: [],selectedId: null }
        }
        const fleetDescs = extractFleets(fleets,0)
        const elements = fleetDescs.map(
            (x)=>(
                <MenuItem value={x.id} key={x.id} >
                    <Icon
                        style={{ marginLeft: `${x.level * 30}px`,marginRight: spaces.textLine[0] }}
                        source={carsIcon}
                        size={20}
                        alt={'cars'}
                    />
                    <ListItemText sx={(theme) => style(theme).sectionSmall}>
                        {x.name}
                    </ListItemText>
                </MenuItem>
            )
        )
        return {
            elements,
            fleetDescs,
            selectedId: fleetId === 'mine'? fleetDescs[0].id: (
                fleetDescs.some((x)=>x.id === fleetId) ? fleetId : null
            ),
        }
    },[fleetId,fleets])
}

const BootstrapInput = styled(InputBase)(({ theme }) => styles.inputBase(theme))

export default function FleetSelector() {
    const { elements,selectedId,fleetDescs } = useFleets()
    const dispatch = useDispatch()

    const changeFleet = (
        event: { target: { value: string | number | null  } }
    ) => {
        if(typeof event.target.value == 'string' ){
            return
        }
        dispatch( RX.context.actions.setFleetId(event.target.value || 'mine'))
    }

    return (
        <div>
            <FormControl sx={styles.formControl} variant="standard">
                <Select
                    id="demo-customized-select-native"
                    value={selectedId || ''}
                    onChange={changeFleet}
                    input={<BootstrapInput />}
                    renderValue={()=>{
                        if(selectedId == null)
                            return <span />
                        return  <span>{fleetDescs.find((x) => x.id === selectedId)?.name}</span>
                    }}
                > {elements} </Select>
            </FormControl>
        </div>
    )
}
const styles = {
    formControl: {
        width: '100%',
    },
    inputBase: (theme: Theme) => ({
        'label + &': {
            width: '100%',
        },
        '& .MuiInputBase-input': {
            "width"           : '100%',
            "position"        : 'relative',
            "backgroundColor" : 'transparent',
            "transition"      : theme.transitions.create(['border-color', 'box-shadow']),
            'textAlign'       : 'left',
            "padding"         : '0px',
            ...style(theme).title,
            '&:focus'         : {
                backgroundColor: 'transparent',
            },
        },
    }),
}

