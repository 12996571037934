import '../../stylesheets/css/Filters.css'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import React from 'react'
import IconCmp from '../ui/Icon'
import closeIcon from '../../assets/img/closeIcon.svg'
import { Calendar } from "react-modern-calendar-datepicker"
import { Title, SectionTitle, SectionSmallGray, SectionTextGray, SectionSmall } from '../Text'
import subDays from 'date-fns/subDays'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import subWeeks from 'date-fns/subWeeks'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import subMonths from 'date-fns/subMonths'
import startOfYear from 'date-fns/startOfYear'
import endOfYear from 'date-fns/endOfYear'
import subYears from 'date-fns/subYears'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import { TextField, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import * as redux from '../../redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { DriverModel } from '@safefleet/react-common'
import format from 'date-fns/format'
import { setFiltersPanelStatus } from '../../redux/context/actions'
import { spaces } from '../../theme'


const renderPeriodLabel = (label, action, period, theme) => {
    return (
        <div
            onClick={action}
            style={{
                border          : `1px solid ${theme.custom.periodSelector.border}`,
                width           : '100%',
                padding         : spaces.textLine[1],
                cursor          : 'pointer',
                backgroundColor : period === label ? theme.custom.periodSelector.backgroundColor :
                    theme.custom.periodSelector.textColorActive,
            }}>
            <SectionSmall
                sx={{
                    color: period === label ? theme.custom.periodSelector.textColorActive :
                        theme.custom.periodSelector.backgroundColor,
                }}
            >
                {label}
            </SectionSmall>
        </div>
    )
}

const PeriodLabelContainer = ({ children }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1 }}>
            {children}
        </div>
    )
}

const PeriodsSelctor = ({ setPeriod, period }) => {
    const startDay = startOfDay(new Date())
    const endDay = endOfDay(new Date())
    const startYesterday = subDays(startDay, 1)
    const endYestarday = subDays(endDay, 1)
    const startWeek = startOfWeek(new Date())
    const endWeek = endOfWeek(new Date())
    const startLastWeek = subWeeks(startWeek, 1)
    const endLastWeek = subWeeks(endWeek, 1)
    const startMonth = startOfMonth(new Date())
    const endMonth = endOfMonth(new Date())
    const startLastMonth = subMonths(startMonth, 1)
    const endLastMonth = endOfMonth(subMonths(endMonth, 1))
    const startYear = startOfYear(new Date())
    const endYear = endOfYear(new Date())
    const startLastYear = subYears(startYear, 1)
    const endLastYear = subYears(endYear, 1)

    const theme = useTheme()
    return (
        <div style={{
            display        : 'flex',
            flexDirection  : 'row',
            alignItems     : 'center',
            justifyContent : 'space-between',
        }}>
            <PeriodLabelContainer>
                {renderPeriodLabel(redux.i18n.t('Today'), () =>
                    setPeriod(
                        startDay,
                        endDay,
                        'Today'
                    ), period, theme)}
                <div style={{ marginTop: spaces.textLine[0] }} />
                {renderPeriodLabel(redux.i18n.t('Yesterday'), () =>
                    setPeriod(
                        startYesterday,
                        endYestarday,
                        'Yesterday'
                    ), period, theme)}
            </PeriodLabelContainer>
            <PeriodLabelContainer>
                {renderPeriodLabel(redux.i18n.t('This Week'), () =>
                    setPeriod(
                        startWeek,
                        endWeek,
                        'This Week'
                    ), period, theme)}
                <div style={{ marginTop: spaces.textLine[0] }} />
                {renderPeriodLabel(redux.i18n.t('Last Week'), () =>
                    setPeriod(
                        startLastWeek,
                        endLastWeek,
                        'Last Week'
                    ), period, theme)}
            </PeriodLabelContainer>
            <PeriodLabelContainer>
                {renderPeriodLabel(redux.i18n.t('This Month'), () =>
                    setPeriod(
                        startMonth,
                        endMonth,
                        'This Month'
                    ), period, theme)}
                <div style={{ marginTop: spaces.textLine[0] }} />
                {renderPeriodLabel(redux.i18n.t('Last Month'), () =>
                    setPeriod(
                        startLastMonth,
                        endLastMonth,
                        'Last Month'
                    ), period, theme)}
            </PeriodLabelContainer>
            <PeriodLabelContainer>
                {renderPeriodLabel(redux.i18n.t('This Year'), () =>
                    setPeriod(
                        startYear,
                        endYear,
                        'This Year'
                    ), period, theme)}
                <div style={{ marginTop: spaces.textLine[0] }} />
                {renderPeriodLabel(redux.i18n.t('Last Year'), () =>
                    setPeriod(
                        startLastYear,
                        endLastYear,
                        'Last Year'
                    ), period, theme)}
            </PeriodLabelContainer>
        </div>
    )
}

type FiltersProps = {
    drivers: {
        [x: string]: DriverModel;
        [x: number]: DriverModel;
    },
    driver: DriverModel | null,
    workingSchedule: string | null,
    journeyPurpose: string | null,
    minimumDuration: string | null,
    minimumDistance: string | null,
    handleDriverChange: (event: any) => void,
    handleWorkingScheduleChange: (event: any) => void,
    handleJourneyPurposeChange: (event: any) => void,
    handleMinimumDistanceChange: (event: any) => void,
    handleMinimumDurationChange: (event: any) => void,
    generateReport: (event: any) => void
}


const Selectors = (props: FiltersProps) => {
    const {
        drivers,
        driver,
        workingSchedule,
        journeyPurpose,
        minimumDuration,
        minimumDistance,
        handleDriverChange,
        handleWorkingScheduleChange,
        handleJourneyPurposeChange,
        handleMinimumDistanceChange,
        handleMinimumDurationChange,
    } = props

    return (
        <div
            style={{
                padding    : spaces.headerLine[2],
                paddingTop : 0,
            }}
        >
            <div style={{
                display        : 'flex',
                flexDirection  : 'row',
                alignItems     : 'center',
                justifyContent : 'space-between',
            }}>
                <SectionTitle>
                    {redux.i18n.t('Driver')}
                </SectionTitle>
                <div>
                    <FormControl variant="standard"
                        sx={{ m: 1, width: 220, height: 40 }}
                    >
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-simple-select-standard"
                            value={driver}
                            onChange={handleDriverChange}
                        >
                            {
                                Object.values(drivers).map((driver) => {
                                    if (driver?.driver_id !== null &&
                                        driver?.name !== null) {
                                        return (
                                            <MenuItem value={driver?.driver_id}>{driver?.name}</MenuItem>
                                        )
                                    }

                                    return []
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div style={{
                display        : 'flex',
                flexDirection  : 'row',
                alignItems     : 'center',
                justifyContent : 'space-between',
                marginTop      : -10,
            }}>
                <SectionTitle>
                    {redux.i18n.t('Working Schedule')}
                </SectionTitle>
                <div>
                    <FormControl
                        variant="standard"
                        sx={{ m: 1, width: 220, height: 40 }}
                    >
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={workingSchedule}
                            onChange={handleWorkingScheduleChange}
                            MenuProps={{ disableScrollLock: true }}
                        >
                            <MenuItem value={'all'}>--------</MenuItem>
                            <MenuItem value={'during'}>{redux.i18n.t('During the working schedule')}</MenuItem>
                            <MenuItem value={'outside'}>{redux.i18n.t('Outside the working schedule')}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div style={{
                display        : 'flex',
                flexDirection  : 'row',
                alignItems     : 'center',
                justifyContent : 'space-between',
                marginTop      : -10,
            }}>
                <SectionTitle>
                    {redux.i18n.t('Journey Purpose')}
                </SectionTitle>
                <div>
                    <FormControl
                        variant="standard"
                        sx={{ m: 1, width: 220, height: 40 }}
                    >
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={journeyPurpose}
                            onChange={handleJourneyPurposeChange}
                        >
                            <MenuItem value={'all'}>{`(${redux.i18n.t('Journey Purpose')})`}</MenuItem>
                            <MenuItem value={'p'}>{redux.i18n.t('Private Purpose')}</MenuItem>
                            <MenuItem value={'b'}>{redux.i18n.t('Business Purpose')}</MenuItem>
                            <MenuItem value={'none'}>{`(${redux.i18n.t('none')})`}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div style={{
                display        : 'flex',
                flexDirection  : 'row',
                alignItems     : 'center',
                justifyContent : 'space-between',
                marginTop      : -10,
            }}>
                <SectionTitle>
                    {redux.i18n.t('Minimum distance')}
                </SectionTitle>
                <div>
                    <FormControl
                        variant="standard"
                        sx={{
                            m             : 1,
                            width         : 220,
                            height        : 40,
                            display       : 'flex',
                            flexDirection : 'row',
                            alignItems    : 'center',
                        }}
                    >
                        <TextField
                            id="outlined-basic"
                            variant="standard"
                            value={minimumDistance}
                            onChange={(event) =>
                                handleMinimumDistanceChange(event.target.value)
                            }
                        />
                        <div style={{ width: 60 }}>
                            <SectionTextGray
                            >
                                {redux.i18n.t('meters')}
                            </SectionTextGray>
                        </div>
                    </FormControl>
                </div>
            </div>
            <div style={{
                display        : 'flex',
                flexDirection  : 'row',
                alignItems     : 'center',
                justifyContent : 'space-between',
                marginTop      : -10,
            }}>
                <SectionTitle>
                    {redux.i18n.t('Minimum duration')}
                </SectionTitle>
                <div>
                    <FormControl
                        variant="standard"
                        sx={{
                            m             : 1,
                            width         : 220,
                            height        : 40,
                            display       : 'flex',
                            flexDirection : 'row',
                            alignItems    : 'center',
                        }}
                    >
                        <TextField
                            id="outlined-basic"
                            //label="Minimum distance"
                            variant="standard"
                            value={minimumDuration}
                            onChange={(event) =>
                                handleMinimumDurationChange(event.target.value)
                            }
                        />
                        <SectionTextGray
                        >
                            {redux.i18n.t('seconds')}
                        </SectionTextGray>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

const Filters = () => {
    const today = React.useMemo(() => new Date(), [])

    const [selectedDayRange, setSelectedDayRange]: any = React.useState({
        from: {
            year  : parseInt(format(today, 'yyyy')),
            month : parseInt(format(today, 'MM')),
            day   : parseInt(format(today, 'dd')),
        },
        to: {
            year  : parseInt(format(today, 'yyyy')),
            month : parseInt(format(today, 'MM')),
            day   : parseInt(format(today, 'dd')),
        },
    })


    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [period, setStatePeriod] = React.useState('Today')

    const setPeriod = React.useCallback((startDate, endDate, period) => {
        setSelectedDayRange({
            from: {
                year  : parseInt(format(startDate, 'yyyy')),
                month : parseInt(format(startDate, 'MM')),
                day   : parseInt(format(startDate, 'dd')),
            },
            to: {
                year  : parseInt(format(endDate, 'yyyy')),
                month : parseInt(format(endDate, 'MM')),
                day   : parseInt(format(endDate, 'dd')),
            },
        })

        setStatePeriod(period)
    }, [])

    const drivers = useSelector(redux.drivers.selectors.getAll)
    const [driver, setDriver] = React.useState<DriverModel | null>(null)
    const [workingSchedule, setWorkingSchedule] = React.useState('all')
    const [journeyPurpose, setJourneyPurpose] = React.useState('all')
    const [minimumDuration, setMinimumDuration] = React.useState(null)
    const [minimumDistance, setMinimumDistance] = React.useState(null)

    const handleDriverChange = React.useCallback((event) => {
        setDriver(event.target.value)
    }, [])

    const handleWorkingScheduleChange = React.useCallback((event) => {
        setWorkingSchedule(event.target.value)
    }, [])

    const handleJourneyPurposeChange = React.useCallback((event) => {
        setJourneyPurpose(event.target.value)
    }, [])

    const handleMinimumDurationChange = React.useCallback((value) => {
        setMinimumDuration(value)
    }, [])

    const handleMinimumDistanceChange = React.useCallback((value) => {
        setMinimumDistance(value)
    }, [])

    const clearFilters = React.useCallback(() => {
        setDriver(null)
        setWorkingSchedule('all')
        setJourneyPurpose('all')
        setMinimumDuration(null)
        setMinimumDistance(null)
        setSelectedDayRange({
            from: {
                year  : parseInt(format(today, 'yyyy')),
                month : parseInt(format(today, 'MM')),
                day   : parseInt(format(today, 'dd')),
            },
            to: {
                year  : parseInt(format(today, 'yyyy')),
                month : parseInt(format(today, 'MM')),
                day   : parseInt(format(today, 'dd')),
            },
        })
    }, [today])

    const { pathname } = useLocation()
    const vehicleId = pathname.split('/')[1]

    const closePanel = React.useCallback(() => {
        dispatch(setFiltersPanelStatus(false))
    }, [dispatch])


    const generateReport = React.useCallback(() => {
        const startDate = new Date(selectedDayRange?.from?.year + "-"
        + selectedDayRange?.from?.month + "-"
        + selectedDayRange?.from?.day)

        const endDate = selectedDayRange?.to ?
            new Date(selectedDayRange?.to?.year + "-"
        + selectedDayRange?.to?.month + "-"
        + selectedDayRange?.to?.day) :
            new Date(startDate)

        endDate.setDate(endDate.getDate() + 1)

        const startHour = format(startDate, 'MMM dd yyyy HH:mm')
        const endHour = format(endDate, 'MMM dd yyyy HH:mm')
        let url = `/${vehicleId}/journeys/filter?start_date=${startHour}&end_date=${endHour}`

        if (driver) {
            url += `&driver=${driver}`
        }

        if (workingSchedule && workingSchedule !== 'all') {
            url += `&ws=${workingSchedule}`
        }

        if (journeyPurpose && journeyPurpose !== 'all') {
            url += `&jp=${journeyPurpose}`
        }

        if (minimumDuration) {
            url += `&mduration=${minimumDuration}`
        }

        if (minimumDistance) {
            url += `&mdistance=${minimumDistance}`
        }

        closePanel()
        navigate(url, { replace: true })
    }, [
        driver,
        journeyPurpose,
        minimumDistance,
        minimumDuration,
        navigate,
        selectedDayRange,
        workingSchedule,
        vehicleId,
        closePanel,
    ])

    return (
        <div
            style={{
                position        : 'absolute',
                left            : 510,
                backgroundColor : theme.custom.periodSelector.textColorActive,
                top             : 90,
                display         : 'flex',
                borderRadius    : "5px",
                padding         : spaces.headerLine[0],
                boxShadow       : "0px 2px 10px rgba(0, 0, 0, 0.1)",
                width           : '492px',
                flexDirection   : 'column',
                alignItems      : 'normal',
                overflow        : 'visible',
            }}
        >
            <div
                style={{
                    display        : 'flex',
                    flexDirection  : 'row',
                    alignItems     : 'center',
                    justifyContent : 'space-between',
                    flex           : 1,
                }}
            >
                <Title>
                    {redux.i18n.t('Filters')}
                </Title>
                <div
                    onClick={closePanel}
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <IconCmp
                        source={closeIcon}
                        size={14}
                        alt={''}
                    />
                </div>
            </div>
            <div style={{
                display       : 'flex',
                alignItems    : 'flex-start',
                marginTop     : spaces.headerLine[0],
                flexDirection : 'column',
            }}>
                <SectionTitle>
                    {redux.i18n.t('Dates')}
                </SectionTitle>
                <div
                    style={{
                        alignSelf : 'center',
                        marginTop : spaces.textLine[0],
                        width     : '100%',
                    }}
                >
                    <PeriodsSelctor
                        setPeriod={setPeriod}
                        period={period}
                    />
                </div>
                <div style={{
                    alignSelf : 'center',
                    marginTop : spaces.textLine[0],
                }}>
                    <Calendar
                        value={selectedDayRange}
                        onChange={setSelectedDayRange}
                        colorPrimary={theme.custom.calendar.primary}
                        colorPrimaryLight={theme.custom.calendar.primaryLight}
                        calendarClassName="custom-calendar"
                    />
                </div>
                <Selectors
                    drivers={drivers}
                    driver={driver}
                    workingSchedule={workingSchedule}
                    journeyPurpose={journeyPurpose}
                    minimumDuration={minimumDuration}
                    minimumDistance={minimumDistance}
                    handleDriverChange={handleDriverChange}
                    handleWorkingScheduleChange={handleWorkingScheduleChange}
                    handleJourneyPurposeChange={handleJourneyPurposeChange}
                    handleMinimumDistanceChange={handleMinimumDistanceChange}
                    handleMinimumDurationChange={handleMinimumDurationChange}
                    generateReport={generateReport}
                />
                <div style={{
                    alignItems     : 'center',
                    justifyContent : 'flex-end',
                    display        : 'flex',
                    width          : '100%',
                }}>
                    <div
                        onClick={clearFilters}
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        <SectionSmallGray
                            sx={{
                                marginRight: `${spaces.headerLine[0]}px`,
                            }}
                        >
                            {redux.i18n.t('Reset filters')}
                        </SectionSmallGray>
                    </div>
                    <div
                        onClick={generateReport}
                        style={{
                            paddingTop      : spaces.headerLine[2],
                            paddingBottom   : spaces.headerLine[2],
                            alignItems      : 'center',
                            justifyContent  : 'center',
                            backgroundColor : theme.palette.primary.main,
                            borderRadius    : 8,
                            alignSelf       : 'center',
                            cursor          : 'pointer',
                        }}>
                        <SectionTitle
                            sx={{
                                marginLeft  : `${spaces.headerLine[0]}px`,
                                marginRight : `${spaces.headerLine[0]}px`,
                                color       : theme.custom.periodSelector.textColorActive,
                            }}
                        >
                            {redux.i18n.t('Apply filters')}
                        </SectionTitle>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filters
