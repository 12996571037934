import * as React from 'react'
import * as RC from '@safefleet/react-common'
import { Interval } from '../components/timeline'

const journeyIntersects = ({ start,end }: Interval<Date>) =>{
    const inside = (x: string) => {
        const xd = RC.utils.parseDate(x)
        return xd >= start && xd <= end
    }
    return ({ start:begin,stop }: RC.JourneyModel): boolean =>
        inside(begin.moment) || inside(stop.moment)
}

export const useFilteredJourneysByInterval = (
    journeys: RC.JourneyModel[],
    domain: Interval<Date>
) => React.useMemo(()=>{
    const todayJourney = journeyIntersects(domain)
    return journeys.filter(todayJourney)
},[domain,journeys])

