import * as React from 'react'
import Timeline  from '../timeline'
import * as RC from '@safefleet/react-common'
import { Interval, IntervalLine } from '../timeline'

type IdleTimeLineProps = {
    lines: IntervalLine<number>[],
    domainStarts: number,
    domainEnds: number,
    checkpoint: number,
    headerLabelText: (text:number) => string,
    showLegend?: boolean,
}
export const IdleTimeLine: React.FC<IdleTimeLineProps> = ({
    domainStarts,
    domainEnds,
    checkpoint,
    headerLabelText,
    showLegend,
    lines,
})=>{
    const domain: Interval<number> = React.useMemo(()=>({
        start : domainStarts,
        end   : domainEnds,
    }),[domainStarts,domainEnds])
    const lastWeekIdleProps = {
        lines,
        checkpoint,
        domain,
        headerLabelText,
        reverseLineColor : '#DA2128',
        reverseLineText  : RC.i18n.t('Engine off'),
        showLegend,
    }
    return <Timeline {...lastWeekIdleProps} />
}

export default IdleTimeLine
