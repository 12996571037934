import { SectionSmall } from "./Text"
import * as redux from '../redux'
import Slider from '@mui/material/Slider'
import format from 'date-fns/format'
import { isNumber } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import React from "react"
import { setJourneysFiltersDates } from "../redux/context/actions"
import { spaces } from '../theme'

const JourneysSlider = () => {
    const dispatch = useDispatch()
    const [value, setValue] = React.useState<number[]>([0, 100])
    const journeysSliderValues = useSelector(redux.context.selectors.getJourneysSliderValues)
    const minValue = journeysSliderValues[0]
    const maxValue = journeysSliderValues[1]
    const defaultDate = journeysSliderValues?.[2]

    const sliderDefaultValue = React.useMemo(()=>{
        return isNumber(maxValue) && isNumber(minValue)
            ? [minValue, maxValue]
            : [0,100]
    },[minValue,maxValue])

    React.useEffect(() => {
        if(isNumber(maxValue) && isNumber(minValue)) {
            setValue([minValue, maxValue])
        }
    }, [maxValue, minValue])

    const getSliderDateValues = React.useCallback((period: string) => {
        if(defaultDate) {
            if(period === 'start') {
                const startDate = new Date(defaultDate)
                startDate.setSeconds(startDate.getSeconds() + value[0])

                return startDate
            } else if(period === 'end') {
                const endDate = new Date(defaultDate)
                endDate.setSeconds(endDate.getSeconds() + value[1])

                return endDate
            }
        }

        return new Date()
    }, [defaultDate, value])

    const minDistance = 10

    const handleChange = React.useCallback((
        event: Event,
        newValue: number | number[],
        activeThumb: number
    ) => {

        if (!Array.isArray(newValue)) {
            return
        }

        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 100 - minDistance)
                setValue([clamped, clamped + minDistance])
            } else {
                const clamped = Math.max(newValue[1], minDistance)
                setValue([clamped - minDistance, clamped])
            }
        } else {
            setValue(newValue as number[])

            const startDate = new Date(defaultDate)
            const minDate = new Date(defaultDate).setSeconds(startDate.getSeconds() + value[0])
            const maxDate = new Date(defaultDate).setSeconds(startDate.getSeconds() + value[1])

            dispatch(setJourneysFiltersDates([
                minDate,
                maxDate,
            ]))
        }


    }, [defaultDate, dispatch, value])

    if(!defaultDate) return <div/>

    return (
        <div
            style={{
                position     : 'absolute',
                bottom       : spaces.headerLine[1],
                paddingLeft  : 550,
                width        : '100%',
                paddingRight : 100,
            }}
        >
            <div
                style={{
                    position        : 'relative',
                    width           : '100%',
                    backgroundColor : '#FCFCFC',
                    paddingTop      : spaces.textLine[1],
                    paddingBottom   : spaces.textLine[2],
                    boxShadow       : `0px 2px ${spaces.textLine[0]}px 0px #0000001A`,
                    paddingLeft     : spaces.headerLine[1],
                    paddingRight    : spaces.headerLine[1],
                    borderRadius    : spaces.textLine[1],
                    flexDirection   : 'row',
                    display         : 'flex',
                    alignItems      : 'center',
                    justifyContent  : 'space-between',
                }}
            >
                <SectionSmall
                    sx={{
                        marginRight: `${spaces.headerLine[2]}px`,
                    }}
                >
                    {format(getSliderDateValues('start'), 'yyyy-MM-dd HH:mm')}
                </SectionSmall>
                <Slider
                    getAriaLabel={() => redux.i18n.t('Minimum distance shift')}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="off"
                    disableSwap
                    sx={{
                        width: '75%',
                    }}
                    defaultValue={sliderDefaultValue}
                    min={minValue}
                    max={maxValue}
                />
                <SectionSmall
                    sx={{
                        marginLeft: `${spaces.headerLine[2]}px`,
                    }}
                >
                    {format(getSliderDateValues('end'), 'yyyy-MM-dd HH:mm')}
                </SectionSmall>
            </div>
        </div>
    )
}

export default JourneysSlider