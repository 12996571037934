import { auth } from '@safefleet/react-common'
import * as actions from './actions'
import * as selectors from './selectors'
import { saga } from './saga'
import { reducer } from './reducer'

export const {
    getAuthServer,
    getInstanceServer,
    getSessionId,
    getPending,
    getCredentials,
    getJwtTokens,
    getContext,
    updateAuthServer,
    isAuthenticated,
    getServer,
} = auth

export {
    actions,
    saga,
    reducer,
    selectors,
}
