import { useTheme } from '@mui/material'
import { settings, utils } from '@safefleet/react-common'
import { get, map } from 'lodash'
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { useKeepReference } from '../../hooks/general'
import { useFitBoundsWithPadding, useZoomToObject } from '../../hooks/map'
import * as RX from '../../redux'
import { timeDifference, useGetJourneyPresences } from '../../utils'

const JourneysPolyline = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { pathname } = useLocation()
    const journeyId = pathname.split('/')[3]
    const vehicleId = parseInt(params.vehicleId || '')
    const vehicle = useSelector(RX.vehicles.selectors.getForID(vehicleId))
    const mapRef = useSelector(RX.context.selectors.getMapRef)
    const current_info = vehicle?.current_info
    const fitCoordinatesWithPadding = useFitBoundsWithPadding()
    const currentJourneyPolyline = React.useRef(null as any)

    const journeys = useSelector((state: any) => RX.journeys.selectors.getForVehicleId(true)(state, { vehicleId }))
    const firstJourney = journeys[0]
    const lastJourney = journeys[journeys.length - 1]
    const startMoment = get(lastJourney, 'start.moment', '')
    const stopMoment = get(firstJourney, 'stop.moment', '')
    const currentPresences = useKeepReference(useGetJourneyPresences(vehicle, startMoment, stopMoment))
    const zoomToObject = useZoomToObject()
    const filterDates = useSelector(RX.context.selectors.getJourneysFiltersDates)

    const fetchPresences = React.useCallback(() => {
        if (timeDifference(startMoment, stopMoment) < settings.API_PRESENCES_MAX_INTERVAL) {
            dispatch(RX.presences.actions.fetchPresences({
                vehicleId   : vehicle?.vehicle_id || 0,
                startMoment : utils.parseDate(startMoment),
                stopMoment  : utils.parseDate(stopMoment),
            }))
        }
    }, [dispatch, startMoment, stopMoment, vehicle?.vehicle_id])

    React.useEffect(() => {
        fetchPresences()
    }, [fetchPresences])

    React.useEffect(() => {
        if(mapRef && mapRef?.maps && mapRef?.map) {
            if(currentJourneyPolyline && currentJourneyPolyline?.current && currentJourneyPolyline.current.setMap) {
                currentJourneyPolyline.current.setMap(null)
                currentJourneyPolyline.current = null
            }

            const filteredPresences = (()=> {
                if(!filterDates) return currentPresences
                const minDate = new Date(filterDates[0])
                const maxDate = new Date(filterDates[1])

                return currentPresences.filter((presence) => {
                    const presenceMoment = new Date(presence.moment)

                    return minDate < presenceMoment && presenceMoment < maxDate
                })
            })()

            const coordinates = map(filteredPresences,
                (item) => ({ lat: get(item, 'lat'),  lng: get(item, 'lng') }))


            const carPath = new mapRef.maps.Polyline({
                path          : coordinates,
                geodesic      : true,
                strokeColor   : theme.palette.primary.main,
                strokeOpacity : 1.0,
                strokeWeight  : 10,
                icons         : [{
                    icon: {
                        path        : mapRef.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        scale       : 2,
                        fillOpacity : 0,
                        fillColor   : theme.custom.polyline.arrow,
                        strokeColor : theme.custom.polyline.arrow,
                    },
                    offset : '100%',
                    repeat : '60px',
                }],
            })

            if(!currentJourneyPolyline.current && coordinates.length > 0) {
                currentJourneyPolyline.current = carPath

                carPath.setMap(mapRef.map)
                zoomToObject(carPath)
            }
        }

    }, [
        current_info,
        mapRef,
        journeyId,
        fitCoordinatesWithPadding,
        currentPresences,
        theme.palette.primary.main,
        theme.custom.polyline.arrow,
        zoomToObject,
        filterDates,
    ])

    React.useEffect(() => {
        return () => {
            if(currentJourneyPolyline && currentJourneyPolyline?.current && currentJourneyPolyline.current.setMap) {
                currentJourneyPolyline.current.setMap(null)
                currentJourneyPolyline.current = null
            }
        }
    }, [])
    return null
}

export default JourneysPolyline
