
import { JourneyModel } from "@safefleet/react-common"
import {
    differenceInMilliseconds,
} from 'date-fns'
import format from 'date-fns/format'
import { get } from "lodash"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { usePositions } from "../../hooks/vehicle"
import * as redux from '../../redux'
import JourneyPoiItem from "./JourneyPoiItem"
import { getDurationText } from "./JourneysStatistics"
import { TopCell } from "./VehicleDetails"

const row = {
    display       : 'flex',
    flexDirection : 'row',
} as React.CSSProperties


type TopProps = {
    journey?: JourneyModel,
    startMoment: Date,
    pois: []
}

const Top: React.FC<TopProps> = ({ journey, startMoment, pois }) => {
    return (
        <div style={{ padding: 20 }}>
            <div style={{
                display: 'flex',

            }}>
                <span style={{ fontSize: 16, color: '#222126', fontWeight: 'bold' }}>
                    {
                        format(startMoment, 'EEEE, MMMM dd')
                    }
                </span>
            </div>
            <div style={{ marginTop: 15 }}>
                <JourneyPoiItem
                    journey={journey}
                    pois={pois}
                />
            </div>
        </div>
    )
}


type JourneyInformationProps = {
    durationMs: number,
    idle: number,
    id?: string,
    distance: number,
}

const JourneyInformation: React.FC<JourneyInformationProps> = ({
    durationMs,
    idle,
    id,
    distance,
}) => {
    return (
        <div >
            <div style={row}>
                <TopCell label={redux.i18n.t('Journey ID: ')} value={id} isLeft={true} direction={'row'} />
            </div>
            <div style={{ ...row, marginTop: 12 }}>
                <TopCell label={redux.i18n.t('Duration')} value={getDurationText(durationMs)} isLeft={true} />
                <TopCell
                    label={redux.i18n.t('Travelled distance')}
                    value={distance + " km"}
                    isLeft={false}
                    valueStyle={{ alignSelf: 'center' }} />
                <TopCell label={redux.i18n.t('Idling')} value={getDurationText(idle * 1000)} isLeft={false} />
            </div>
        </div>
    )
}

type DriverInfoProps = {
    purpose: string | undefined,
    driverKey: string,
    driver: string,
    code: string
}

const DriverInfo: React.FC<DriverInfoProps> = ({
    purpose,
    driverKey,
    driver,
    code,
}) => {
    return (
        <div>
            <div style={{ ...row, marginTop: 12 }}>
                <TopCell label={redux.i18n.t('Driver key')} value={driverKey} isLeft={true} />
                <TopCell label={redux.i18n.t('Driver')} value={driver} isLeft={false} />
            </div>
            <div style={{ ...row, marginTop: 12 }}>
                <TopCell label={redux.i18n.t('Purpose')} value={purpose ?? 'N/A'} isLeft={true} />
                <TopCell label={redux.i18n.t('Code')} value={code} isLeft={false} />
            </div>
        </div>
    )
}

const Divider: React.FC = () => {
    return (
        <div style={{ height: 1, backgroundColor: '#F0F0EC', width: '100%' }}/>
    )
}


const JourneyDetails = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const vehicleId = parseInt(params.vehicleId || '0')
    const vehicle = useSelector( redux.vehicles.selectors.getForID( vehicleId || undefined ) )
    const journeyId = params.journeyId || '0'
    const journey = useSelector((state: any) =>
        redux.journeys.selectors.getForID(journeyId || '')(state))
    const startMoment = new Date(journey?.start?.moment || 0)
    const stopMoment = new Date(journey?.stop?.moment || 0)
    const startMomentRaw = get(journey, 'start.moment', '')
    const stopMomentRaw = get(journey, 'stop.moment', '')

    const positions = usePositions(vehicle, startMomentRaw, stopMomentRaw)
    const driver = useSelector(redux.drivers.selectors.getForID(journey?.driver_id || 0))

    const durationMs = Math.abs(differenceInMilliseconds(startMoment, stopMoment))
    const pois = positions.map((item) => {
        return {
            address : item.display_name,
            moment  : item.moment,
        }
    }).filter((item) => {
        return (item.moment !== journey?.start?.moment) && (item.moment !== journey?.stop?.moment)
    })


    React.useEffect(() => {
        if(!journey) {
            setTimeout(() => {
                dispatch(redux.journeys.actions.fetchJourney({
                    vehicleId : vehicleId,
                    journeyId : journeyId,
                }))
            }, 200)
        }
    }, [dispatch, journey, journeyId, vehicleId])

    if(!journey) {
        return null
    }

    return (
        <div>
            <Top
                journey={journey}
                startMoment={startMoment}
                pois={pois}
            />
            <Divider/>
            <div style={{ padding: 15 }}>
                <JourneyInformation
                    durationMs={durationMs}
                    idle={journey?.idle_duration || 0}
                    id={journey?.journey_id}
                    distance={journey?.distance}
                />
            </div>
            {/* <Divider/>
            <div style={{ padding: 20 }}>
                <Odometer/>
            </div>
            <Divider/>
            <div style={{ padding: 20 }}>
                <Fuel/>
            </div> */}
            <Divider/>
            <div style={{ padding: 15 }}>
                <DriverInfo
                    purpose={journey?.purpose}
                    driver={driver?.name || ''}
                    driverKey={driver?.key_code || ''}
                    code={journey?.journey_code || ''}
                />
            </div>
        </div>
    )
}

export default JourneyDetails
