import { Settings } from "@mui/icons-material"
import { Avatar, Divider, ListItemIcon, Menu, MenuItem } from "@mui/material"
import Logout from '@mui/icons-material/Logout'
import { useDispatch } from "react-redux"
import { auth, i18n } from "../redux"

type BarUserMenuProps = {
    open: boolean,
    handleClose: () => any,
    anchorEl: any,
    avatar: string
}

const BarUserMenu: React.FC<BarUserMenuProps> = ({
    open,
    handleClose,
    anchorEl,
    avatar,
}) => {
    const dispatch = useDispatch()
    const logout = () => {
        dispatch(auth.actions.requestLogout())
    }

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation : 0,
                sx        : {
                    "overflow"          : 'visible',
                    "filter"            : 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    "mt"                : 1.5,
                    '& .MuiAvatar-root' : {
                        width  : 32,
                        height : 32,
                        ml     : -0.5,
                        mr     : 1,
                    },
                    '&:before': {
                        content   : '""',
                        display   : 'block',
                        position  : 'absolute',
                        top       : 0,
                        right     : 14,
                        width     : 10,
                        height    : 10,
                        bgcolor   : 'background.paper',
                        transform : 'translateY(-50%) rotate(45deg)',
                        zIndex    : 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem>
                <Avatar src={avatar} />
                {i18n.t('My account')}
            </MenuItem>
            <Divider />
            <MenuItem>
                <ListItemIcon>
                    <Settings fontSize="small" />
                </ListItemIcon>
                {i18n.t('Settings')}
            </MenuItem>
            <MenuItem onClick={logout}>
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                {i18n.t('Logout')}
            </MenuItem>
        </Menu>
    )
}

export default BarUserMenu
