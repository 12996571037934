import { JourneyModel } from "@safefleet/react-common"
import { TopCell } from './VehicleDetails'
import * as redux from '../../redux'
import { getDHMSFromMs, stringFromDHMS } from "../../utils"
import {
    differenceInMilliseconds,
} from 'date-fns'
import { spaces } from '../../theme'

export const getDurationText = (duration: number) => {
    if(!duration) return ""
    return stringFromDHMS( getDHMSFromMs(duration),true )
}

const JourneysStatistics = ({ journeys }: {journeys: Array<JourneyModel>}) => {

    const defaultValues = {
        duration : 0,
        distance : 0,
        idle     : 0,
    }

    const { duration, distance, idle } = (() => {

        const acc = journeys ? journeys.reduce((acc, j) => {
            const startMoment = new Date(j?.start?.moment || '')
            const stopMoment = new Date(j?.stop?.moment || '')
            const durationMs = Math.abs(differenceInMilliseconds(startMoment, stopMoment))
            acc.duration += durationMs
            acc.distance += j.distance
            acc.idle += j.idle_duration || 0

            return acc
        }, defaultValues) : defaultValues

        return acc
    })()

    if(!journeys) return null

    return (
        <div style={{
            display         : 'flex',
            position        : 'absolute',
            bottom          : 0,
            backgroundColor : 'white',
            width           : '100%',
            zIndex          : 99999,
            flexDirection   : 'row',
            justifyContent  : 'center',
            alignItems      : 'center',
            padding         : spaces.textLine[0],
            paddingLeft     : spaces.headerLine[2],
            paddingRight    : spaces.headerLine[2],
            boxShadow       : `0px 2px ${spaces.textLine[0]}px 0px #0000001A`,
        }}>
            <TopCell
                label={redux.i18n.t('Duration')}
                value={getDurationText(duration)}
                isLeft={true}
                style={{ paddingBottom: 0 }}
            />
            <TopCell
                label={redux.i18n.t('Travelled distance')}
                value={Math.round(distance) + " km"}
                isLeft={false}
                valueStyle={{ alignSelf: 'center' }}
                style={{ paddingBottom: 0 }}
            />
            <TopCell
                label={redux.i18n.t('Idling')}
                value={getDurationText(idle * 1000)}
                isLeft={false}
                style={{ paddingBottom: 0 }}
            />
        </div>
    )
}

export default JourneysStatistics