
type IconProps = {
    source: string,
    size: number | string,
    style?: Object,
    alt: string
}

const Icon: React.FC<IconProps> = ({
    source,
    size,
    style,
    alt,
}) => {
    return (
        <img
            loading="lazy"
            style={{
                display : "block",
                width   : size,
                height  : size,
                ...style,
            }}
            src={source}
            alt={alt}
        />
    )
}

export default Icon
