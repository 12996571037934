import * as React from 'react'
import * as RC from '@safefleet/react-common'
import { Interval  } from '../timeline'
import {
    format,
} from 'date-fns'
import * as Utils from '../../utils'
import WorkingTimeLine from './WorkingTimeLine'
import { useFilteredJourneysByInterval } from '../../hooks/journeys'

type DayWorkingTimeLineProps = {
    journeys: RC.JourneyModel[],
    interval: Interval<Date>,
    schedule: Utils.WorkingInterval[],
    showLegend?: boolean,
}
const DayWorkingTimeLine : React.FC<DayWorkingTimeLineProps> = ({
    journeys,
    interval,
    schedule,
    showLegend,
}) => {
    const weekJourneys = useFilteredJourneysByInterval(journeys,interval)
    const start = interval.start.getTime() // - (3600000*18)
    const end = interval.end.getTime()
    return <WorkingTimeLine
        schedule={schedule}
        journeys={weekJourneys}
        domainStarts={start}
        domainEnds={end}
        checkpoint={86400000/6}
        headerLabelText = { (x: number) => format(new Date(x),'HH:mm') }
        showLegend={showLegend}
    />
}

export default DayWorkingTimeLine
