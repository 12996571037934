import { textStyle } from '../Text'
import IconCmp from '../ui/Icon'
import { spaces } from '../../theme'
import { useTheme } from '@mui/material/styles'

type SvgLinkProps = {
    icon: any,
    text: any,
    alt?: string,
    action?: () => any,
}

export const SvgLink: React.FC<SvgLinkProps> = ({ icon, text, alt, action }) => {
    const color = useTheme().palette.primary.main
    return (
        <div onClick={action} style={{
            display       : 'flex',
            flexDirection : 'row',
            alignItems    : 'center',
            cursor        : 'pointer',
            marginRight   : spaces.headerLine[1],
        }}>
            <IconCmp
                source={icon}
                size={20}
                alt={alt || ''}
            />
            <div style={ {
                ...textStyle,
                marginLeft : spaces.textLine[0],
                fontWeight : 'bold',
                color,
            }}>
                {text}
            </div>
        </div>
    )
}