import { isEmpty, last } from "lodash"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import * as redux from '../../redux'
import { parseDate } from '@safefleet/react-common/src/utils'
import SectionedJourneyList from "./SectionedJourneyList"
import { JourneyModel } from "@safefleet/react-common"
import { useQuery } from "../../hooks/router"
import JourneyDetails from "./JourneyDetails"
import LoadingContainer from './LoadingContainer'
import JourneysStatistics from "./JourneysStatistics"

const daysOfWeek = [
    'sun',
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
]

function intervalInsideWs(vehicle, startMoment, stopMoment) {
    startMoment = new Date(startMoment)
    stopMoment = new Date(stopMoment)
    const workingSchedule = vehicle.working_schedule
    const startMomentDayOfWeek = daysOfWeek[startMoment.getDay()]
    const stopMomentDayOfWeek = daysOfWeek[stopMoment.getDay()]

    if (workingSchedule) {
        if (startMomentDayOfWeek === stopMomentDayOfWeek) {
            const workingDay = workingSchedule[startMomentDayOfWeek]

            if (workingDay) {
                const wsStartMoment = new Date(
                    startMoment.getFullYear(),
                    startMoment.getMonth(),
                    startMoment.getUTCDate(),
                    workingDay.from_hour,
                    workingDay.from_minute,
                    0,
                    0
                )

                const wsStopMoment = new Date(
                    stopMoment.getFullYear(),
                    stopMoment.getMonth(),
                    stopMoment.getUTCDate(),
                    workingDay.to_hour,
                    workingDay.to_minute,
                    0,
                    0
                )

                return startMoment >= wsStartMoment &&
                    stopMoment <= wsStopMoment
            }

            return false
        }
    }

    return false
}

function prepareSectionedJourneys(journeys) {
    let sectionedJourneys: any = []
    for (const journey of journeys) {
        const journeyStopDate = parseDate(journey.stop.moment)
        journeyStopDate.setHours(0, 0, 0, 0)

        const lastSectionJourney: any = last(sectionedJourneys)
        const lastSectionDate = !isEmpty(sectionedJourneys) ? lastSectionJourney.date : null

        if (isEmpty(sectionedJourneys) || journeyStopDate.getTime() !== lastSectionDate.getTime()) {
            sectionedJourneys.push({ date: journeyStopDate, data: [] })
        }

        // @ts-ignore
        last(sectionedJourneys).data.push(journey)
    }

    return sectionedJourneys
}



function filterJourneys(journeys: JourneyModel[], filters) {
    const {
        journeyPurpose,
        driver,
        workingSchedule,
        vehicle,
    } = filters

    if (journeys && journeys.length > 0) {
        return journeys.filter((journey) => {
            if (workingSchedule && workingSchedule !== 'all') {
                const duringWS = intervalInsideWs(vehicle, journey.start.moment, journey.stop.moment)
                if (workingSchedule === 'during' && !duringWS) {
                    return false
                } else if (workingSchedule === 'outside' && duringWS) {
                    return false
                }
            }

            if (driver && journey.driver_id !== driver) {
                return false
            }

            if (journeyPurpose && journey.purpose !== journeyPurpose) {
                return false
            }

            return true
        })
    }

    return []
}

const RenderEmpty = () => {
    return (
        <div style={{
            display        : 'flex',
            flexDirection  : 'column',
            alignItems     : 'center',
            justifyContent : 'center',
            marginTop      : 30,
        }}>
            <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                {redux.i18n.t('There are no journeys to display.')}
            </span>
            <span style={{ fontSize: 16 }}>
                {redux.i18n.t('Please retry with other parameters.')}
            </span>
        </div>
    )
}

const FilteredJourneys = () => {
    const query = useQuery()
    const params = useParams()
    const dispatch = useDispatch()
    const journeyId = params.journeyId || ''
    const vehicleId = parseInt(params.vehicleId || '')
    const vehicle = useSelector(redux.vehicles.selectors.getForID(vehicleId || undefined))
    const journeys = useSelector((state: any) =>
        redux.journeys.selectors.getForVehicleId(true)(state, { vehicleId }))
    const startDate = React.useMemo(() => new Date(query.get("start_date") || ''), [query])
    const endDate = React.useMemo(() => new Date(query.get("end_date") || ''), [query])
    const driver = parseInt(query.get("driver") || '')
    const workingSchedule = query.get("ws")
    const journeyPurpose = query.get("jp")
    const minimumDuration = query.get("mduration")
    const minimumDistance = query.get("mdistance")
    const journeysIsGetting = useSelector(redux.journeys.selectors.isPendingForTag(redux.tags.JOURNEY_WITH_ADDRESSES))
    const isInitialized = useSelector(redux.app.selectors.isInitialized)

    const filteredJourneys = filterJourneys(journeys, {
        driver,
        journeyPurpose,
        workingSchedule,
        vehicle,
    })

    React.useEffect(() => {
        if(isInitialized) {
            dispatch(redux.journeys.actions.fetchJourneysWithAddresses({
                vehicleId   : vehicleId || 0,
                startMoment : startDate,
                stopMoment  : endDate,
                minDistance : minimumDistance ? parseInt(minimumDistance) / 1000 : undefined,
                minDuration : minimumDuration ? parseInt(minimumDuration) : undefined,
            }))
        }

    }, [dispatch, endDate, minimumDistance, minimumDuration, startDate, vehicleId, isInitialized])

    if (journeysIsGetting) {
        return (
            <LoadingContainer />
        )
    }


    if(journeyId && filteredJourneys && filteredJourneys.length > 0) {
        return (
            <JourneyDetails/>
        )
    }

    return (
        <div>
            {
                filteredJourneys && filteredJourneys.length > 0 && !journeyId ? (
                    <>
                        <SectionedJourneyList
                            startDate={startDate}
                            endDate={endDate}
                            journeys={prepareSectionedJourneys(filteredJourneys || [])}
                        />
                        <JourneysStatistics journeys={filteredJourneys}/>
                    </>
                ) : (
                    <RenderEmpty/>
                )
            }
        </div>
    )
}

export default FilteredJourneys