import JourneyItem from "./JourneyItem"
import format from 'date-fns/format'
import { spaces } from '../../theme'
import { SectionRegularGray } from '../Text'
import { useTheme } from "@mui/material"

const SectionedJourneyItem = ({ date, data, startNumber }) => {
    const theme = useTheme()

    return (
        <div>
            <div
                style={{
                    display        : 'flex',
                    flexDirection  : 'row',
                    justifyContent : 'space-between',
                    marginTop      : spaces.headerLine[1],
                    alignItems     : 'center',
                    borderBottom   : `1px solid ${theme.custom.journeyPointsDivider.border}`,
                    paddingBottom  : spaces.headerLine[1],
                }}
            >
                <div style={{ marginLeft: 50 }}>
                    <SectionRegularGray>
                        {format(date, 'EEEE, MMMM dd')}
                    </SectionRegularGray>
                </div>
            </div>
            {
                data.map((item, index) => {
                    return (
                        <JourneyItem
                            journey={ item }
                            index= { startNumber + index + 1 }
                        />
                    )
                })
            }
        </div>
    )
}

export default SectionedJourneyItem