/* eslint-disable max-len */
import { useEffect } from 'react'
import './stylesheets/css/App.css'
import Router from './routers'
import WebFont from 'webfontloader'
import { ThemeProvider } from '@mui/material/styles'
import ToastsList from './components/ToastsList'
import DateAdapter from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { theme } from './theme'
import { useDispatch, useSelector } from 'react-redux'
import { refreshToken as refreshAccess, updateInstanceServer } from './redux/auth/actions'
import instancesServer from './utils/instancesServer'
import { getServer } from './redux/auth/selectors'
import { useQuery } from './hooks/router'

function App() {
    const dispatch = useDispatch()
    const query = useQuery()
    const accessToken = query.get('access_token')
    const refreshToken = query.get('refresh_token')
    const instancePrefix: any = window.location.hostname.split('.')[0]
    const baseURL = useSelector((state: any) => getServer(state, { instancePrefix: instancePrefix }))

    useEffect(() => {
        WebFont.load({
            google: {
                families : ['fliGO'],
                urls     : ['./assets/fonts/fliGO.ttf'],
            },
        })

        const prefix = window.location.hostname.split('.')[0]
        const serverUrl = instancesServer?.[prefix]
        const instanceUrl = serverUrl ? `${serverUrl}/safefleet/api/` : null

        dispatch(updateInstanceServer({
            url: instanceUrl ?? baseURL,
        }))

        dispatch(refreshAccess({
            accessToken  : accessToken ?? '',
            refreshToken : refreshToken ?? '',
        }))

        window.history.pushState({}, "", "/")

    }, [dispatch, baseURL, accessToken, refreshToken])

    return (
        <div className="App">
            <LocalizationProvider dateAdapter={DateAdapter}>
                <ThemeProvider theme={theme}>
                    <Router />
                    <ToastsList />
                </ThemeProvider>
            </LocalizationProvider>
        </div>
    )
}

export default App
