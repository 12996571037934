
import { UserUnits } from '@safefleet/react-common/src/enums'
import { i18n } from './redux'

export const DARK_MODE_KEY = 'isDarkMode'
export const REFRESHING_INTERVAL = 60 * 1000  // 1 minute
export const MAP_TYPE = 'mapType'

const msg = (x: string) => {
    return i18n.t(x)
}

export const REQUIRED_FIELD = msg('This field is required')

export type VehicleStatusKey = -1 | 0 | 1

const vehicleStatus: {[keys in VehicleStatusKey]: string;} = {
    '-1' : 'Offline',
    '0'  : 'Off',
    '1'  : 'On',
}

export const userUnitDistUnit: {[keys in UserUnits]: string} = {
    1 : msg('km'),
    2 : msg('nm'),
    3 : msg('mi'),
}
export const userUnitSpeedUnit: {[keys in UserUnits]: string} = {
    1 : msg('km/h'),
    2 : msg('kn'),
    3 : msg('mph'),
}

export type PurposeKey = 'b' | 'p'
const purpose: {[keys in PurposeKey]: string} = {
    b : msg('Business Journey'),
    p : msg('Private Journey'),
}

export type ChargingCableKey = 'off' | 'on'
const chargingCable: {[keys in ChargingCableKey]: string} = {
    off : msg('Unplugged'),
    on  : msg('Plugged'),
}
export type ReportIntervalsKey = 'today' | 'yesterday' | 'thisWeek' | 'lastWeek'
export const reportIntervals: {[keys in ReportIntervalsKey]: string} = {
    today     : msg('Today'),
    yesterday : msg('Yesterday'),
    thisWeek  : msg('This Week'),
    lastWeek  : msg('Last Week'),
}

export type NoDataMessageKey
    = 'vehicles'
    | 'currentPositions'
    | 'journeys'
    | 'currentInfo'
    | 'tooManyPresences'

export const noDataMessage: {[keys in NoDataMessageKey]: string} = {
    vehicles         : msg('There are no vehicles to display.'),
    currentPositions : msg('There are no positions to display.'),
    journeys         : msg('There are no journeys to display.'),
    currentInfo      : msg('There is no information to display.'),
    tooManyPresences : msg('Too many presences. Select a shorter interval.'),
}

export type VehicleTypeIconKeys = keyof (typeof vehicleTypeIcon)
export type VehicleTypeIcon = typeof vehicleTypeIcon[VehicleTypeIconKeys]

const vehicleTypeIcon = {
    1  : ('fl/bicycle' as  'fl/bicycle'),
    2  : ('fl/face-bus' as  'fl/face-bus'),
    3  : ('fl/face-vehicle' as  'fl/face-vehicle'),
    4  : ('fl/helicopter' as  'fl/helicopter'),
    5  : ('fl/motorcycle' as  'fl/motorcycle'),
    6  : ('fl/face-vehicle' as  'fl/face-vehicle'),
    7  : ('fl/plane' as  'fl/plane'),
    8  : ('fl/boat' as  'fl/boat'),
    9  : ('fl/tank' as  'fl/tank'),
    10 : ('fl/tractor' as  'fl/tractor'),
    11 : ('fl/face-train' as  'fl/face-train'),
    12 : ('fl/face-tram' as  'fl/face-tram'),
    13 : ('fl/truck' as  'fl/truck'),
    14 : ('fl/face-truck' as  'fl/face-truck'),
    15 : ('fl/excavator' as  'fl/excavator'),
    16 : ('fl/excavator' as  'fl/excavator'),
    17 : ('fl/excavator' as  'fl/excavator'),
}

export type VehicleTypeKey =keyof (typeof vehicleType)
export type VehicleTypeValues = typeof vehicleType[VehicleTypeKey]

const vehicleType = {
    1  : ('Bike' as  'Bike'),
    2  : ('Bus' as  'Bus'),
    3  : ('Car' as  'Car'),
    4  : ('Helicopter' as  'Helicopter'),
    5  : ('Motorcycle' as  'Motorcycle'),
    6  : ('Personal Tracker' as  'Personal Tracker'),
    7  : ('Plane' as  'Plane'),
    8  : ('Ship' as  'Ship'),
    9  : ('Tank' as  'Tank'),
    10 : ('Tractor' as  'Tractor'),
    11 : ('Train' as  'Train'),
    12 : ('Tram' as  'Tram'),
    13 : ('Truck' as  'Truck'),
    14 : ('Van' as  'Van'),
    15 : ('Excavator' as  'Excavator'),
    16 : ('Armored' as  'Armored'),
    17 : ('Utility' as  'Utility'),
}

const fuelType = [
    'Diesel',
    'Gasoline',
    'Electric',
]


export type MapTypesKey = 'standard' | 'silver' | 'hybrid' | 'dark'

const eventsType: Array<{label: string, type: string}> = [
    {
        label : 'Impact',
        type  : 'impact',
    },
    {
        label : 'Harsh Acceleration',
        type  : 'harsh_acceleration',
    },
    {
        label : 'Harsh Brake',
        type  : 'harsh_brake',
    },
    {
        label : 'Harsh Cornering',
        type  : 'harsh_cornering',
    },
    {
        label : 'Panic',
        type  : 'panic',
    },
    {
        label : 'Overspeeding',
        type  : 'overspeeding',
    },
    {
        label : 'Idling',
        type  : 'idle',
    },
    {
        label : 'No GPS Signal',
        type  : 'no_gps_signal',
    },
    {
        label : 'No GSM Signal',
        type  : 'no_gsm_signal',
    },
    {
        label : 'Geofence Entered',
        type  : 'geofence_entered',
    },
    {
        label : 'Geofence Left',
        type  : 'geofence_left',
    },{
        label : 'Driver Authentication Enabled',
        type  : 'driver_id_enable',
    },{
        label : 'Driver Authentication Disabled',
        type  : 'driver_id_disable',
    },{
        label : 'No Driver Key',
        type  : 'no_driver_id',
    },{
        label : 'Immobilizer Off',
        type  : 'imob_off',
    },{
        label : 'Immobilizer On',
        type  : 'imob_on',
    },{
        label : 'No External Power',
        type  : 'no_external_power',
    },{
        label : 'External Power Reconnected',
        type  : 'external_power_reconnected',
    },{
        label : 'Low External Battery',
        type  : 'low_external_battery',
    },{
        label : 'External Battery Charging',
        type  : 'external_battery_charging',
    },{
        label : 'Device Sabotage',
        type  : 'device_sabotage',
    },{
        label : 'Unauthorized Door Opening',
        type  : 'unauthorized_door_opening',
    },{
        label : 'Unauthorized Tractor Unit Detachment',
        type  : 'unauthorized_tractor_unit_detachment',
    },{
        label : 'Unauthorized Tractor Unit Attachment',
        type  : 'unauthorized_tractor_unit_attachment',
    },{
        label : 'Authorized Tractor Unit Detachment',
        type  : 'authorized_tractor_unit_detachment',
    },{
        label : 'Authorized Tractor Unit Attachment',
        type  : 'authorized_tractor_unit_attachment',
    },{
        label : 'GPRS Traffic Enabled',
        type  : 'gprs_traffic_enable',
    },{
        label : 'GPRS Traffic Disable',
        type  : 'gprs_traffic_disable',
    },{
        label : 'Overtemperature',
        type  : 'overtemperature',
    },{
        label : 'Undertemperature',
        type  : 'undertemperature',
    },{
        label : 'Emergency Mode',
        type  : 'emergency_mode',
    },{
        label : 'Tow',
        type  : 'tow',
    },
]

const contactsType: Array<{label: string, id: number}> = [
    {
        label : 'Door',
        id    : 1,
    },{
        label : 'Dumpster',
        id    : 2,
    },{
        label : 'Tipper',
        id    : 3,
    },{
        label : 'Pressure Pump',
        id    : 4,
    },{
        label : 'Vacuum Pump',
        id    : 5,
    },{
        label : 'Tractor Unit',
        id    : 6,
    },{
        label : 'Device Sabotage',
        id    : 7,
    },{
        label : 'Fuel Pump',
        id    : 8,
    },
]

const constants = {
    vehicleStatus,
    purpose,
    chargingCable,
    vehicleTypeIcon,
    vehicleType,
    reportIntervals,
    noDataMessage,
    fuelType,
    mapEdgePadding       : 50,
    journey_min_distance : 0.1, // 100 meters
    eventsType,
    contactsType,
}


export default constants
