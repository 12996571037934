import * as React from 'react'
import { useSelector,useDispatch  } from 'react-redux'
import MenuItem from '@mui/material/MenuItem'
import * as RC from '@safefleet/react-common'
import * as RX from '../redux'
import { values } from 'lodash'
import * as Text from './Text'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'


const noCompany: RC.CompanyModel = {
    login_allowed : false,
    name          : "",
    api_access    : false,
    company_id    : -1,
    web_access    : false,
    agent_id      : -1,
    address       : {},
    type          : "",
}
const useCompanies = () => {
    const companies = useSelector(RX.companies.selectors.getAll)
    const selectedId = useSelector(RX.context.selectors.getCompanyId)
    const myCompanyId = useSelector(RX.companyStructures.selectors.getMyCompanyId)

    const companiesArray = React.useMemo(()=>values(companies),[companies])

    const selectedCompanyId: number | null = React.useMemo(() => {
        if(selectedId === 'mine'){
            if(myCompanyId != null){
                return companies[myCompanyId]?.company_id || null
            }
            return null
        }
        return companies[selectedId]?.company_id || null
    },[selectedId,myCompanyId,companies])

    return React.useMemo(()=>{
        const elements = companiesArray.map(
            (x,ix)=>(
                <MenuItem key={ix} value={x.company_id}>
                    {x.name}
                </MenuItem>
            )
        )
        const selectedCompany = companiesArray.find( (x)=> x.company_id === selectedCompanyId) || noCompany
        return {
            elements,
            selectedId : selectedCompanyId,
            companies  : companiesArray,
            selectedCompany,
        }
    },[companiesArray,selectedCompanyId])
}

export default function CompanySelector() {
    const { companies,selectedCompany } = useCompanies()
    const dispatch = useDispatch()
    const changeCompany = (_: any,company: RC.CompanyModel) => {
        dispatch( RX.context.actions.setCompanyId(company.company_id))
    }
    const theme = useTheme()
    const textStyle = {
        ...Text.style(theme).sectionSmall,
        color: theme.palette.secondary.main,
    }
    return (
        <Autocomplete
            disablePortal
            disableClearable
            id="combo-box-demo"
            options={companies}
            sx={{ width: 180, alignItems: 'center', justifyContent: 'center' }}
            getOptionLabel={(company)=>company.name}
            isOptionEqualToValue={(a,b) => a.company_id === b.company_id}
            onChange={changeCompany}
            value={selectedCompany}
            blurOnSelect
            PaperComponent={({ children }) => (
                <Paper style={{ ...textStyle }}>{children}</Paper>
            )}
            renderInput={
                (params) => (<TextField
                    {...params}
                    variant="standard"
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline : true,
                        sx               : textStyle,
                        inputProps       : {
                            ...params.inputProps,
                            style: {
                                textAlign: "right",
                            },
                        },
                    }} />
                )


            }
        />
    )
}
