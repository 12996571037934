import { useTheme } from "@mui/material"
import { i18n } from "@safefleet/react-common"
import { SectionRegularDenim, SectionTitle } from "../Text"
import Icon from "../ui/Icon"
import { drawerWidth } from "../vehicle-drawer"
import CloseIcon from '../../assets/img/close.svg'
import * as RX from '../../redux'
import { useSelector } from "react-redux"
import EstimatedRouteItem from "./EstimatedRouteItem"
import LoadingContainer from "../vehicle-drawer/LoadingContainer"
import { useCallback, useEffect, useState } from "react"

const EstimatedRoutes = ({ onClose, loadMore }: {
    onClose: () => void;
    loadMore: (() => void) | false;
}) => {
    const theme = useTheme()
    const estimtaedRoutes: any = useSelector(RX.estimatedRoutes.selectors.getAll) || []
    const routesLength = Object.keys(estimtaedRoutes)?.length
    const isLoading = useSelector((state: any) => state.estimatedRoutes.tagStatus?.[RX.tags.IS_GETTING])
    const [localLoading, setLocalLoading] = useState(false)

    const handleLoadMore = useCallback(() => {
        if(loadMore) {
            loadMore()
        }
    }, [loadMore])

    const baseStyle = {
        right  : drawerWidth + 12,
        top    : theme.custom.appBar.height + 12,
        width  : drawerWidth,
        height : '88%',
    }

    useEffect(() => {
        if(routesLength >= 0 && routesLength < 2 && !isLoading && !localLoading && loadMore) {
            setLocalLoading(true)
            handleLoadMore()
        }
    }, [estimtaedRoutes.length, handleLoadMore, isLoading, localLoading, routesLength, loadMore])

    useEffect(() => {
        if(isLoading) {
            setLocalLoading(false)
        }
    }, [isLoading])

    return (
        <>
            {isLoading && (
                <div
                    style={{
                        ...baseStyle,
                        position        : "absolute",
                        zIndex          : 9999,
                        backgroundColor : "rgba(255,255,255,0.85)",
                    }}
                >
                    <LoadingContainer />
                </div>
            )}
            <div
                style={{
                    ...baseStyle,
                    position        : "absolute",
                    backgroundColor : 'white',
                    zIndex          : 1,
                    overflow        : 'auto',
                }}
            >
                <div style={{
                    padding        : 24,
                    display        : 'flex',
                    flexDirection  : 'row',
                    alignItems     : 'center',
                    justifyContent : 'space-between',
                    borderBottom   : '1px solid #E0E0E0',
                }}>
                    <SectionTitle>
                        {i18n.t('Estimated route')}
                    </SectionTitle>
                    <div onClick={onClose} style={{ cursor: 'pointer' }}>
                        <Icon
                            source={CloseIcon}
                            size={16}
                            alt={'estimated-routes-close-icon'}
                        />
                    </div>
                </div>
                {
                    Object.values(estimtaedRoutes)?.map((route) => {
                        return <EstimatedRouteItem route={route} />
                    })
                }
                {
                    loadMore && !isLoading ? (
                        <div style={{ alignSelf: 'center', cursor: 'pointer', padding: 16 }}>
                            <SectionRegularDenim
                                onClick={handleLoadMore}
                            >
                                {i18n.t('load more')}
                            </SectionRegularDenim>
                        </div>
                    ) : null
                }
            </div>
        </>
    )
}

export default EstimatedRoutes