import { useSelector } from "react-redux"
import * as RX from "../redux"
import "../stylesheets/css/VehicleMap.css"
import IconCmp from './ui/Icon'
import pinIcon from '../assets/img/end-pin.svg'
import circleIcon from '../assets/img/start-pin.svg'
import { useLocation } from "react-router-dom"

const K_WIDTH = 40
const K_HEIGHT = 40

const PointMarker = ({ icon, lat, lng, start }) => {
    let top = -K_WIDTH

    if(start) {
        top = -K_WIDTH / 2
    }

    return (
        <div
            style={{
                position : 'absolute',
                width    : K_WIDTH,
                height   : K_HEIGHT,
                left     : -K_WIDTH / 2,
                top      : top,
            }}
        >
            <IconCmp
                source={icon}
                size={40}
                alt={''}
            />
        </div>
    )
}

const JourneyMap = () => {
    const { pathname } = useLocation()
    const journeyId = pathname.split('/')[3]
    const journey = useSelector((state: any) =>
        RX.journeys.selectors.getForID(journeyId || '')(state))

    if(!journey) {
        return []
    }

    return [
        <PointMarker
            key={`point-marker-${journey?.start?.lat}`}
            lat={journey?.start?.lat}
            lng={journey?.start?.lng}
            icon={circleIcon}
            start={true}
        />,
        <PointMarker
            key={`point-marker-stop-${journey?.stop?.lat}`}
            lat={journey?.stop?.lat}
            lng={journey?.stop?.lng}
            icon={pinIcon}
            start={false}
        />,
    ]
}
export default JourneyMap
