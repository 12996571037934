import * as enLocale from 'date-fns/locale/en-US'
import * as roLocale from 'date-fns/locale/ro'
import * as itLocale from 'date-fns/locale/it'
import * as plLocale from 'date-fns/locale/pl'
import * as deLocale from 'date-fns/locale/de'
import * as huLocale from 'date-fns/locale/hu'

import { i18n } from '@safefleet/react-common'

import { Locale } from 'date-fns'

export type Language = 'en' | 'ro' | 'it' | 'pl' | 'de' | 'hu'

export const dateFnsLocales: {[key in Language]: Locale;} = {
    en : (enLocale.default as Locale),
    ro : (roLocale.default as Locale),
    it : (itLocale.default as Locale),
    pl : (plLocale.default as Locale),
    de : (deLocale.default as Locale),
    hu : (huLocale.default as Locale),
}

export const currentLocale = (): Locale => {
    // TODO: this is done because i18n.language is not defined in react-common
    const currentLanguage: Language = (i18n as any).language
    const ret=  dateFnsLocales[currentLanguage]
    return ret || enLocale.default
}
