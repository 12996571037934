import { createTheme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

declare module '@mui/material/styles' {

    interface Theme {
        custom: {
            appBar: {
                height: number,
            };
            markerColors: {
                offline: string;
                off: string;
                on: string;
                unexpected: string;
                start: string,
                intermediate: string,
                end: string,
            };
            text: {
                details: string;
                button: string;
                off: string;
                on: string;
                black?: string;
                title: string;
            }
            polyline: {
                border: string,
                background: string,
                arrow?: string,
            }
            calendar: {
                selectedDay: string,
                selectionBackground: string,
                primary: string,
                primaryLight: string
            }
            header: {
                background: string,
                padding: number,
            },
            tab: {
                active: string,
                inactive: string
            },
            periodSelector: {
                border: string,
                backgroundColor: string
                textColorActive: string
                background: string
            },
            journeyPointsDivider: {
                color: string,
                border: string,
            },
            filters: {
                background: string,
            }
        };
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        custom?: {
            appBar?: {
                height?: number,
            };
            markerColors?: {
                offline?: string;
                off?: string;
                on?: string;
                unexpected?: string;
                start?: string,
                intermediate?: string,
                end?: string,
            };
            text?: {
                details?: string;
                button?: string;
                off?: string;
                on?: string;
                title: string;
                black?: string;
            },
            polyline?: {
                border?: string,
                background?: string,
                arrow?: string,
            },
            calendar?: {
                selectedDay?: string,
                selectionBackground?: string,
                primary: string,
                primaryLight: string
            }
            header: {
                background: string,
                padding: number,
            },
            tab: {
                active: string,
                inactive: string
            },
            periodSelector: {
                border: string,
                backgroundColor: string,
                textColorActive: string,
                background: string
            },
            journeyPointsDivider: {
                color: string,
                border: string,
            },
            filters: {
                background: string,
            }
        };
    }
}


const colors = {
    neutrals: [
        '#FCFCFC', '#FDFDFD', '#C5C5C5', '#7E7E7E', '#515151', '#343434', '#212121', '#121212',
    ],
    grays: [
        '#FCFCFC', '#EEEEEE', '#C4C4C4', '#43474B', '#F5F5F5',
    ],
    alizarinCrimson : '#DA2128',
    denim           : '#1672CE',
    darkerDenim     : '#1967D2',
    chateauGreen    : '#36C24C',
    cornflowerBlue  : '#669DF6',
    solitude        : '#E8F0FE',
}

export const spaces = {
    headerLine : [30, 20, 15] as [number, number, number],
    textLine   : [10, 5, 3] as [number, number, number],
}

export const textSizes = {
    title        : 24,
    sectionTitle : 16,
    section      : 14,
    sectionSmall : 12,
}

export const withTheme = <T = SxProps>(f: (theme: Theme) => T): ((theme: Theme) => T) => f

export const theme = createTheme({
    palette: {
        primary: {
            main: colors.denim,
        },
        secondary: {
            main: colors.neutrals[4],
        },
    },
    custom: {
        text: {
            details : colors.neutrals[3],
            on      : colors.chateauGreen,
            off     : colors.alizarinCrimson,
            title   : colors.neutrals[7],
            button  : colors.denim,
            black   : '#121212',
        },
        appBar: {
            height: 80,
        },
        markerColors: {
            offline    : colors.alizarinCrimson,
            off        : colors.neutrals[5],
            on         : colors.chateauGreen,
            unexpected : colors.neutrals[3],

            // journey
            start        : colors.chateauGreen,
            intermediate : colors.neutrals[2],
            end          : colors.denim,
        },
        polyline: {
            background : colors.cornflowerBlue,
            border     : colors.darkerDenim,
            arrow      : colors.solitude,
        },
        calendar: {
            selectedDay         : colors.denim,
            selectionBackground : colors.solitude,
            primary             : colors.denim,
            primaryLight        : colors.cornflowerBlue,
        },
        header: {
            background : colors.neutrals[1],
            padding    : spaces.headerLine[1],
        },
        tab: {
            active   : colors.denim,
            inactive : colors.neutrals[3],
        },
        periodSelector: {
            border          : colors.grays[2],
            backgroundColor : colors.denim,
            textColorActive : colors.neutrals[0],
            background      : colors.grays[4],
        },
        journeyPointsDivider: {
            color  : colors.grays[2],
            border : colors.neutrals[1],
        },
        filters: {
            background: colors.solitude,
        },
    },
})

