import * as React from 'react'
import { getChunks } from './base'


type Props = {
    segments: number[],
    style?: React.CSSProperties,
}

//TODO: improve style
const borderColor = '#FFE6E6'
const backgroundColor = '#EEEEEE'
const container: React.CSSProperties = {
    backgroundColor,
    width         : '100%',
    display       : 'flex',
    flexDirection : 'row',
    height        : '100%',
}

type SegmentProps = {
    isLast: boolean,
    domainLen: number,
    len: number,
}
const Segment: React.FC<SegmentProps>  = ({
    isLast,
    domainLen,
    len,
}) => {
    return(
        <div style={{
            flex        : len*100/domainLen,
            borderRight : !isLast ? `2px solid ${borderColor}`: undefined,
        }} />
    )
}
const InnerSegments : React.FC<Props> = ({ style,segments }) => {
    const domainLen = segments.length >= 2
        ?  segments[segments.length - 1] - segments[0]
        : 0

    const chunks = getChunks(segments)
    return (
        <div style={{ ...container,...style }} >
            {chunks.map((x,ix) => <Segment
                key={ix}
                isLast={ix === chunks.length - 1}
                domainLen={domainLen}
                len={x} />)}
        </div>
    )

}


export default InnerSegments
