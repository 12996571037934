import { useSelector } from "react-redux"
import "../stylesheets/css/VehicleMap.css"
import IconCmp from './ui/Icon'
import pinIcon from '../assets/img/end-pin.svg'

import { getSelectedAddressForRouteEstimation } from "../redux/context/selectors"
import { useFitBoundsWithPadding } from "../hooks/map"
import { useCallback, useEffect } from "react"
import * as RX from '../redux'
import { get, sortBy, toLower } from "lodash"

const K_WIDTH = 40
const K_HEIGHT = 40
const MAP_ZOOM_SELECTED_ADDRESS = 17
const MAP_ZOOM_DEFAULT = 7

const PointMarker = ({ icon, lat, lng, start }) => {
    let top = -K_WIDTH

    if(start) {
        top = -K_WIDTH / 2
    }

    return (
        <div
            style={{
                position : 'absolute',
                width    : K_WIDTH,
                height   : K_HEIGHT,
                left     : -K_WIDTH / 2,
                top      : top,
            }}
        >
            <IconCmp
                source={icon}
                size={40}
                alt={''}
            />
        </div>
    )
}

const SelectedAddressPin = () => {
    const mapRef = useSelector(RX.context.selectors.getMapRef)
    const selectedAddress = useSelector(getSelectedAddressForRouteEstimation)
    const fitBoundsWithPadding = useFitBoundsWithPadding()
    const userVehicles  = useSelector((state: any) => sortBy(
        RX.vehicles.selectors.getAll(state),
        (vehicle) => toLower(get(vehicle, 'name'))
    ))

    const resetPosition = useCallback(() => {
        if (mapRef) {
            const points = userVehicles.reduce((acc: any,item)=>{
                const cInfo = item.current_info
                if(!cInfo){
                    return acc
                }


                return acc.concat(
                    {
                        lat : item.current_info?.lat,
                        lng : item.current_info?.lng,
                    }
                )
            }, [])


            fitBoundsWithPadding(points)
        }
    }, [fitBoundsWithPadding, mapRef, userVehicles])

    useEffect(() => {
        if(selectedAddress) {
            fitBoundsWithPadding([
                {
                    lat : selectedAddress.lat,
                    lng : selectedAddress.lng,
                },
            ])

            if(mapRef) {
                mapRef.map.setZoom(MAP_ZOOM_SELECTED_ADDRESS)
            }
        } else {
            resetPosition()
            if(mapRef) {
                mapRef.map.setZoom(MAP_ZOOM_DEFAULT)
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAddress])

    return selectedAddress && [
        <PointMarker
            key={`point-marker-stop-${selectedAddress?.lat}`}
            lat={selectedAddress?.lat}
            lng={selectedAddress?.lng}
            icon={pinIcon}
            start={false}
        />,
    ]
}
export default SelectedAddressPin
