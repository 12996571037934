import { channel as createChannel } from 'redux-saga'
import { put, fork, takeLatest, call } from 'redux-saga/effects'

import { utils, i18nInit, app as appCommon } from '@safefleet/react-common'
import { i18nLocales } from '../../locales/settings'

import * as actions from './actions'
import detectBrowserLanguage from 'detect-browser-language'

const channel = createChannel()

export function* saga() {
    yield fork(appCommon.saga)
    yield call(i18nInit, i18nLocales, getDeviceLanguage())
    yield fork(utils.watchChannel, channel)
    yield takeLatest(actions.INITIALIZED, watchForStateChanges)
    yield takeLatest(actions.INITIALIZED, setDeviceLanguage)
}

function* watchForStateChanges() {
    yield put(actions.activate())
}

const getDeviceLanguage = () => {
    const lang = detectBrowserLanguage()

    return lang.split('-')[0]
}

function* setDeviceLanguage() {
    yield put(actions.setLanguage({ language: getDeviceLanguage() }))
}
