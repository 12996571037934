import React from 'react'
import { BrowserRouter as Router,Routes, Route } from "react-router-dom"
import Home from '../views/Home'
import Login from '../views/Login'
import {  useSelector } from 'react-redux'
import { auth } from '../redux'
import routes from './routes'

type Props = {}

const PublicRoutes = () => (
    <Routes>
        <Route path="*"
            element={ <Login />}
        />
    </Routes>
)
const PrivateRoutes = () => (
    <Home routes={routes} />
)

const MainRouter: React.FC<Props> = () => {

    const isAuthenticated = useSelector(auth.selectors.isAuthenticated)
    return (
        <Router>
            <div>
                { isAuthenticated ? <PrivateRoutes /> : <PublicRoutes /> }
            </div>
        </Router>
    )
}

export default MainRouter

