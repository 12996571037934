import { VehicleModel } from "@safefleet/react-common"
import { SvgLink } from "./SvgLink"
import { CopyLink } from './CopyLink'
import React from "react"
import * as RX from '../../redux'
import streetViewIcon from '../../assets/img/streetview.svg'
import { spaces } from '../../theme'

type BottomProps = {
    vehicle: VehicleModel | undefined,
    hide?: null | 'journeys' | 'activity'
}

export const DrawerBottom: React.FC<BottomProps> = ({ vehicle, hide = null }) => {
    const lat = vehicle?.current_info?.lat
    const lng = vehicle?.current_info?.lng
    var streetViewURL = 'https://www.google.com/maps/@?api=1&map_action=pano&pitch=0&fov=1000&viewpoint='
    streetViewURL += lat + ',' + lng

    const onStreetViewPress = React.useCallback(() => {
        window.open(streetViewURL)
    }, [streetViewURL])

    return (
        <div
            style={{
                display        : 'flex',
                flexDirection  : 'column',
                justifyContent : 'flex-end',
            }}
        >
            <div style={{
                display : 'flex',
                padding : spaces.headerLine[1],

            }}>
                <CopyLink/>
                <SvgLink
                    icon={streetViewIcon}
                    text={RX.i18n.t('Street View')}
                    action={onStreetViewPress}
                />
            </div>
        </div>
    )
}