import { SectionText } from '../components/Text'
import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import * as RX from '../redux'
import { useSelector, useDispatch } from 'react-redux'
import { spaces } from '../theme'
import { i18n } from '@safefleet/react-common'
import MapOptionsButton from './MapOptionsButton'

const CheckBoxLabeled = ({ value = false, handleChange, label }) => {
    return (
        <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
            <Checkbox
                checked={value}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            <SectionText
                sx={{
                    marginLeft: `${spaces.textLine[0]}px'`,
                }}
            >
                {label}
            </SectionText>
        </div>
    )
}

const CurrentPositionsOptions = () => {
    const onlyActiveVehicles = useSelector(RX.context.selectors.getOnlyActiveVehiclesFilter)
    const dispatch = useDispatch()

    const toggleActiveVehicles = React.useCallback(() => {
        dispatch(RX.context.actions.setOnlyActiveVehiclesFilter(!onlyActiveVehicles))
    }, [dispatch, onlyActiveVehicles])


    return <MapOptionsButton>

        <CheckBoxLabeled
            label={i18n.t('Show only active vehicles')}
            value={onlyActiveVehicles}
            handleChange={toggleActiveVehicles}
        />
    </MapOptionsButton>
}

export default CurrentPositionsOptions
