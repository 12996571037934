const instancesDefaultCoordinates = {
    // italy
    italy: {
        lat : 42.657330,
        lng : 12.967265,
    },
    mifra: {
        lat : 42.657330,
        lng : 12.967265,
    },
    plose: {
        lat : 42.657330,
        lng : 12.967265,
    },
    // poland
    poland: {
        lat : 52.078870,
        lng : 19.461525,
    },
    poland2: {
        lat : 52.078870,
        lng : 19.461525,
    },
    // macedonia
    ins: {
        lat : 41.524548,
        lng : 21.648160,
    },
    //kosovo
    osce: {
        lat : 42.606053,
        lng : 20.986310,
    },
    eulex: {
        lat : 42.606053,
        lng : 20.986310,
    },
    // austria
    austria: {
        lat : 47.514768,
        lng : 14.295256,
    },
    // hungary
    galignum: {
        lat : 47.058789,
        lng : 19.516339,
    },
    // tanzania
    tanzania: {
        lat : -5.869562,
        lng : 35.095361,
    },
    // ireland
    merrion: {
        lat : 53.265623,
        lng : -8.035523,
    },
    // romania
    alpha: {
        lat : 45.967070,
        lng : 24.990701,
    },
    citone: {
        lat : 45.967070,
        lng : 24.990701,
    },
    delta: {
        lat : 45.967070,
        lng : 24.990701,
    },
    electrica: {
        lat : 45.967070,
        lng : 24.990701,
    },
    eon: {
        lat : 45.967070,
        lng : 24.990701,
    },
    gamma: {
        lat : 45.967070,
        lng : 24.990701,
    },
    telekom: {
        lat : 45.967070,
        lng : 24.990701,
    },
    veolia: {
        lat : 45.967070,
        lng : 24.990701,
    },
    staging: {
        lat : 45.967070,
        lng : 24.990701,
    },
}

export default instancesDefaultCoordinates