export type Interval<T> = {
    start: T,
    end: T,
}

export type IntervalLine<T> = {
    intervals: Interval<T>[],
    color: string,
    name?: string,
    height: number,
}

export const getChunks = (
    segments: number[]
): number[] => segments.reduce((acc: number[],x,ix) => {
    if(ix === 0){
        return []
    }
    const len = x - segments[ix - 1]
    return acc.concat(len)
},[])

const nexStop = (l: number,f: number) => f % l === 0? f : ( f + l ) - ((f + l) % l)

export function split(start: number,end: number,checkpoint: number): number[]{
    if(start >= end){
        return [end]
    }
    const next = nexStop(checkpoint,start)
    if(next+checkpoint > end){
        return [start,end]
    }
    return (start === next ? [next]: [start,next]).concat(split(next+checkpoint,end,checkpoint))
}

//we assume src is sorted
export function unifyIntervals<T>(src: Interval<T>[]): Interval<T>[] {
    if(src.length < 2){
        return src
    }
    const init: Interval<T>[] = [src[0]]
    const tail = src.slice(1)
    return tail.reduce(
        (acc: Interval<T>[],x) => {
            const last = acc[acc.length - 1]
            //if x comes after last
            if(last.end < x.start){
                return acc.concat(x)
            }
            //if x is included
            if(last.end > x.end){
                return acc
            }

            // union from last and current
            const withoutLast = acc.slice(0,acc.length - 1)
            return withoutLast.concat({
                start : last.start,
                end   : x.end,
            })
        },
        init
    )
}

export function sortIntervals<T>(src: Interval<T>[][],compare: (a: T,b: T) => number): Interval<T>[]{
    return src.reduce(
        (acc,x) => acc.concat(x),
        ([] as  Interval<T>[])
    ).sort((a,b)=> compare(a.start,b.start))
}

//we assume src is sorted and not overlapping
export function reverseIntervals<T>(srcRaw: Interval<T>[],domain: Interval<T>): Interval<T>[]{
    const src = srcRaw.filter( (x) => x.end >= domain.start && x.start <= domain.end)
    if(!src.length){
        return [domain]
    }
    const init: Interval<T>[] = domain.start < src[0].start ? [{ start: domain.start, end: src[0].start }] : []
    return src.reduce((acc,x,ix)=>{
        // if domain ends before ending of the current
        if(domain.end < x.end){
            return acc
        }
        //if it is the last
        if(ix >= src.length - 1){
            return acc.concat({
                start : x.end,
                end   : domain.end,
            })
        }

        return acc.concat({
            start : x.end,
            end   : src[ix+1].start,
        })
    },init)
}

