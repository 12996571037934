import React from "react"
import hornIcon from "../assets/img/newsfeed.svg"
import arrowRight from "../assets/img/arrowLongRight.svg"
import { spaces } from "../theme"
import IconCmp from "./ui/Icon"
import { SectionRegular, SectionTitle, SectionSmallGray } from "./Text"
import Popover from "@mui/material/Popover"
import { format } from "date-fns"
import { i18n } from "@safefleet/react-common"
import Button from "@mui/material/Button"

const polandInstances = ['poland', 'poland2']
const italyInstances = ['italy']

const RssFeed = () => {
    const [rssFeed, setRssFeed]: any = React.useState()
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )
    const [newNews, setNewNews] = React.useState(false)

    const handleClick = (event: React.MouseEvent<any>) => {
        setAnchorEl(event.currentTarget)
        setNewNews(false)
        localStorage.setItem("feed", JSON.stringify(rssFeed))
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : undefined

    React.useEffect(() => {
        (async () => {
            try {
                const prefixUrl = window.location.hostname.split('.')[0]
                const feedLocation = polandInstances.includes(prefixUrl)
                    ? 'pl' : (italyInstances.includes(prefixUrl) ? 'it' : 'ro')
                const res = await fetch(
                    `https://api.allorigins.win/get?url=${encodeURIComponent(
                        'https://safefleet.tech/feed-'+feedLocation+'/'
                    )}`
                )

                const { contents } = await res.json()
                let commaIndex = contents.indexOf(',')
                let base64String = contents.slice(commaIndex + 1)

                let decodedString = atob(base64String)

                let bytes = new Uint8Array(decodedString.length)
                for(let i = 0; i < decodedString.length; i++) {
                    bytes[i] = decodedString.charCodeAt(i)
                }

                let blob = new Blob([bytes], { type: "application/rss+xml; charset=UTF-8" })
                let reader = new FileReader()

                reader.onload = function() {
                    if(reader.result && typeof reader.result === 'string' ) {
                        const feed = new window.DOMParser().parseFromString(reader.result, "text/xml")
                        const items = feed.querySelectorAll("item")
                        const itemsArr = Array.prototype.slice.call(items)
                        let feedItems: Array<{
                            title: string;
                            description: string;
                            link: string;
                            pubDate: string;
                        }> = []

                        for (let i = 0; i <= 3; i++) {
                            if(i < itemsArr.length){
                                const el = itemsArr[i]
                                const description = el.querySelector("description").textContent
                                let parsedDescription = ""

                                for (let i = 0; i < description.length; i++) {
                                    if (description[i] === "&") {
                                        break
                                    }
                                    parsedDescription += description[i]
                                }

                                feedItems.push({
                                    title       : el.querySelector("title").textContent,
                                    description : parsedDescription,
                                    link        : el.querySelector("link").textContent,
                                    pubDate     : el.querySelector("pubDate").textContent,
                                })
                            }
                        }

                        const serverFeed = JSON.stringify(feedItems)
                        const localFeedItems = localStorage.getItem("feed") || ""

                        if (
                            localFeedItems &&
                            serverFeed &&
                            localFeedItems.length !== serverFeed.length
                        ) {
                            setNewNews(true)
                        }

                        setRssFeed(feedItems)
                    }

                }

                reader.readAsText(blob, "UTF-8")


            } catch (e) {
                console.log(e)
            }
        })()
    }, [])

    return (
        <div>
            <div
                style={{
                    backgroundColor : "white",
                    height          : "100%",
                }}
            >
                <Button
                    color="secondary"
                    onClick={(event) => handleClick(event)}
                    sx={{
                        display       : "flex",
                        flexDirection : "column",
                        paddingTop    : `${spaces.headerLine[2]}px`,
                        height        : "100%",
                    }}
                >
                    {newNews && (
                        <div
                            style={{
                                zIndex          : 99999,
                                width           : 12,
                                height          : 12,
                                backgroundColor : "red",
                                borderRadius    : 7,
                                alignItems      : "center",
                                justifyContent  : "center",
                                position        : "absolute",
                                top             : 6,
                                right           : spaces.headerLine[2],
                                display         : "flex",
                            }}
                        >
                            <span
                                style={{
                                    color      : "white",
                                    fontWeight : "bold",
                                    textAlign  : "center",
                                    fontSize   : 8,
                                }}
                            >
                                1
                            </span>
                        </div>
                    )}
                    <IconCmp source={hornIcon} size={18} alt={""} />
                    <span style={{ fontSize: 12, fontWeight: "normal", marginTop: 7 }}>
                        {i18n.t("Newsfeed")}
                    </span>
                </Button>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical   : "bottom",
                    horizontal : "left",
                }}
            >
                <div
                    style={{
                        width  : 400,
                        zIndex : 999999,
                    }}
                >
                    <div style={{ padding: spaces.headerLine[1], paddingBottom: 0 }}>
                        <SectionTitle>{i18n.t('CURRENT NEWS FROM SAFEFLEET')}</SectionTitle>
                    </div>
                    <div
                        style={{
                            marginTop       : 15,
                            marginBottom    : 10,
                            height          : 6,
                            backgroundColor : "rgb(212, 212, 212)",
                            width           : "100%",
                        }}
                    />
                    <div style={{ padding: 20, paddingTop: 0 }}>
                        {rssFeed &&
              rssFeed.map((item, index) => {
                  return (
                      <div
                          id={index}
                          style={{
                              display       : "flex",
                              flexDirection : "column",
                              borderBottom  : "1px solid rgb(212, 212, 212)",
                              marginBottom  : spaces.textLine[0],
                              paddingBottom : spaces.textLine[1],
                          }}
                      >
                          <SectionTitle>{item.title}</SectionTitle>
                          <div style={{ position: 'relative' }}>
                              <SectionRegular
                                  style={{
                                      marginTop  : spaces.textLine[1],
                                      lineHeight : "1.5em",
                                      height     : "3em",
                                      overflow   : "hidden",
                                      width      : '95%',
                                  }}>
                                  {item.description}
                              </SectionRegular>
                              <div style={{
                                  position : 'absolute',
                                  bottom   : 0,
                                  right    : 12,
                              }}>...</div>
                          </div>
                          <div
                              style={{
                                  display        : "flex",
                                  alignItems     : "center",
                                  justifyContent : "space-between",
                                  flexDirection  : "row",
                                  marginTop      : spaces.textLine[0],
                              }}
                          >
                              <div
                                  style={{
                                      flexDirection : "row",
                                      alignItems    : "center",
                                      display       : "flex",
                                      cursor        : "pointer",
                                  }}
                                  onClick={() => window.open(item.link)}
                              >
                                  <span
                                      style={{
                                          color       : "rgb(46, 113, 207)",
                                          fontSize    : 12,
                                          fontWeight  : "bold",
                                          marginRight : spaces.textLine[0],
                                      }}
                                  >
                                      {" "}
                                      {i18n.t('READ MORE')}
                                  </span>
                                  <IconCmp source={arrowRight} size={18} alt={""} />
                              </div>
                              <SectionSmallGray>
                                  {format(new Date(item.pubDate), "dd MMMM yyyy")}
                              </SectionSmallGray>
                          </div>
                      </div>
                  )
              })}
                    </div>
                </div>
            </Popover>
        </div>
    )
}

export default RssFeed
