import { get, set, cloneDeep } from 'lodash'
import * as actions from './actions'
import { ContextAction } from './actions'

export interface ContextState {
    refreshingEnabled: boolean;
    isRefreshing: boolean;
    loading: boolean;
    fleetId: number | 'mine';
    companyId: number | 'mine';
    vehicleId?: number | null
    journeyIds: string[],
    userId?: number | null
    search: {
        screen?: string,
        display: boolean,
        value?: string,
    },
    reportInterval : {
        startMoment: Date,
        stopMoment: Date,
        filterShortJourneys: boolean,
    } | null,
    trajectoryInterval : {
        startMoment: Date,
        stopMoment: Date,
    } | null,
    mapRef: any | null,
    shouldPollingRun: boolean,
    isPolling: boolean,
    mapType: "roadmap" | "terrain" | "satellite",
    drawerStatus: "open" | "close",
    filtersPanel: boolean,
    journeySliderValue: Array<number>,
    journeysFilterDate: Array<Date> | null,
    showJourneysPois: boolean
    onlyActiveVehicles: boolean,
    addressSearchDrawerStatus: "open" | "close"
    selectedAddressForRouteEstimation: null | {
        lat: number,
        lng: number
    }
}

const initialState: ContextState = {
    search: {
        display: false,
    },
    refreshingEnabled                 : false,
    isRefreshing                      : false,
    loading                           : true,
    vehicleId                         : null,
    fleetId                           : 'mine',
    companyId                         : 'mine',
    userId                            : null,
    reportInterval                    : null,
    journeyIds                        : [],
    trajectoryInterval                : null,
    shouldPollingRun                  : false,
    isPolling                         : false,
    mapRef                            : null,
    mapType                           : "roadmap",
    drawerStatus                      : "open",
    addressSearchDrawerStatus         : "close",
    filtersPanel                      : false,
    journeySliderValue                : [],
    journeysFilterDate                : null,
    showJourneysPois                  : false,
    onlyActiveVehicles                : false,
    selectedAddressForRouteEstimation : null,
}

export function reducer(state = initialState, action: ContextAction): ContextState {
    switch (action.type) {
        case actions.USER_CHANGED: {
            const { userId } = action.payload
            return {
                ...state,
                userId,
            }
        }
        case actions.VEHICLE_CHANGED: {
            const { vehicleId } = action.payload
            return {
                ...state,
                vehicleId,
            }
        }
        case actions.SET_REPORT_INTERVAL: {
            const { startMoment, stopMoment, filterShortJourneys } = action.payload
            return {
                ...state,
                reportInterval: {
                    startMoment,
                    stopMoment,
                    filterShortJourneys,
                },
            }
        }
        case actions.JOURNEYS_CHANGED: {
            const { journeyIds } = action.payload
            return {
                ...state,
                journeyIds,
            }
        }
        case actions.SET_TRAJECTORY_INTERVAL: {
            const trajectoryInterval = action.payload
            return {
                ...state,
                trajectoryInterval,
            }
        }
        case actions.SET_SEARCH_VALUE: {
            const { screen, value } = action.payload
            const { search } = state
            const newSearch = cloneDeep(search)
            //TODO: fix screen as any
            set(newSearch, [(screen as any), 'value'], value)

            return {
                ...state,
                search: newSearch,
            }
        }
        case actions.SET_SEARCH_DISPLAYED: {
            const { screen, displayed } = action.payload
            const { search } = state
            //TODO: fix screen and search as any
            const oldValue = get((search as any)[(screen as any)], 'value', '')

            //TODO: fix cloneDeep(search) as any
            const newSearch = (cloneDeep(search) as any)
            newSearch[(screen as any)] = { displayed, value: displayed ? oldValue : '' }

            return {
                ...state,
                search: newSearch,
            }
        }
        case actions.SET_FLEET_ID:{
            return {
                ...state,
                fleetId: action.payload.fleetId,
            }
        }
        case actions.SET_COMPANY_ID:{
            return {
                ...state,
                companyId: action.payload.companyId,
            }
        }
        case actions.SET_MAP_REF: {
            return {
                ...state,
                mapRef: action.payload,
            }
        }
        case actions.START_POLLING: {
            return {
                ...state,
                shouldPollingRun: true,
            }
        }
        case actions.STOP_POLLING: {
            return {
                ...state,
                shouldPollingRun: false,
            }
        }
        case actions.POLLING_TASK_REQUESTED: {
            return {
                ...state,
                isPolling: true,
            }
        }
        case actions.POLLING_TASK_FINISHED: {
            return {
                ...state,
                isPolling: false,
            }
        }
        case actions.SET_MAP_TYPE: {
            return {
                ...state,
                mapType: action.payload,
            }
        }
        case actions.SET_DRAWER_STATUS: {
            return {
                ...state,
                drawerStatus: action.payload,
            }
        }

        case actions.SET_FILTERS_PANEL_STATUS: {
            return {
                ...state,
                filtersPanel: action.payload,
            }
        }
        case actions.SET_JOURNEYS_SLIDER_VALUES: {
            return {
                ...state,
                journeySliderValue: action.payload,
            }
        }
        case actions.SET_JOURNEYS_FILTERS_DATES: {
            return {
                ...state,
                journeysFilterDate: action.payload,
            }
        }
        case actions.SET_SHOW_JOURNEYS_POIS: {
            return {
                ...state,
                showJourneysPois: action.payload,
            }
        }
        case actions.SET_ONLY_ACTIVE_VEHICLES: {
            return {
                ...state,
                onlyActiveVehicles: action.payload,
            }
        }
        case actions.SET_ADDRESS_SEARCH_DRAWER_STATUS: {
            return {
                ...state,
                addressSearchDrawerStatus: action.payload,
            }
        }
        case actions.SET_SELECTED_ADDRESS_FOR_ROUTE_ESTIMATION: {
            return {
                ...state,
                selectedAddressForRouteEstimation: action.payload,
            }
        }
        default:
            return state
    }
}
