import { i18n } from "@safefleet/react-common"
import { getDHMSFromMs, getDHMSFromS, stringFromDHMS } from "../../utils"
import { TopCell } from "../vehicle-drawer/VehicleDetails"
import format from "date-fns/format"
import { useSelector } from "react-redux"
import * as RX from "../../redux"
import IdleTimeLine from "../vehicle-drawer/IdleTimeLine"
import { getIntervals, useJourneys } from "../vehicle-drawer/VehicleActivity"
import { useCallback, useMemo } from "react"
import {
    useIdleIntervalLines,
    useLinesWithReverse,
} from "../vehicle-drawer/IntervalLinesSummary"
import LoadingContainer from "../vehicle-drawer/LoadingContainer"
import { useFilteredJourneysByInterval } from "../../hooks/journeys"
import { useNumberInterval } from "../timeline"

const EstimatedRouteItem = ({ route }) => {
    const row = {
        display       : "flex",
        flexDirection : "row",
    } as React.CSSProperties
    const vehicle = useSelector(
        RX.vehicles.selectors.getForID(route.vehicle_id || undefined)
    )
    const intervals = useMemo(() => {
        return getIntervals(new Date())
    }, [])
    const [startInterval, endInterval] = [
        intervals.last24.start,
        intervals.last24.end,
    ]
    const { journeys } = useJourneys(
        startInterval,
        endInterval,
        route.vehicle_id
    )
    const last24Journeys = useFilteredJourneysByInterval(
        journeys,
        intervals.last24
    )
    const start = startInterval.getTime()
    const end = endInterval.getTime()
    const last24Lines = useIdleIntervalLines(last24Journeys)
    const journeysIsGetting = useSelector(
        RX.journeys.selectors.isPendingForTag(RX.tags.IS_GETTING)
    )
    const last24NumberInterval = useNumberInterval(intervals.last24)
    const lines = useLinesWithReverse(last24Lines, last24NumberInterval)

    const getLast24HoursDrivingTime = useCallback(() => {
        const sum = lines[0].intervals.reduce(
            (acc, x) => acc + (x.end - x.start),
            0
        )
        return stringFromDHMS(getDHMSFromMs(sum))
    }, [lines])

    const getLast24HoursDistance = useCallback(() => {
        return last24Journeys.reduce((acc, journey) => {
            return acc + journey.distance
        }, 0)
    }, [last24Journeys])

    return (
        <div
            style={{
                padding      : 20,
                borderBottom : "1px solid #E0E0E0",
                position     : "relative",
            }}
        >
            {journeysIsGetting && (
                <div
                    style={{
                        width           : "100%",
                        height          : "90%",
                        position        : "absolute",
                        zIndex          : 9999,
                        backgroundColor : "rgba(255,255,255,0.85)",
                    }}
                >
                    <LoadingContainer />
                </div>
            )}
            <div style={row}>
                <TopCell
                    label={vehicle?.name || ""}
                    value={vehicle?.default_driver ? `[${vehicle?.default_driver}]` : ""}
                    isLeft={true}
                />
                <TopCell
                    label={i18n.t("Leave in")}
                    value={route?.leave_in}
                    isLeft={false}
                />
            </div>
            <div style={row}>
                <TopCell
                    label={i18n.t("Estimated distance")}
                    value={route?.estimated_distance}
                    isLeft={true}
                />
                <TopCell
                    label={i18n.t("Estimated duration")}
                    value={stringFromDHMS(getDHMSFromS(route?.estimated_duration), true)}
                    isLeft={false}
                />
            </div>
            <div style={row}>
                <TopCell
                    label={i18n.t("Estimated hour")}
                    value={format(new Date(route?.estimated_time), "dd MMMM, HH:mm")}
                    isLeft={true}
                />
                <TopCell
                    label={i18n.t("Estimated road tax")}
                    value={
                        route.estimated_cost?.total_cost +
            " " +
            route?.estimated_cost.currency
                    }
                    isLeft={false}
                />
            </div>
            <div style={row}>
                <TopCell
                    label={i18n.t("Distance ( 24h )")}
                    value={getLast24HoursDistance()?.toFixed(1) + " km"}
                    isLeft={true}
                />
                <TopCell
                    label={i18n.t("Driving ( 24h )")}
                    value={getLast24HoursDrivingTime()}
                    isLeft={false}
                />
            </div>
            <div style={{ marginTop: 20 }}>
                <IdleTimeLine
                    lines={last24Lines}
                    domainStarts={start}
                    domainEnds={end}
                    checkpoint={86400000 / 6}
                    headerLabelText={(x: number) => format(new Date(x), "HH:mm")}
                />
            </div>
        </div>
    )
}

export default EstimatedRouteItem
