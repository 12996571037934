const instancesServer = {
    staging   : 'https://staging.safefleet.eu',
    alpha     : 'https://alpha.safefleet.eu',
    electrica : 'https://electrica.safefleet.eu',
    italy     : 'https://portale.safefleet.it',
    mifra     : 'https://mifra.safefleet.it',
    poland    : 'https://portal.safefleet.pl',
    veolia    : 'https://veolia-cloud.safefleet.eu',
}

export default instancesServer