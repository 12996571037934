import { createSelector } from "reselect"
import * as redux from '../../redux'
import { getKey } from '@safefleet/react-common/src/redux/positions/utils'

export const getPositionsForPresences = createSelector(
    redux.positions.selectors.getAll,
    (_, props: { coordinates: any}) => props.coordinates,
    (positions, coordinates) => {
        const pois = coordinates.reduce((acc: [], coordinate, ix) => {
            const position = positions[getKey(coordinate)]
            const lastPosition = ix ? positions[getKey(coordinates[ix - 1])] : position

            if(position && position?.poi) {
                return ix && position?.poi?.poi_id === lastPosition?.poi?.poi_id ? acc :
                    acc.concat({
                        ...position,
                        moment: coordinate.moment,
                    })
            }

            return acc
        }, [])

        return pois
    }
)
