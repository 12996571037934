import { app, ActionType, ActionCreator } from '@safefleet/react-common'

import { AppStateStatus } from "../../base"


export const {
    initialize,
    setLanguage,
    setCountry,

    INITIALIZED,
    LANGUAGE_UPDATED,
    COUNTRY_UPDATED,
} = app

export const STATE_CHANGED       : ActionType = '@ app / STATE_CHANGED'
export const ACTIVATED           : ActionType = '@ app / ACTIVATED'
export const CLOSED              : ActionType = '@ app / CLOSED'

export const changeState : ActionCreator<{ state: AppStateStatus }> = (payload) => (
    {
        type: STATE_CHANGED,
        payload,
    }
)

export const activate    : ActionCreator = () => ({ type: ACTIVATED })

export const closed : ActionCreator = () => ({ type: CLOSED })
