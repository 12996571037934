import React from "react"
import GoogleMapReact from 'google-map-react'
import { settings, utils, VehicleModel,JourneyModel } from '@safefleet/react-common'
import { useDispatch, useSelector } from "react-redux"
import { context } from "../redux"
import JourneyMap from "./MapJourney"
import "../stylesheets/css/VehicleMap.css"
import MapClusters from "./MapClusters"
import { timeDifference, useGetJourneyPresences } from "../utils"
import { get, map } from "lodash"
import * as RX from '../redux'
import { useLocation } from "react-router-dom"
import { useFitBoundsWithPadding, useZoomToObject } from "../hooks/map"
import { useTheme } from '@mui/material/styles'
import { useKeepReference } from "../hooks/general"
import * as HC from '../hooks/vehicle'
//NOTE: let's keep this comment in order to have an example
//import {
    //useWhatChanged,
    //setUseWhatChange,
//} from '@simbathesailor/use-what-changed'
//setUseWhatChange(process.env.NODE_ENV === 'development')
import MapJourneysMarkers from "./MapJourneysMarkers"
import { getMapCenter } from "../utils/getMapCenter"
import SelectedAddressPin from "./SelectedAddressPin"

const defaultProps = {
    zoom: 7,
}


type VehicleMapProps = {
    vehicles: VehicleModel[],
}

const useStartStop = (journey?: JourneyModel) => {
    return React.useMemo(()=>{
        if(!journey){
            return null
        }
        return {
            start: {
                lng : journey.start.lng,
                lat : journey.start.lat,
            },
            stop: {
                lng : journey.stop.lng,
                lat : journey.stop.lat,
            },
        }
    },[journey])
}
const useOnlyActiveVehiclesFilter = (vehicles: VehicleModel[]) => {
    const onlyActiveVehicles = useSelector(RX.context.selectors.getOnlyActiveVehiclesFilter)
    return React.useMemo(()=>
        !onlyActiveVehicles ? vehicles : vehicles.filter((vehicle) => HC.getStatus(vehicle) === 1),
    [vehicles,onlyActiveVehicles])
}
const VehicleMap: React.FC<VehicleMapProps> = ({ vehicles: allVehicles }) => {
    const vehicles = useOnlyActiveVehiclesFilter(allVehicles)
    const theme = useTheme()
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const vehicleIdStr = pathname.split('/')[1]
    const mapType: string = useSelector(context.selectors.getMapType)
    const mapRef: any = useSelector(context.selectors.getMapRef)
    const journeyId = pathname.split('/')[3]
    const journey = useSelector((state: any) =>
        RX.journeys.selectors.getForID(journeyId || '')(state))
    const startMoment = get(journey, 'start.moment', '')
    const stopMoment = get(journey, 'stop.moment', '')
    const startStop = useKeepReference( useStartStop(journey) )
    const vehicle: VehicleModel | undefined = useSelector(RX.vehicles.selectors.getForID(vehicleIdStr || 0))
    const currentJourneyPolyline = React.useRef(null as any)
    const currentPresences = useKeepReference( useGetJourneyPresences(vehicle, startMoment, stopMoment) )
    const [currentPositionAlertShowed, setCurrentPositionAlertShowed] = React.useState(false)
    const fitBoundsWithPadding = useFitBoundsWithPadding()
    const zoomToObject = useZoomToObject()
    const [zoomToVehicles, setZoomToVehicles] = React.useState(false)
    const vehicleId = vehicle?.vehicle_id
    const hasJourney = !!journey
    const mapCenter = getMapCenter()

    const setMapRef = React.useCallback((ref) => {
        if (ref && !mapRef) {
            dispatch(context.actions.setMapRef(ref))
        }
    }, [dispatch, mapRef])

    const [bounds, setBounds] = React.useState(null as any)
    const [zoom, setZoom] = React.useState(10)


    const fetchPresences = React.useCallback(() => {
        if (timeDifference(startMoment, stopMoment) < settings.API_PRESENCES_MAX_INTERVAL) {
            dispatch(RX.presences.actions.fetchPresences({
                vehicleId   : vehicle?.vehicle_id || 0,
                startMoment : utils.parseDate(startMoment),
                stopMoment  : utils.parseDate(stopMoment),
            }))
        }
    }, [dispatch, startMoment, stopMoment, vehicle?.vehicle_id])

    React.useEffect(() => {
        if(mapRef && mapRef?.maps && mapRef?.map && hasJourney ) {
            if(currentJourneyPolyline && currentJourneyPolyline?.current && currentJourneyPolyline.current.setMap) {
                currentJourneyPolyline.current.setMap(null)
                currentJourneyPolyline.current = null
            }

            const coordinates = map(currentPresences,
                (item) => ({ lat: get(item, 'lat'),  lng: get(item, 'lng') }))


            const useStartStop = (!coordinates || !coordinates.length) && startStop
            const carPath = new mapRef.maps.Polyline({
                path: (!coordinates || !coordinates.length) && startStop
                    ?  [startStop.start,startStop.stop] : coordinates,
                geodesic      : true,
                strokeColor   : theme.palette.primary.main,
                strokeOpacity : 1.0,
                strokeWeight  : useStartStop? 0 : 10,
                icons         : [{
                    icon: {
                        path        : mapRef.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        scale       : 2,
                        fillOpacity : 0,
                        fillColor   : theme.custom.polyline.arrow,
                        strokeColor : theme.custom.polyline.arrow,
                    },
                    offset : '100%',
                    repeat : '60px',
                }],
            })

            if((!currentJourneyPolyline.current && coordinates.length > 0) || useStartStop)  {
                currentJourneyPolyline.current = carPath

                carPath.setMap(mapRef.map)
                zoomToObject(carPath)
            }
        }
    }, [mapRef, currentPresences, currentJourneyPolyline,startStop, hasJourney, zoomToObject, theme])

    //NOTE: let's keep this comment
    //useWhatChanged([mapRef, hasVehicle, currentPresences, currentJourneyPolyline, hasJourney, zoomToObject, theme])

    const { lat,lng } = vehicle?.current_info ?? {}
    React.useEffect(() => {
        if (mapRef && mapRef?.map && !hasJourney) {
            if(lat && lng) {
                fitBoundsWithPadding([
                    {
                        lat ,
                        lng ,
                    },
                ])
                mapRef.map.setZoom(17)
            } else {
                if(!currentPositionAlertShowed && vehicleId != null) {
                    dispatch(RX.ui.showToast({
                        id      : 'CURRENT_POSITION_FAILED',
                        message : RX.i18n.t('Current position for this vehicle is not available'),
                        intent  : RX.ui.INTENT.error,
                    }))
                    setCurrentPositionAlertShowed(true)
                }
            }
        }
    }, [vehicleId,hasJourney,lat,lng, mapRef,
        dispatch, currentPositionAlertShowed, fitBoundsWithPadding])


    React.useEffect(() => {
        if (mapRef && mapRef?.map && vehicle && journey) {
            fetchPresences()
        }
    }, [vehicle, mapRef, fetchPresences, journey])

    React.useEffect(() => {
        if (mapRef && mapRef?.map && !vehicle && !journey && vehicles && vehicles.length > 0 && !zoomToVehicles) {
            const points = vehicles.reduce((acc: any,item)=>{
                const cInfo = item.current_info
                if(!cInfo){
                    return acc
                }


                return acc.concat(
                    {
                        lat : item.current_info?.lat,
                        lng : item.current_info?.lng,
                    }
                )
            }, [])

            fitBoundsWithPadding(points)
            setZoomToVehicles(true)
        }
    }, [vehicle, mapRef, fetchPresences, journey, fitBoundsWithPadding, vehicles, zoomToVehicles])

    React.useEffect(() => {
        if(mapRef && mapRef?.maps && mapRef?.map && vehicle && !journey) {
            if(currentJourneyPolyline && currentJourneyPolyline?.current && currentJourneyPolyline.current.setMap) {
                currentJourneyPolyline.current.setMap(null)
                currentJourneyPolyline.current = null
            }

            const coordinates = map(currentPresences,
                (item) => ({ lat: get(item, 'lat'),  lng: get(item, 'lng') }))

            const carPath = new mapRef.maps.Polyline({
                path          : coordinates,
                geodesic      : true,
                strokeColor   : "#000000",
                strokeOpacity : 1.0,
                strokeWeight  : 2,
            })

            if(!currentJourneyPolyline.current && coordinates.length > 0) {
                currentJourneyPolyline.current = carPath

                carPath.setMap(mapRef.map)
                zoomToObject(carPath)
            }
        }
    }, [mapRef, vehicle, currentPresences, currentJourneyPolyline, journey, zoomToObject])

    return (
        <>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
                defaultCenter={mapCenter}
                defaultZoom={defaultProps.zoom}
                options={{
                    zoomControl       : false,
                    mapTypeId         : mapType,
                    fullscreenControl : false,
                    rotateControl     : false,
                }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => setMapRef({ map, maps })}
                onChange={({ zoom, bounds }) => {
                    setZoom(zoom)
                    setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat,
                    ])
                }}
                style={{
                    paddingLeft: 200,
                }}
            >
                {
                    JourneyMap()
                }
                {
                    MapJourneysMarkers()
                }
                {
                    MapClusters(
                        vehicles,
                        zoom,
                        bounds
                    )
                }
                {
                    SelectedAddressPin()
                }
            </GoogleMapReact>
        </>
    )
}
export default VehicleMap
