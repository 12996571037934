import React from "react"
import { useSelector } from "react-redux"
import { drawerWidth } from "../components/vehicle-drawer"
import * as RX from "../redux"

export const useFitBoundsWithPadding = () => {
    const mapRef = useSelector(RX.context.selectors.getMapRef)
    const drawerStatus = useSelector(RX.context.selectors.getDrawerStatus)

    const fitBoundsWithPadding: any = React.useCallback((coordinates): any => {
        const padding = {
            left   : drawerStatus === "open" ? drawerWidth : 20,
            top    : 80,
            bottom : 20,
            right  : 20,
        }

        var bounds = new mapRef.maps.LatLngBounds()
        for (var n = 0; n < coordinates.length; n++) {
            bounds.extend(coordinates[n])
        }

        mapRef.map.fitBounds(bounds, padding)

        if(coordinates.length === 1 && drawerStatus === "open") {
            mapRef.map.panBy(- (drawerWidth*9), 0)
        }
    }, [drawerStatus, mapRef])

    return fitBoundsWithPadding
}

export const useZoomToObject = () => {
    const fitBoundsWithPadding = useFitBoundsWithPadding()

    const zoomToObject = React.useCallback((carPath) => {
        var points = carPath.getPath().getArray()

        return fitBoundsWithPadding(points)
    }, [fitBoundsWithPadding])

    return zoomToObject
}
