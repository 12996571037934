import * as React from 'react'
import HeaderLabels from './HeaderLabels'
import HeaderSegments from './HeaderSegments'
import { Interval,split } from './base'

type Props = {
    checkpoint: number, // in tiks
    domain: Interval<number>,
    labelText: (labelId: number) => string,
    headerSegmentsContainerStyle?: React.CSSProperties,
}
const Header : React.FC<Props> = ({
    domain,
    checkpoint,
    labelText,
    headerSegmentsContainerStyle,
}) => {
    const segments = split(domain.start,domain.end,checkpoint)
    return (
        <>
            <HeaderLabels segments={segments} labelText={labelText} />
            <HeaderSegments segments={segments} style={headerSegmentsContainerStyle} />
        </>
    )
}

export default Header
