import { channel as createChannel } from 'redux-saga'
import { fork, take, takeEvery, takeLatest, put, select, delay, race, call } from 'redux-saga/effects'
import { ActionCreator,CompanyStructureModel,VehicleModel } from '@safefleet/react-common'
import { keys } from 'lodash'
import * as redux from '..'
import * as actions from './actions'
import { ACTIVATED, CLOSED } from '../app/actions'
import { isActive } from '../app/selectors'
import { shouldPollingRun,getFleetId } from './selectors'
import { setVehicles } from '../vehicles/actions'

interface UploadFileModel {
    type: string,
    data: string,
    tag: string,
    profilePhoto: boolean
}
const channel = createChannel()
const POLLING_DELAY = 30 * 1000

export function* saga() {
    yield takeLatest([redux.auth.actions.LOGIN_SUCCEEDED, redux.app.actions.ACTIVATED], fetchData)
    yield takeEvery(redux.api.users.GET_SUCCEEDED, handleUserUpdate)
    yield takeLatest(actions.UPLOAD_FILE, handleFileUpload)
    yield fork(redux.commonUtils.watchChannel, channel)
    yield takeEvery(redux.api.users.GET_SUCCEEDED, (handleUserUpdate as any))
    yield takeEvery(redux.api.users.UPDATE_SUCCEEDED, (handleUserUpdateSucceeded as any))
    yield takeLatest(actions.SET_FLEET_ID,fetchFleetVehicles)
    yield takeLatest(actions.SET_COMPANY_ID,handleCompanyChanged)
    //yield takeEvery(redux.api.vehicles.GET_SUCCEEDED, (handleVechiclesUpdate as any))
    yield takeEvery(actions.REFRESH_VEHICLE, handleVehicleRefresh)
    yield takeLatest([
        redux.api.vehicles.UPDATE_SUCCEEDED,
        redux.api.vehicleAttributes.UPDATE_SUCCEEDED],
    handleVehicleUpdate)
    yield takeLatest([
        redux.api.vehicles.UPDATE_FAILED,
        redux.api.vehicleAttributes.UPDATE_FAILED],
    handleVehicleUpdateFailed)
    yield takeEvery(ACTIVATED, startPollTaskWatcher)
    yield takeEvery(CLOSED, stopPollTaskWatcher)
    //yield takeEvery(actions.VEHICLE_CHANGED, onVehicleChange)
}

// function* onVehicleChange(vehicleId) {
//     const mapRef = yield select(redux.context.selectors.getMapRef)
//     const vehicle = yield select(redux.vehicles.selectors.getForID(vehicleId || 0))

// }

function* startPollTaskWatcher() {
    const isAuthenticated = yield select(redux.auth.isAuthenticated)
    const currentState = yield select(shouldPollingRun)

    if (!isAuthenticated || currentState) {
        return
    }
    yield delay(POLLING_DELAY)
    yield put(actions.startPolling())

    while (yield select(isActive)) {
        yield race([call(pollTask), take(actions.STOP_POLLING_TASK)])
    }
    yield put(actions.stopPolling())
}

function* stopPollTaskWatcher() {
    yield put({ type: actions.STOP_POLLING_TASK })
}
function* handleCompanyChanged(action: actions.SetCompanyIdAction ) {
    const { companyId } = action.payload
    if(companyId === 'mine'){
        yield(put(redux.companyStructures.actions.fetchMyCompanyStructure({ })))
    }else {
        yield(put(redux.companyStructures.actions.fetchCompanyStructure({ companyId })))
    }
    yield awaitCompanyStructureFetching()

    const companyStructure: CompanyStructureModel | null = yield select(
        redux.companyStructures.selectors.getForID(companyId)
    )
    if (companyStructure && companyStructure.fleets.length) {
        const { fleet_id: fleetId } = companyStructure.fleets[0]
        yield put(actions.setFleetId(fleetId))
    } else {
        yield put(setVehicles({}))
        if (companyStructure?.company_id) {
            yield put(actions.setFleetId(companyStructure?.company_id))
        }
    }
}
function* fetchFleetVehicles(autoRefresh?: boolean) {

    const fleetId: number = yield select(getFleetId)

    const tag =  autoRefresh ? redux.tags.AUTO_REFRESH : undefined
    yield put(redux.vehicles.actions.fetchFleetVehicles({
        fleetId,
        tag,
        overwrite: true,
    }))

    yield put(redux.fleetAttributes.actions.fetch({
        fleetId: fleetId || undefined,
    }))

    yield take([redux.api.vehicles.GET_SUCCEEDED, redux.api.vehicles.GET_FAILED]) // wait for response
    const vehicles: {[key: number]: VehicleModel} = yield select(redux.vehicles.selectors.getAll)
    const coordinates = Object.values(vehicles).reduce((acc,{ current_info: info })=> {
        if(info && info.lat && info.lng){
            return acc.concat({ lat: info.lat,lng: info.lng })
        }
        return acc
    }, [] as {lat:number,lng:number}[] )
    if(coordinates.length){
        yield put(redux.positions.actions.lookup({ coordinates }))
    }
}
function* pollTask() {
    yield put({ type: actions.POLLING_TASK_REQUESTED })
    yield fetchFleetVehicles(true)
    yield put({ type: actions.POLLING_TASK_FINISHED })
    yield delay(POLLING_DELAY)
}

function* awaitCompanyStructureFetching() {
    yield take([redux.api.companyStructures.GET_SUCCEEDED, redux.api.companyStructures.GET_FAILED]) // wait for response
}
function* fetchData() {
    const auth = yield select(redux.auth.actions.getJwtTokens)
    yield put(redux.auth.actions.refreshToken(auth))
    yield(put(redux.companyStructures.actions.fetchMyCompanyStructure({})))
    yield awaitCompanyStructureFetching()
    yield fetchFleetVehicles()
    yield put(redux.users.actions.fetchCurrentUser({}))
    yield put(redux.fleetAttributes.actions.fetch({}))
    yield put(redux.drivers.actions.fetchDrivers({}))
    yield put(redux.companies.actions.fetchAllCompanies({}))
}

function* handleUserUpdateSucceeded({ payload }: any) {
    const { url, requestData, data } = payload
    const userId = parseInt(keys(data)[0], 10)
    const user = yield select(redux.users.selectors.getForID(userId || 0))

    if (url === `/users/${userId}`) {
        if (requestData && requestData?.profile_photo && !user.profile_photo) {
            yield put(redux.users.actions.fetchCurrentUser({}))
        }
    }
}

// function* handleVechiclesUpdate(props: any) {
//     // TODO: hardcoded B-777-LNT selection
//     // const id = 825
//     // const id = 781
//     const idStr = Object.keys(props.payload.data)[0] as (string | null)
//     const id = idStr ? parseInt(idStr, 10) : null
//     if (id != null && props.payload.data[id]) {
//         //TODO:  WHY bellow line
//         //yield put(actions.changeVehicle(id))
//         // TODO: Optimize here to get vehice from request/data directly
//         const vehicle: VehicleModel = yield select(redux.vehicles.selectors.getForID(id))
//         const { lat = null, lng = null } = vehicle?.current_info || {}

//         if (lat != null && lng != null) {
//             yield put(redux.positions.actions.lookup({ coordinates: [{ lat, lng }] }))
//         }
//     }
// }

function* handleVehicleRefresh({ payload }: any) {
    const { vehicleId } = payload

    yield put(redux.vehicles.actions.fetchVehicle({ vehicleId }))

    yield take(redux.api.vehicles.GET_SUCCEEDED)

    const vehicle = yield select(redux.vehicles.selectors.getForID(vehicleId))
    const { lat = null, lng = null, driverId = null } = vehicle?.current_info || {}

    if (lat != null && lng != null) {
        yield put(redux.positions.actions.lookup({ coordinates: [{ lat, lng }] }))
    }

    yield put(redux.fleetAttributes.actions.fetch({}))

    if (typeof driverId === 'number') {
        const driver = yield select(redux.drivers.selectors.getForID(driverId))
        if (!driver) {
            yield put(redux.drivers.actions.fetchDriver({ driverId }))
        }
    }
}

function* handleUserUpdate({ payload }: any) {
    const { url, data } = payload

    if (url === '/users/me') {
        try {
            const userId = parseInt(keys(data)[0], 10)
            const savedUser: number | null = yield select(redux.context.selectors.getUserId)
            if (userId !== savedUser) {
                yield put({ type: actions.USER_CHANGED, payload: { userId } })
            }
        } catch (e) {
            // eslint-disable-next-line no-useless-return
            return
        }
    }
}

function* handleFileUpload({ payload }: ReturnType<ActionCreator<UploadFileModel>>) {
    const { type, data, tag } = payload!
    const userId: number | null = yield select(redux.context.selectors.getUserId)
    if (!userId || !type || !data) return
    const prefix = `data:${type};base64,`

    yield put(redux.users.actions.updateUser({ userId, data: { profile_photo: prefix + data }, tag }))
}

function* handleVehicleUpdate({ payload: { data = {} } }: any) {
    // @ts-ignore
    const id = Object.values(data)?.[0]?.vehicle_id

    if (id) {
        yield put(redux.vehicles.actions.fetchVehicle({ vehicleId: id }))
    }

    yield put(redux.ui.showToast({
        id      : 'VEHICLE_UPDATE',
        message : redux.i18n.t('The vehicle has been successfully updated'),
        intent  : redux.ui.INTENT.success,
    }))
}
function* handleVehicleUpdateFailed() {
    yield put(redux.ui.showToast({
        id      : 'VEHICLE_UPDATE_FAILED',
        message : redux.i18n.t('Failed to update vehicle'),
        intent  : redux.ui.INTENT.error,
    }))
}
