import { VehicleModel } from '@safefleet/react-common'
import { get, round } from 'lodash'
import { useUserUnit } from './user'
import * as RC from '@safefleet/react-common'
import * as redux from '../redux'
import { includes } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import constants, { VehicleStatusKey } from '../constants'
import { currentLocale } from '../settings'
import { useGetJourneyPresences } from '../utils'
import React from 'react'
import { getPositionsForPresences } from "../redux/positions/selectors"

const { utils : commonUtils,i18n } = RC

const COMBUSTION_TYPE = ['Gasoline', 'Diesel', 'LPG', 'Electric']

const DISTANCE_PRECISION = 2

export const getStatus = (vehicle: VehicleModel): VehicleStatusKey => (vehicle.current_info == null
    ? -1
    : (vehicle.current_info?.status as any))

export const engineValue = (vehicle: RC.VehicleModel): [string,string | null] => {

    const status = vehicle.current_info?.status

    const engineSince = vehicle.current_info?.engine_since

    const locale = currentLocale()

    const statusStr = status != null ? constants.vehicleStatus[status] : 'n/a'
    let sinceText = ''

    if (engineSince) {
        const moment = commonUtils.parseDate(engineSince)
        const engineSinceStr = format(moment, 'Pp', { locale })
        //TODO: add this too but when we have multiple lines,
        //+ formatDistanceToNow(moment, { addSuffix: true, includeSeconds: true, locale })
        sinceText = `${engineSinceStr}`
    }

    return [
        `${i18n.t('Engine')} ${i18n.t(statusStr)} ${sinceText && i18n.t('since')} `,
        sinceText,
    ]
}

export type HookedVehicleExtraValues = {
    speed: string,
    tankOrBattery: TankOrBatteryLevel | null,
}
export type TankOrBatteryLevel = {
    icon: string;
    value: string;
}

export const useVehicleExtraValues = (
    vehicle: RC.VehicleModel | null
): HookedVehicleExtraValues => {
    const speed = useSpeed( vehicle )
    const tankOrBattery = useTankOrBatteryLevel( vehicle )
    return  {
        speed,
        tankOrBattery,
    }
}

export const useSpeed = (
    vehicle: VehicleModel | null
) => {
    const { toUserSpeed } = useUserUnit()
    return toUserSpeed(get(vehicle, 'current_info.speed', 0))
}

export const useVehicleAddress= (
    { current_info: cInfo }: RC.VehicleModel
) => {
    const selector = RC.positions.selectors.getForCoordinates
    const position = useSelector(cInfo && cInfo.lat && cInfo.lng
        ? selector({ lat: cInfo.lat,lng: cInfo.lng }) : ()=>undefined )

    if(!position){
        return null
    }
    return position.display_name
}

const useTankOrBatteryLevel = (
    vehicle: RC.VehicleModel | null
): TankOrBatteryLevel | null => {
    //const { fuelType, isElectric, batteryCapacity, tankCapacity, vehicle } = props

    const batteryCharging =  vehicle?.current_info?.battery_charging

    const fuelType = useSelector(RC.fleetAttributes.selectors.getAttribute(
        vehicle?.vehicle_id??-1 , 'fuel_type'
    ))?.value
    const isElectric = fuelType === 'Electric'
    const batteryCapacity = useSelector(RC.fleetAttributes.selectors.getAttribute(
        vehicle?.vehicle_id??-1, 'battery_capacity'
    ))?.value
    const tankCapacity = useSelector(RC.fleetAttributes.selectors.getAttribute(
        vehicle?.vehicle_id??-1, 'tank_capacity'
    ))?.value
    if (isElectric) {
        if (vehicle?.current_info?.battery_soc && batteryCapacity) {
            const availableBattery  = (vehicle.current_info.battery_soc * batteryCapacity) / 100

            return {
                //icon  : batteryCharging ? 'fl/state-of-charge' : 'fl/charging-status',
                icon  : batteryCharging ? 'fl/state-of-charge' : 'fl/charging-status',
                value : `${vehicle.current_info.battery_soc.toFixed(2)}% - ${availableBattery.toFixed(2)} kWh`,
            }
        }
    } else if (includes(COMBUSTION_TYPE, fuelType)) {
        if (vehicle?.current_info?.fuel_level && tankCapacity) {
            const availableFuel  = (vehicle.current_info.fuel_level * tankCapacity) / 100
            return {
                icon  : 'fl/fuel-indicator',
                value : `${vehicle.current_info.fuel_level.toFixed(2)}% - ${availableFuel.toFixed(2)} L`,
            }
        }
    }

    return null
}
export const getMomentValue = (vehicle: RC.VehicleModel,locale: Locale,formatMode: string = 'Pp') =>{
    const moment = vehicle.current_info?.moment
    if(!moment){
        return null
    }
    return format(commonUtils.parseDate(moment),formatMode,{ locale })
}

export const usePositions = (
    vehicle: VehicleModel | undefined,
    startMomentRaw: Date,
    stopMomentRaw: Date
) => {
    const dispatch = useDispatch()
    const currentPresences = useGetJourneyPresences(
        vehicle,
        startMomentRaw,
        stopMomentRaw
    )

    const coordinates = React.useMemo(() => {
        return currentPresences.map((presence) => { return {
            lat    : presence.lat,
            lng    : presence.lng,
            moment : presence.moment,
        }})
    }, [currentPresences])

    React.useEffect(() => {
        if(coordinates && coordinates.length > 0) {
            dispatch(redux.positions.actions.lookup({ coordinates: coordinates }))
        }
    }, [coordinates, dispatch])

    const positions = useSelector((state: any) => getPositionsForPresences(
        state, { coordinates }
    ))


    return positions
}
export const distance = (value) => {
    return i18n.t('{{distance}} km', { distance: round(value, DISTANCE_PRECISION) })
}
