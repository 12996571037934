import React from 'react'
import { SvgLink } from "./SvgLink"
import copyIcon from '../../assets/img/copy.svg'
import { i18n, ui } from "@safefleet/react-common"
import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'

export const CopyLink = () => {
    const dispatch = useDispatch()
    const pathName = useLocation().pathname
    const origin = window.location.origin.toString()

    const handleButtonPress = React.useCallback(() => {
        const address = origin + pathName

        dispatch(ui.showToast({
            id      : 'ADDRESS_COPIED_SUCCESSFULLY',
            message : i18n.t('Address was successfully copied to the clipboard'),
            intent  : ui.INTENT.success,
        }))
        return navigator.clipboard.writeText(address)
    }, [origin, pathName, dispatch])

    return (
        <SvgLink
            icon={copyIcon}
            text={i18n.t('Copy link')}
            action={handleButtonPress}
        />
    )
}