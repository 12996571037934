import * as React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { textSizes } from '../theme'
import { Theme } from '@mui/material/styles'


const baseStyle = (theme: Theme) => ({
    title: {
            /* Title */
        fontFamily : 'Roboto',
        fontStyle  : 'normal',
        fontWeight : 700,
        fontSize   : `${textSizes.title}px`,
        lineHeight : '28px',

        color: theme.custom.text.title,
    },
    sectionTitle: {
        /* Section_title */
        fontFamily    : 'Roboto',
        fontStyle     : 'normal',
        fontWeight    : 700,
        fontSize      : `${textSizes.sectionTitle}px`,
        lineHeight    : '19px',
        /* identical to box height */
        letterSpacing : '0.015em',

        color: theme.custom.text.title,
    },
    sectionSmall: {
        /* Section_small_regular */
        fontFamily : 'Roboto',
        fontStyle  : 'normal',
        fontWeight : 400,
        fontSize   : `${textSizes.sectionSmall}px`,
        lineHeight : '14px',
        /* identical to box height */

        letterSpacing : '0.02em',
        /* Neutrals/Black */
        color         : theme.custom.text.title,
    },
    sectionRegular: {
        /* Section_small_regular */
        fontFamily : 'Roboto',
        fontStyle  : 'normal',
        fontWeight : 400,
        fontSize   : `${textSizes.section}px`,
        lineHeight : '16px',
        /* identical to box height */
        /* Section_14_regular */

        /* Neutrals/Black */
        color: theme.custom.text.title,
    },
    capsRegular: {
        /* caps_regular */
        fontFamily : 'Roboto',
        fontStyle  : 'normal',
        fontWeight : 400,
        fontSize   : `${textSizes.sectionSmall}px`,
        lineHeight : '14px',

        /* identical to box height */
        letterSpacing : '0.04em',
        textTransform : ('uppercase' as any),

        /* Neutrals/Black */
        color: theme.custom.text.title,
    },
})
export const style = (theme: Theme) => {
    return ({
        ...baseStyle(theme),
        titleRegular: {
            ...baseStyle(theme).title,
            fontWeight: 400,
        },
        sectionText: {
            ...baseStyle(theme).sectionTitle,
            fontWeight: 400,
        },
        sectionSmallBold: {
            ...baseStyle(theme).sectionSmall,
            fontWeight: 700,
        },
        capsBold: {
            ...baseStyle(theme).capsRegular,
            fontWeight: 700,
        },

    })
}

export const Title = styled(Box)(({ theme }) => style(theme).title)
export const TitleRegular = styled(Box)(({ theme }) => style(theme).titleRegular)
export const TitleRegularGray = styled(TitleRegular)(
    ({ theme }) => ({
        color: theme.custom.text.details,
    })
)
export const SectionTitle = styled(Box)(({ theme }) => style(theme).sectionTitle)
export const SectionText = styled(Box)(({ theme }) => style(theme).sectionText)
export const SectionTextGray = styled(SectionText)(
    ({ theme }) => ({
        color: theme.custom.text.details,
    })
)
export const SectionRegular = styled(Box)(({ theme }) => style(theme).sectionRegular)
export const SectionRegularGray = styled(SectionRegular)(
    ({ theme }) => ({
        color: theme.custom.text.details,
    })
)
export const SectionRegularDenim = styled(SectionRegular)(
    ({ theme }) => ({
        color      : theme.custom.text.button,
        fontWeight : 700,
    })
)

export const SectionSmall = styled(Box)(({ theme }) => style(theme).sectionSmall)
export const SectionSmallBold = styled(Box)(({ theme }) => style(theme).sectionSmallBold)
export const SectionSmallGray = styled(SectionSmall)(
    ({ theme }) => ({
        color: theme.custom.text.details,
    })
)
export const CapsRegular = styled(Box)(({ theme }) => style(theme).capsRegular)
export const CapsBold = styled(Box)(({ theme }) => style(theme).capsBold)
export const CapsRegularGray = styled(CapsRegular)( ({ theme }) => ({
    color: theme.custom.text.details,
}))
export const ButtonText = styled(SectionText)(({ theme })=>({
    color: theme.custom.text.button,
}))

//TODO: bellow should be removed after all are changed according to above ones
export const textStyle: React.CSSProperties={
    display    : 'inline',
    fontStyle  : 'normal',
    fontSize   : '14px',
    lineHeight : '19px',
}

export const BoldText = styled(Box)(
    {
        ...textStyle,
        fontWeight: 'bold',
    }
)

export const GrayText = styled(Box)(
    ({ theme }) => ({
        ...textStyle,
        color: theme.custom.text.details,
    })
)
const footerTextStyle: React.CSSProperties={
    display   : 'inline',
    fontStyle : 'normal',
    fontSize  : '11px',
}
export const SmallBoldText = styled(Box)(
    () => ({
        ...footerTextStyle,
        fontWeight: 'bold',
    })
)

