import * as React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { useFitBoundsWithPadding } from '../../hooks/map'
import * as RX from '../../redux'

const VehicleSelection = () => {
    let params = useParams()
    const { pathname } = useLocation()
    const journeyId = pathname.split('/')[3]
    const vehicleId = parseInt(params.vehicleId || '')
    const vehicle = useSelector(RX.vehicles.selectors.getForID(vehicleId))
    const mapRef = useSelector(RX.context.selectors.getMapRef)
    const current_info = vehicle?.current_info
    const fitCoordinatesWithPadding = useFitBoundsWithPadding()
    const { lat,lng } = current_info ?? {}

    React.useEffect(() => {
        if (mapRef && mapRef?.map && lat && lng && !journeyId) {
            fitCoordinatesWithPadding([
                {
                    lat,
                    lng,
                },
            ])
            mapRef.map.setZoom(17)
        }

    }, [lat,lng, mapRef, journeyId, fitCoordinatesWithPadding])
    return null
}

export default VehicleSelection
