import * as React from 'react'
import Timeline  from '../timeline'
import * as RC from '@safefleet/react-common'
import {
    Interval,
    IntervalLine,
    sortIntervals,
    unifyIntervals,
    dateIntervalToNumber,
} from '../timeline'
import * as Utils from '../../utils'
const { parseDate } = RC.utils

const workingHoursIntervalToLines = (
    internals: Utils.InOutWorkingHoursInterval
): IntervalLine<number>[] => {
    return [{
        intervals : internals.duringWorkingHours.map(dateIntervalToNumber),
        color     : '#36C24C',
        name      : RC.i18n.t('During the working schedule'),
        height    : 15,

    },{
        intervals : internals.ousideWorkingHours.map(dateIntervalToNumber),
        color     : '#43474B',
        name      : RC.i18n.t('Outside the working schedule'),
        height    : 15,
    }]
}

const fromJourneyToInterval = ({ start,stop }:RC.JourneyModel): Interval<Date> => ({
    start : parseDate(start.moment),
    end   : parseDate(stop.moment),
})


const journeysToWorkingScheduleIntervals = (
    journeys: RC.JourneyModel[],
    schedule: Utils.WorkingInterval[]
): Utils.InOutWorkingHoursInterval => {
    const intervals = unifyIntervals(
        sortIntervals(
            [journeys.map(fromJourneyToInterval)],
            (a:Date,b:Date) => a.getTime() - b.getTime()
        )
    )
    return Utils.splitIntervalsByWorkingSchedule(
        intervals,
        schedule
    )
}

type WorkingTimeLineProps = {
    journeys: RC.JourneyModel[],
    domainStarts: number,
    domainEnds: number,
    schedule: Utils.WorkingInterval[],
    checkpoint: number,
    headerLabelText: (text:number) => string,
    showLegend?: boolean,
}
const WorkingTimeLine: React.FC<WorkingTimeLineProps> = ({
    journeys,
    domainStarts,
    domainEnds,
    schedule,
    checkpoint,
    headerLabelText,
    showLegend,
}) => {

    const workingIntervals = React.useMemo(
        ()=>journeysToWorkingScheduleIntervals(
            journeys,
            schedule
        ),
        [ journeys,schedule ]
    )
    const lines = React.useMemo(
        ()=> workingHoursIntervalToLines(workingIntervals),
        [workingIntervals]
    )
    const domain: Interval<number> = React.useMemo(()=>({
        start : domainStarts,
        end   : domainEnds,
    }),[domainStarts,domainEnds])

    const lastWeekIdleProps = {
        lines,
        checkpoint,
        domain,
        headerLabelText,
        showLegend,
    }
    return <Timeline {...lastWeekIdleProps} />
}
export default WorkingTimeLine
