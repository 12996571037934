import JourneyItem from "./JourneyItem"

const MostRecentJourneysList = ({ journeys }) => {
    return (
        <div>
            {
                journeys.map((item, index) => {
                    return (
                        <JourneyItem
                            journey={ item }
                            index={index + 1}
                        />
                    )
                })
            }
        </div>
    )
}

export default MostRecentJourneysList