import * as React from 'react'
import { useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import { i18n } from '@safefleet/react-common'
import IconCmp from '../ui/Icon'
import * as RX from '../../redux'
import { useParams,useNavigate } from "react-router-dom"
import { Title,SectionTextGray } from '../Text'
import arrowBack from '../../assets/img/arrow-back.svg'
import car from '../../assets/img/car.svg'
import { spaces } from '../../theme'

type ContainerProps = {
    children?: any,
    fstIconClick: ()=>any,
}
const useCommon = () => {
    let params = useParams()
    const vehicleId = parseInt(params.vehicleId || '')
    const vehicle = useSelector(RX.vehicles.selectors.getForID(vehicleId))
    const model = vehicle ? removeUnknown(`${vehicle.maker} ${vehicle.model}`).trim() : ''
    const licensePlate = vehicle?.license_plate
    return  { params,vehicle,model, licensePlate }
}
export const Container: React.FC<ContainerProps> = ({ children,fstIconClick }) => {
    return (
        <div style={{
            paddingTop    : `${spaces.headerLine[2]}px`,
            display       : 'flex',
            paddingRight  : `${spaces.headerLine[1]}px`,
            flexDirection : 'row',
            alignItems    : 'center',
            alignSelf     : 'stretch',
        }} >
            {/*TODO: because of the b*/}
            <IconButton onClick={fstIconClick} size="large" >
                <IconCmp
                    source={arrowBack}
                    size={24}
                    alt={'go back' || ''}
                />
            </IconButton>
            {children}
        </div>
    )
}

export const JourneyHeader: React.FC<{}> =  () => {
    const navigate = useNavigate()
    const { vehicle,model } = useCommon()

    const fstIconClick = () => {
        navigate(-1)
    }

    return (
        <Container fstIconClick={fstIconClick} >
            <div style={{ display: 'flex',flexDirection: 'row',flex: '1',textAlign: 'left',alignItems: 'center' }}>
                <SectionTextGray >
                    {model} {vehicle?.default_driver}
                </SectionTextGray>
                <Title style={{ flex: 1, textAlign: 'right' }}>
                    {i18n.t('Journey details')}
                </Title>
            </div>
        </Container>
    )
}

export const VehicleHeader: React.FC<{}> =  () => {
    const navigate = useNavigate()
    const { licensePlate } = useCommon()
    const fstIconClick = React.useCallback(() => {
        navigate(-1)
    },[navigate])

    return (
        <Container fstIconClick={fstIconClick} >
            <Title style={{ flex: '1',textAlign: 'left' }}>
                {licensePlate}
            </Title>
            <IconCmp
                source={car}
                size={24}
                alt={'car' || ''}
                style={{  }}
            />
        </Container>
    )
}

//TODO: ugly hack
const removeUnknown = (str: string) => {
    var re = new RegExp('unknown', 'g')
    return str.replace(re, '')
}
