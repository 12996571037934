import IconCmp from '../ui/Icon'
import pinIcon from '../../assets/img/pin.svg'
import poiIcon from '../../assets/img/poiMarker.svg'
import circleIcon from '../../assets/img/circle.svg'
import * as redux from '../../redux'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import format from 'date-fns/format'
import { SectionTitle, SectionText } from '../Text'
import { spaces } from '../../theme'


const Point = ({ date, address, icon }) => {
    return (
        <div style={{
            display       : 'flex',
            flexDirection : 'row',
            alignItems    : 'center',
        }}>
            <IconCmp
                source={icon}
                size={18}
                alt={''}
            />
            <SectionTitle
                sx={{
                    marginLeft: `${spaces.textLine[0]}px`,
                }}
            >
                {format(new Date(date), 'HH:mm')}
            </SectionTitle>
            <div style={{
                display      : 'inline-block',
                whiteSpace   : 'nowrap',
                textOverflow : 'ellipsis',
                overflow     : 'hidden',
                paddingRight : spaces.textLine[0],
                marginLeft   : `${spaces.textLine[0]}px`,
            }}>
                <SectionText
                >
                    {!isEmpty(address) ?
                        address?.display_name :
                        redux.i18n.t('(unknown address)')}
                </SectionText>
            </div>
        </div>
    )
}

const Divider = () => {
    return (
        <div style={{
            height          : 12,
            width           : 2,
            backgroundColor : '#C4C4C4',
            marginTop       : 3,
            marginBottom    : 3,
            marginLeft      : 7,
        }} />
    )
}

const JourneyPoiItem = ({ journey, pois }) => {
    const start = journey.start
    const stop = journey.stop

    const startAddress = useSelector(
        redux.positions.selectors.getForCoordinates({ lat: start.lat, lng: start.lng })
    )

    const stopAddress = useSelector(
        redux.positions.selectors.getForCoordinates({ lat: stop.lat, lng: stop.lng })
    )

    return (
        <div style={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}>
            <div style={{
                display       : 'flex',
                flexDirection : 'column',
            }}>
                <Point
                    date={start.moment}
                    icon={circleIcon}
                    address={startAddress}
                />
                {
                    pois.map((poi) => {
                        return (
                            <>
                                <Divider/>
                                <Point
                                    date={poi.moment}
                                    icon={poiIcon}
                                    address={{
                                        display_name: poi.address,
                                    }}
                                />
                            </>
                        )
                    })
                }
                <Divider/>
                <Point
                    date={stop.moment}
                    icon={pinIcon}
                    address={stopAddress}
                />
            </div>
        </div>
    )
}

export default JourneyPoiItem