import * as React from 'react'
import Divider from '@mui/material/Divider'
import { useSelector,useDispatch  } from 'react-redux'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { context } from "../../redux"
import FleetSelector from './FleetSelector'
import * as RC from '@safefleet/react-common'
import * as RX from '../../redux'
import { useTheme } from '@mui/material/styles'
import * as Text from '../Text'
import { spaces } from '../../theme'
const Filter: React.FC<any> =  () => {
    const dispatch = useDispatch()

    const filter  = useSelector((state: any) => RX.context.selectors.getSearchValue(
        state,{ screen: 'vehicles' }
    ))
    const setFilter = (value: string) => {
        dispatch(context.actions.setSearchValue('vehicles',value))
    }

    return (
        <Paper
            component="form"
            onSubmit={(e) => e.preventDefault()}
            sx={{
                display    : 'flex',
                alignItems : 'center',
                width      : '100%',

                marginBottom : '5px',
                height       : '48px',
                boxShadow    : '0px 2px 10px rgba(0, 0, 0, 0.1)',
                borderRadius : '8px',
            }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                value={filter}
                inputProps={{ 'aria-label': RC.i18n.t('search google maps') }}
                onChange={(event)=>{
                    setFilter(event.target.value)
                }}
            />
            <IconButton sx={{ p: '10px',color: '#C4C4C4'  }} aria-label="search">
                <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
                onClick={()=>setFilter('')}
                sx={{ p: '10px',color: '#C4C4C4' }}
                aria-label="directions"
            >
                <CloseIcon />
            </IconButton>
        </Paper>
    )
}
//
//const fromCompanyToFleet = (structure: CompanyStructureModel): FleetStructureModel[] => {
    //return structure.fleets
//}

const VehicleListHeader: React.FC<{}> = () => {
    const theme = useTheme()
    return (
        <div style={{
            alignSelf : 'stretch',
            padding   : theme.custom.header.padding,
        }}>

            <FleetSelector />
            <Text.SectionTextGray style={styles.sectionCaption} >{RC.i18n.t('Current Positions')}</Text.SectionTextGray>
            <Filter />
        </div>
    )
}


const styles = {
    sectionCaption: {
        textAlign     : 'left',
        marginBottom  : spaces.headerLine[1],
        verticalAlign : 'bottom',
    } as React.CSSProperties,
    paper: {
        display    : 'flex',
        alignItems : 'center',
        width      : '100%',

        marginBottom : '5px',
        boxShadow    : '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14),'
                + '0px 1px 10px rgba(0, 0, 0, 0.12);',
        height: '40px',
    },
}

export default VehicleListHeader
