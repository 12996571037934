import * as React from 'react'
import * as RC from '@safefleet/react-common'
import { Interval,useNumberInterval } from '../timeline'
import { useFilteredJourneysByInterval } from '../../hooks/journeys'
import {
    format,
} from 'date-fns'
import IdleTimeLine from './IdleTimeLine'
import IntervalLinesSummary, { useIdleIntervalLines } from './IntervalLinesSummary'

type DayIdleTimeLineProps = {
    journeys: RC.JourneyModel[],
    todayInterval: Interval<Date>,
    last24Interval: Interval<Date>,
}

const defaultDistance = 0

const DayIdleTimeLine : React.FC<DayIdleTimeLineProps> = ({
    journeys,
    todayInterval,
    last24Interval,
}) => {
    const last24Journeys = useFilteredJourneysByInterval(journeys,last24Interval)
    const todayJourneys = useFilteredJourneysByInterval(journeys,todayInterval)
    const last24Lines = useIdleIntervalLines(last24Journeys)
    const todayLines = useIdleIntervalLines(todayJourneys)
    const start = last24Interval.start.getTime()
    const end = last24Interval.end.getTime()
    const todayNumberInterval = useNumberInterval(todayInterval)
    const todayDistanceTravelled = todayJourneys ? todayJourneys.reduce((acc, journey)=>{
        return  acc + journey.distance
    }, defaultDistance) : defaultDistance
    const last24NumberInterval = useNumberInterval(last24Interval)
    const last24DistanceTravelled = last24Journeys ? last24Journeys.reduce((acc, journey)=>{
        return  acc + journey.distance
    }, defaultDistance) : defaultDistance


    return <>
        <IntervalLinesSummary
            lines={todayLines}
            text={RC.i18n.t('Today')}
            showReverseForInterval={todayNumberInterval}
            distance={todayDistanceTravelled}
        />
        <IntervalLinesSummary
            lines={last24Lines}
            text={RC.i18n.t('Last 24 hours')}
            showReverseForInterval={last24NumberInterval}
            distance={last24DistanceTravelled}
        />
        <IdleTimeLine
            lines={last24Lines}
            domainStarts={start}
            domainEnds={end}
            checkpoint={86400000/6}
            headerLabelText = { (x: number) => format(new Date(x),'HH:mm') }
        />
    </>
}

export default DayIdleTimeLine
