import * as React from 'react'
import { reverseIntervals } from '../timeline'
import * as RC from '@safefleet/react-common'
import {
    dateIntervalToNumber,
    Interval,
    IntervalLine,
    sortIntervals,
    unifyIntervals,
} from '../timeline'
import { SmallBoldText } from '../Text'
import { subSeconds } from 'date-fns'
import { getDHMSFromMs,stringFromDHMS } from '../../utils'
const { parseDate } = RC.utils

type IntervalLineSummaryProps ={
    line: IntervalLine<number>
}
export const IntervalLineSummary: React.FC<IntervalLineSummaryProps> = ({
    line,
}) => {
    const sum = line.intervals.reduce((acc,x)=>acc+(x.end - x.start),0)
    const timeStr = stringFromDHMS( getDHMSFromMs(sum) )
    const style = { paddingLeft: '5px', color: line.color }
    return <SmallBoldText sx={style}>{timeStr}</SmallBoldText>
}
type IntervalLinesSummaryProps ={
    lines: IntervalLine<number>[],
    text: string,
    showReverseForInterval?: Interval<number>,
    distance: number,
}

export const useLinesWithReverse = (
    lines: IntervalLine<number>[],
    domain?: Interval<number>
) => React.useMemo(() => {
    if(!domain){
        return lines
    }
    const revIntervals = reverseIntervals(
        unifyIntervals(
            sortIntervals(lines.map( (x) => x.intervals),(a,b) => a - b )
        ),
        domain
    )
    const reverseLine: IntervalLine<number> = {
        intervals : revIntervals,
        color     : '#DA2128',
        name      : '',
        height    : 0,
    }
    return lines.concat(reverseLine)
},[lines,domain])

export type IdleIntervals = {
    driving: Interval<Date>[],
    idle: Interval<Date>[],
}

const journeysToIdleIntervals = (
    journeys: RC.JourneyModel[]
): IdleIntervals => {
    const sortAndUnify = (intervals: Interval<Date>[]) => unifyIntervals(
        sortIntervals(
            [intervals],
            (a:Date,b:Date) => a.getTime() - b.getTime()
        )
    )
    const { driving, idle } = journeys.reduce((acc: IdleIntervals,{
        start:{ moment: startStr },
        stop:{ moment: endStr },
        idle_duration,
    })=>{
        const start = parseDate(startStr)
        const end = parseDate(endStr)
        if(!idle_duration){
            return {
                ...acc,
                driving: acc.driving.concat({ start,end }),
            }
        }
        const endDriving = subSeconds(end,idle_duration)
        return {
            driving : acc.driving.concat({ start,end: endDriving }),
            idle    : acc.idle.concat({ start: endDriving,end }),

        }
    },{
        driving : [],
        idle    : [],
    })

    return {
        driving : sortAndUnify(driving),
        idle    : sortAndUnify(idle),
    }
}


export const useIdleIntervalLines = (journeys: RC.JourneyModel[]): IntervalLine<number>[] => {
    return React.useMemo(
        ()=> {
            const idleJourneys = journeysToIdleIntervals(journeys)
            return [{
                intervals : idleJourneys.driving.map(dateIntervalToNumber),
                color     : '#36C24C',
                name      : RC.i18n.t('Driving'),
                height    : 15,

            },{
                intervals : idleJourneys.idle.map(dateIntervalToNumber),
                color     : '#1672CE',
                name      : RC.i18n.t('Engine On'),
                height    : 15,

            }]
        },
        [journeys]
    )


}


export const IntervalLinesSummary: React.FC<IntervalLinesSummaryProps> = ({
    lines: rawLines,
    text,
    showReverseForInterval,
    distance,
}) => {
    const lines = useLinesWithReverse(rawLines,showReverseForInterval)

    return (
        <div style={{
            display       : 'flex',
            flexWrap      : 'wrap',
            flexDirection : 'row',
            marginBottom  : '5px',
        }}>
            <SmallBoldText >{text}:</SmallBoldText>
            {lines.map((line,ix) => <IntervalLineSummary key={ix} line={line} /> )}
            <SmallBoldText sx={{ paddingLeft: '5px' }}>{ Math.round(distance) + " km" }</SmallBoldText>
        </div>
    )
}

export default IntervalLinesSummary
