import googlePin from "../../assets/img/google-pin.svg"
import Icon from "../ui/Icon"
import { SectionTitle } from "../Text"
import LoadingContainer from "../vehicle-drawer/LoadingContainer"

const PlacesList = ({ loading, places, getPlaceDetails }) => {


    return (
        <div
            style={{
                display       : "flex",
                flexDirection : "column",
                paddingTop    : 24,
                paddingBottom : 24,
            }}
        >
            {loading ? (
                <LoadingContainer />
            ) : (
                places.map((place) => (
                    <>
                        <div
                            key={place.place_id}
                            onClick={() => getPlaceDetails(place.place_id, place.description)}
                            style={{
                                display       : "flex",
                                alignItems    : "center",
                                flexDirection : "row",
                                marginLeft    : 20,
                                marginRight   : 20,
                                cursor        : "pointer",
                            }}
                        >
                            <Icon
                                source={googlePin}
                                size={18}
                                style={{ marginRight: 12 }}
                                alt={"google pin"}
                            />
                            <SectionTitle
                                style={{
                                    whiteSpace   : "nowrap",
                                    textOverflow : "ellipsis",
                                    overflow     : "hidden",
                                    color        : "#43474B",
                                }}
                            >
                                {place.description}
                            </SectionTitle>
                        </div>
                        <div
                            style={{
                                height          : "1px",
                                width           : "100%",
                                backgroundColor : "#EFEFEF",
                                marginTop       : 15,
                                marginBottom    : 15,
                            }}
                        />
                    </>
                ))
            )}
        </div>
    )
}

export default PlacesList
