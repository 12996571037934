import { SectionText } from './Text'
import React, { useCallback, useEffect, useState } from 'react'
import * as RX from '../redux'
import { useDispatch, useSelector } from 'react-redux'
import { spaces } from '../theme'
import { i18n } from '../redux'
import SearchIcon from "@mui/icons-material/Search"

type OptionsProps = {
    children?: any,
    height?: any,
}

const SearchButton: React.FC<OptionsProps> = () => {
    const dispatch = useDispatch()
    const addressDrawerStatus = useSelector(RX.context.selectors.getAddressSearchDrawerStatus)

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ])

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight])
        }
        window.addEventListener('resize', handleWindowResize)
        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    const drawerStatus = useSelector(RX.context.selectors.getDrawerStatus)
    const isDrawerOpen = drawerStatus === 'open'

    const getDispalySearch = React.useCallback(() => {
        return isDrawerOpen && windowSize[0] < 650 ? 'none' : 'block'
    }, [isDrawerOpen, windowSize])

    const handleDrawerToggle = useCallback(() => {
        if(addressDrawerStatus === 'open') {
            dispatch(RX.context.actions.setAddressSearchDrawerStatus('close'))
        } else {
            dispatch(RX.context.actions.setAddressSearchDrawerStatus('open'))
        }
    }, [dispatch, addressDrawerStatus])

    return (
        <div
            onClick={handleDrawerToggle}
            style={{
                position : 'absolute',
                right    : 24,
                top      : 100,
                display  : getDispalySearch(),
            }}
        >
            <div
                style={{
                    backgroundColor : 'white',
                    borderRadius    : spaces.textLine[1],
                    padding         : spaces.textLine[0],
                    flexDirection   : 'row',
                    alignItems      : 'center',
                    display         : 'flex',
                    cursor          : 'pointer',
                    boxShadow       : `0px 2px ${spaces.textLine[0]}px 0px #0000001A`,

                }}
            >
                <SectionText
                    sx={{
                        marginLeft  : "7px",
                        marginRight : "17px",
                    }}
                >
                    {i18n.t('Search...')}
                </SectionText>

                <SearchIcon fontSize={"small"}/>
            </div>
        </div>
    )
}

export default SearchButton
