import { Interval } from '../components/timeline'

import {
    WeekDay,
    WorkingSchedule,
} from '@safefleet/react-common'

import { nextDay, set,Day } from 'date-fns'

const msInDay = 86400000
const msInHour = 3600000
const msInMin = 60000


const sInDay = 86400
const sInHour = 3600
const sInMin = 60

export type DHMS = {
    days: number,
    hours: number,
    minutes: number,
    seconds: number,
}
export const getDHMSFromMs = (ms: number): DHMS => {
    const [days,daysRem] = [Math.floor(ms/msInDay),ms % msInDay]
    const [hours,hoursRem] = [Math.floor(daysRem/msInHour),daysRem % msInHour]
    const [minutes,minutesRem] = [Math.floor(hoursRem/msInMin),hoursRem % msInMin]
    const seconds = Math.floor(minutesRem/1000)
    return { days, hours, minutes, seconds }
}

export const getDHMSFromS = (s: number): DHMS => {
    const [days,daysRem] = [Math.floor(s/sInDay),s % sInDay]
    const [hours,hoursRem] = [Math.floor(daysRem/sInHour),daysRem % sInHour]
    const [minutes,minutesRem] = [Math.floor(hoursRem/sInMin),hoursRem % sInMin]
    const seconds = Math.floor(minutesRem/1000)
    return { days, hours, minutes, seconds }
}

function stringFromDHMSArray( xs: [number,string][],showSpace: boolean,showZero?: boolean): string {
    if(!xs.length){
        return ""
    }
    const [val,label] = xs[0]
    const show = !!showZero || !!val
    const tail = stringFromDHMSArray( xs.slice(1),showSpace,show)

    return ((tail && showZero) || val ?  val + label + (showSpace ? ' ' :'') : '') + tail
}

export const arrayFromDHMS = ({ days,hours,minutes,seconds }: DHMS): [number,string][] => [
    [days,'d'],[hours,'h'],[minutes,'m'],[seconds,'s'],
]

export const stringFromDHMS = ( dhms: DHMS, showSpace?: boolean ): string => {
    const ret = stringFromDHMSArray( arrayFromDHMS( dhms ), !!showSpace )
    return ret === "" ? "0s" : ret
}


export type WorkingInterval = {
    day         : Day,
    from_hour   : number,
    from_minute : number,
    to_hour     : number,
    to_minute   : number,
}

export const toWorkingIntervals = (ws: WorkingSchedule): WorkingInterval[] =>{
    const days: WeekDay[] = ['sun','mon','tue','wed', 'thu', 'fri', 'sat']
    return days.reduce((acc: WorkingInterval[],day)=>{
        const current = ws[day]
        if(current ){
            const startMinutes = toMinutes(current.from_hour,current.from_minute)
            const endMinutes = toMinutes(current.to_hour,current.to_minute)
            if( startMinutes !== endMinutes){
                return acc.concat({
                    ...current,
                    day: toDay(day),
                })
            }
        }
        return acc
    },[])
}

export const toWeekDayStr = (day: Day): string => {
    switch(day){
        case 0:
            return 'Su'
        case 1:
            return 'Mo'
        case 2:
            return 'Tu'
        case 3:
            return 'We'
        case 4:
            return 'Th'
        case 5:
            return 'Fr'
        case 6:
            return 'Sa'
    }
}

export const toDay = (day: WeekDay): Day => {
    switch(day){
        case 'sun':
            return 0
        case 'mon':
            return 1
        case 'tue':
            return 2
        case 'wed':
            return 3
        case 'thu':
            return 4
        case 'fri':
            return 5
        case 'sat':
            return 6
    }
}

const toMinutes = (hours: number,minutes: number) => hours*60 + minutes
const dateToMinutes = (date: Date) => toMinutes(date.getHours(),date.getMinutes())
const wsToMinutes = (x: WorkingInterval): [number,number] =>
    [toMinutes(x.from_hour,x.from_minute),toMinutes(x.to_hour,x.to_minute)]

export const getNextInterval = (date: Date,interval: WorkingInterval): Interval<Date> => {
    const setTime = (date: Date,hours: number,minutes: number) =>
        set(date, { hours,minutes,seconds: 0,milliseconds: 0 })

    const end = (x: Date) => setTime(x,interval.to_hour,interval.to_minute)
    const start = (x: Date) => setTime(x,interval.from_hour,interval.from_minute)
    if(interval.day === date.getDay()){
        const dminutes = dateToMinutes(date)
        const [istart,iend] = wsToMinutes(interval)
        if(dminutes >= istart){
            //if intersects with interval
            if(dminutes < iend){
                return { start: date,end: end(date) }
            }
        }else{
            return { start: start(date),end: end(date) }
        }
    }

    const next = nextDay(date,interval.day)
    return { start: start(next),end: end(next) }
}

export const getNextIntervalBySchedule = (
    date: Date,
    intervals: WorkingInterval[]
): Interval<Date> | null => {
    if(!intervals.length){
        return null
    }
    const day = date.getDay()
    const minutes = dateToMinutes(date)
    const nexInterval = intervals.find( (x) => {
        const end = wsToMinutes(x)[1]
        return x.day > day || ( x.day === day && minutes < end)
    })

    // there is no working interval this week
    return getNextInterval(date,nexInterval || intervals[0])
}

export type InOutWorkingHoursInterval = {
    duringWorkingHours: Interval<Date>[],
    ousideWorkingHours: Interval<Date>[],
}

export const splitIntervalByWorkingSchedule = (
    schedule: WorkingInterval[],
    acc: InOutWorkingHoursInterval,
    interval: Interval<Date>
): InOutWorkingHoursInterval => {
    const next = getNextIntervalBySchedule(
        interval.start,
        schedule
    )


    const allOusideWorkingHours: InOutWorkingHoursInterval = {
        ...acc,
        ousideWorkingHours: acc.ousideWorkingHours.concat(interval),
    }

    const { start: a,end: b } = interval

    // 1. if the schedule is empty all the intervals are
    // ouside working hours
    //
    //    interval:      A[             ]B
    //    next:                              NULL
    if(!next){
        return allOusideWorkingHours
    }
    const { start: wa,end: wb } = next


    // 2. if next working interval is after the current one's ending
    //
    //    interval:      A[             ]B
    //    next:                             WA[             ]WB
    if(b <= wa){
        return allOusideWorkingHours

    }
    if(a < wa) {

        // 3. if next working interval begins during the current one
        // and is ending after the current one's ending
        //    interval:      A[             ]B
        //    next:              WA[             ]WB
        if( wb >= b){
            return {
                ousideWorkingHours: acc.ousideWorkingHours.concat({
                    start : a,
                    end   : wa,
                }),
                duringWorkingHours: acc.duringWorkingHours.concat({
                    start : wa,
                    end   : b,
                }),
            }
        }else{
            // 4. if next working interval begins and ends during the
            // current one's ending.
            // NOTE: other might overlapp
            //    interval:      A[                     ]B
            //    next:              WA[    ]WB  ...........
            const newAcc = {
                ousideWorkingHours: acc.ousideWorkingHours.concat({
                    start : a,
                    end   : wa,
                }),
                duringWorkingHours: acc.duringWorkingHours.concat(next),
            }

            //searching intervals for (wb,b)
            return splitIntervalByWorkingSchedule(
                schedule,
                newAcc,{
                    start : wb,
                    end   : b,
                }

            )
        }
    }

    // nexInterval implementation doesn't allow for WA to be less then A
    // so from now on we know that A == WA

    // 5. if next working interval begins in the same time with
    // current one but is ending after it
    //
    //    interval:      A [           ]B
    //    next:          WA[                  ]WB
    if(wb >= b){
        return {
            ...acc,
            duringWorkingHours: acc.duringWorkingHours.concat(interval),
        }
    }

    // 6. the only case remains when wb < b
    //
    //    interval:      A [             ]B
    //    next:          WA[      ]WB ......
    const newAcc = {
        ...acc,
        duringWorkingHours: acc.duringWorkingHours.concat({
            start : a,
            end   : wb,
        }),
    }

    //searching intervals for (wb,b)
    return splitIntervalByWorkingSchedule(
        schedule,
        newAcc,{
            start : wb,
            end   : b,
        }
    )
}

export const splitIntervalsByWorkingSchedule = (
    intervalLines: Interval<Date>[],
    schedule: WorkingInterval[]
): InOutWorkingHoursInterval => {
    const init: InOutWorkingHoursInterval = {
        duringWorkingHours : [],
        ousideWorkingHours : [],
    }

    return intervalLines.reduce(
        (acc,x)=>splitIntervalByWorkingSchedule(schedule,acc,x)
        ,init
    )
}

