import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { Routes, Route, useLocation } from "react-router-dom"
import Toolbar from '@mui/material/Toolbar'
import CircularProgress from '@mui/material/CircularProgress'
import { useDispatch, useSelector } from 'react-redux'
import * as RX from '../../redux'
import { MainRoute } from '../../base'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useTheme } from '@mui/material/styles'

export const drawerWidth = 400

type DrawerContentProps = {
    isGetting: boolean
    routes: MainRoute[],
}
type VehicleDrawerProps = {
    mobileOpen: boolean,
    handleDrawerToggle: () => any,
} & DrawerContentProps

type DrawerHeaderProps = {
    routes: MainRoute[],
}
const DrawerHeader: React.FC<DrawerHeaderProps> = ({ routes }) => {
    const theme = useTheme()
    return (
        <div
            id='drawer-header-container'
            style={{
            //height        : headerHeight,
                background    : theme.custom.header.background,
                alignItems    : 'center',
                display       : 'flex',
                flexDirection : 'column',
            }} >
            {
                <Routes>
                    {routes.map((x,ix) =>
                        <Route key={ix} path={x.path} element={
                            <>
                                {x.leftHeader()}
                                {
                                    !x.hideDivider?
                                        <div style={{ height: '5px',width: '100%',backgroundColor: '#EFEFEF' }} />
                                        :null
                                }
                            </>
                        } />)
                    }
                </Routes>
            }
        </div>
    )
}
const DrawerContent: React.FC<DrawerContentProps> = ({
    isGetting,
    routes,
}) => {
    const location = useLocation()
    const isHome = location.pathname === '/'
    const isHomeRef = React.useRef(isHome)
    const drawerContentRef: any= React.useRef()

    const handleScroll = React.useCallback((event) => {
        const { scrollTop } = event.target

        if(isHome) {
            localStorage.setItem("homeDrawerScroll", scrollTop.toString())
        }
    }, [isHome])

    React.useEffect(() => {
        if(!isHome && isHomeRef.current){
            isHomeRef.current = false
            drawerContentRef.current.scroll(0, 0)
        } else if(isHome && !isHomeRef.current){
            isHomeRef.current = true

            const verticalScroll = localStorage.getItem("homeDrawerScroll")
            drawerContentRef.current.scroll(0, parseInt(verticalScroll || '0'))
        }
    }, [isHome])

    return (
        <div style={{ height: "100%",display: 'flex',flexDirection: 'column', paddingTop: 24 }}>
            <Toolbar sx={{ display: 'flex',justifyItems: "center",justifyContent: 'center' }}>
                { isGetting &&
                <Box sx={{
                    display: 'flex',
                }}>
                    <CircularProgress />
                </Box>
                }
            </Toolbar>
            <DrawerHeader routes={routes} />
            <div onScroll={handleScroll} style={{ flex: 1, overflow: 'auto' }} ref={drawerContentRef} >
                {
                    <Routes>
                        {routes.map((x,ix) =>
                            <Route key={ix} path={x.path} element={ x.left() } />)
                        }
                    </Routes>
                }
            </div>
        </div>
    )
}
const VehicleDrawer: React.FC<VehicleDrawerProps> = ({
    isGetting,routes,
}) => {
    const dispatch = useDispatch()
    const mapRef = useSelector(RX.context.selectors.getMapRef)
    const drawerStatus = useSelector(RX.context.selectors.getDrawerStatus)

    const handleDrawerToggle = React.useCallback(() => {
        if(drawerStatus === 'open') {
            dispatch(RX.context.actions.setDrawerStatus('close'))
        } else {
            dispatch(RX.context.actions.setDrawerStatus('open'))
        }
    }, [dispatch, drawerStatus])

    const isDrawerToggleOpen = drawerStatus === 'open'

    const drawer = <DrawerContent {...{
        isGetting,
        mapRef,
        routes,
    }}
    />

    return (
        <>
            <Drawer
                variant="persistent"
                sx={{
                    "display"            : { sm: 'block' },
                    '& .MuiDrawer-paper' : { boxSizing: 'border-box', width: drawerWidth },
                }}
                open={isDrawerToggleOpen}
                transitionDuration={70}
            >
                {drawer}
            </Drawer>
            <Box
                onClick={handleDrawerToggle}
                sx={{
                    position                : 'absolute',
                    top                     : '45%',
                    left                    : isDrawerToggleOpen ? drawerWidth : 0,
                    backgroundColor         : 'white',
                    width                   : 30,
                    height                  : 60,
                    zIndex                  : 100,
                    borderTopRightRadius    : 10,
                    borderBottomRightRadius : 10,
                    alignItems              : 'center',
                    justifyContent          : 'center',
                    display                 : 'flex',
                    boxShadow               : "rgba(100, 100, 111, 0.3) 5px 5px 10px 0px",
                }}
            >
                {
                    isDrawerToggleOpen ? (
                        <ArrowLeftIcon
                            sx={{ fontSize: 35 }}
                        />
                    ) : (
                        <ArrowRightIcon
                            sx={{ fontSize: 35 }}
                        />
                    )
                }
            </Box>
        </>
    )
}

export default VehicleDrawer
