import { createSelector } from 'reselect'
import { ContextState } from './reducer'

export const getState = (state: { context: ContextState }) => state.context
export const getUserId = (state: { context: ContextState }) => state.context.userId
export const getVehicleId = (state: { context: ContextState }) => state.context.vehicleId
export const getFleetId = (state: { context: ContextState }) => state.context.fleetId
export const getCompanyId = (state: { context: ContextState }) => state.context.companyId
export const getMapRef = (state: { context: ContextState }) => state.context.mapRef
export const getMapType = (state: { context: ContextState }) => state.context.mapType
export const getDrawerStatus = (state: { context: ContextState }) => state.context.drawerStatus
export const getAddressSearchDrawerStatus = (state: { context: ContextState }) =>
    state.context.addressSearchDrawerStatus
export const getOnlyActiveVehiclesFilter = (state: { context: ContextState }) => state.context.onlyActiveVehicles
export const getFiltersPanelStatus = (state: { context: ContextState }) => state.context.filtersPanel
export const getJourneysSliderValues = (state: { context: ContextState }) => state.context.journeySliderValue
export const getJourneysFiltersDates = (state: { context: ContextState }) => state.context.journeysFilterDate
export const getShowJourneysPois = (state: { context: ContextState }) => state.context.showJourneysPois
export const getSelectedAddressForRouteEstimation = (state: { context: ContextState }) =>
    state.context.selectedAddressForRouteEstimation

export const getReportInterval = createSelector(
    getState,
    (state) => state.reportInterval
)
export const getSelectedJourneyIds = createSelector(
    getState,
    (state) => state.journeyIds
)

export const getTrajectoryInterval = createSelector(
    getState,
    (state) => state.trajectoryInterval
)

export const shouldPollingRun = createSelector(
    getState,
    (state) => state.shouldPollingRun
)
export const getSearchValue = createSelector(
    getState,
    (_:any,props: {screen: string}) => props.screen,
    (state,screen) => state.search[screen] ? state.search[screen].value : ''
)
