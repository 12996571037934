import { fork, put, select, takeLatest, takeEvery } from 'redux-saga/effects'
import { auth, ActionCreator, UserAuthCredentials } from '@safefleet/react-common'
import { isEmpty } from 'lodash'
//import CookieManager from '@react-native-community/cookies'
//import * as NavigationHelper from '../../routing/helper'
import * as actions from './actions'
import { app,/*ui */api } from '../index'
import { selectors } from './index'


export function* saga() {
    yield fork(auth.saga)

    yield takeLatest(actions.LOGIN_FAILED, displayNotification)
    yield takeLatest(actions.LOGOUT, clearNativeCookies)
    //TODO: when app redux initialize use it here if it is the case
    yield takeLatest([actions.LOGIN_SUCCEEDED, app.actions.INITIALIZED], handleLoginSucceed)
    yield takeLatest(actions.SESSION_EXPIRED, reauthenticate)
    yield takeEvery(actions.TWO_FA_REQUIRED, handle2FA)
    yield takeEvery(actions.TWO_FA_FAILED, handle2FAFailed)
    //TODO solve this ap as any hack
    yield takeEvery((api as any).usersPassword.UPDATE_SUCCEEDED, handlePwdChange)
    yield takeEvery((api as any).usersPassword.UPDATE_FAILED, handlePwdChangeFailed)
}

function* handleLoginSucceed() {
    yield put(actions.changeReauthState({ reauth: false }))
}

function handle2FA({ payload }:any) {
    //TODO: navigate to route
    //NavigationHelper.navigate({
        //name   : 'TwoFACode',
        //params : { authData: { hint, username, password, saveCredentials } },
    //})
}

function* malert(str: any): any {
    yield alert(str)
}
function* handle2FAFailed() {
    yield malert("FAILED")
    /*yield put(ui.showToast({*/
        /*id      : 'AUTH_FAILED',*/
        /*message : i18n.t('Invalid code, please try again.'),*/
        /*intent  : ui.INTENT.error,*/
    /*}))*/
}

function* displayNotification({ payload }: ReturnType<ActionCreator<{ error: { message: string } }>>) {
    const { error } = payload!
    yield malert(error.message)
    /*yield put(ui.showToast({*/
        /*id      : 'AUTH_FAILED',*/
        /*message : get(error, '_error', i18n.t('Failed to log in.')),*/
        /*intent  : ui.INTENT.error,*/
    /*}))*/
}

function clearNativeCookies() {
    //window.localStorage.removeItem("sessionid")
}

function* reauthenticate() {
    const credentials: UserAuthCredentials = yield select(selectors.getCredentials)
    if (!isEmpty(credentials)) {
        yield put(actions.changeReauthState({ reauth: true }))
        yield put(actions.authenticateJWT(credentials))
    } else {
        yield put(actions.logout())
    }
}

function* handlePwdChange() {
    yield malert('Password changed')
    /*yield put(ui.showToast({*/
        /*id      : 'AUTH_FAILED',*/
        /*message : i18n.t('Password changed, please login again.'),*/
        /*intent  : ui.INTENT.success,*/
    /*}))*/

    const credentials: UserAuthCredentials = yield select(selectors.getCredentials)
    //TODO any hack for payload, solve this
    const payload : any = { credentials: { ...credentials, password: '' } }
    yield put(actions.updateCredentials(payload))
}

function* handlePwdChangeFailed() {
    yield malert('Failed to change password')
    /*yield put(ui.showToast({*/
        /*id      : 'PASSWORD_CHANGE_FAILED',*/
        /*message : i18n.t('Failed to change password.'),*/
        /*intent  : ui.INTENT.error,*/
    /*}))*/
}
