import { useDispatch, useSelector } from "react-redux"
import * as RX from "../redux"
import "../stylesheets/css/VehicleMap.css"
import IconCmp from './ui/Icon'
import pinIcon from '../assets/img/end-pin.svg'
import circleIcon from '../assets/img/start-pin.svg'
import journeyStopPin from '../assets/img/journey-stop-icon.svg'
import eventIcon from '../assets/img/event-icon.svg'
import contactIcon from '../assets/img/contact-icon.svg'
import poiIcon from '../assets/img/poi-icon.svg'
import { useLocation } from "react-router-dom"
import { useTheme } from "@mui/material"
import { SectionSmallBold } from './Text'
import React from "react"
import { JourneyModel } from "@safefleet/react-common"
import { setJourneysFiltersDates } from "../redux/context/actions"
import { usePositions } from "../hooks/vehicle"
import { spaces } from '../theme'

const K_WIDTH = 40
const K_HEIGHT = 40

const PointMarker = ({ icon, lat, lng, start, style = {} }) => {
    let top = -K_WIDTH

    if(start) {
        top = -K_WIDTH / 2
    }

    return (
        <div
            style={{
                position : 'absolute',
                width    : K_WIDTH,
                height   : K_HEIGHT,
                left     : -K_WIDTH / 2,
                top      : top,
                ...style,
            }}
        >
            <IconCmp
                source={icon}
                size={40}
                alt={''}
            />
        </div>
    )
}

const Divv = ({ lat, lng, children }) => {
    return (
        <div
            style={{
                position   : 'relative',
                alignItems : 'center',
            }}
        >
            {children}
        </div>
    )
}

const JourneysMap = () => {
    const { pathname } = useLocation()
    const theme = useTheme()
    const dispatch = useDispatch()
    const vehicleId = parseInt(pathname.split('/')[1])
    const vehicle = useSelector( RX.vehicles.selectors.getForID( vehicleId || undefined ) )
    const journeys = useSelector((state: any) =>
        RX.journeys.selectors.getForVehicleId(true)(state, { vehicleId })) || []
    const firstJourney = journeys[0]
    const lastJourney = journeys[journeys?.length - 1]
    const filterDates = useSelector(RX.context.selectors.getJourneysFiltersDates) || new Date()
    const journeyId = pathname.split('/')[3]
    const journeysFocus = pathname.split('/')?.[2] === 'journeys' || false
    const selectedEvents = useSelector(RX.events.selectors.getSelectedEvents)
    const selectedContacts = useSelector(RX.contacts.selectors.getSelectedContacts)
    const minDate = new Date(filterDates[0])
    const maxDate = new Date(filterDates[1])
    const events = useSelector(RX.events.selectors.getForID(vehicleId))
    const contacts = useSelector(RX.contacts.selectors.getForID(vehicleId))
    const positions = usePositions(vehicle, minDate, maxDate)
    const showJourneysPoiValue = useSelector(RX.context.selectors.getShowJourneysPois)

    const filteredEvents = events ?
        events.filter((event) => {
            const eventDate: Date = new Date(event.moment)
            return selectedEvents?.[event.type] === true &&
            eventDate > minDate &&
            eventDate < maxDate
        })
        : []

    const filteredContacts = contacts ?
        contacts.filter((contact) => {
            const contactDate: Date = new Date(contact.moment)
            return selectedContacts?.[contact?.input_id] &&
            contactDate > minDate &&
            contactDate < maxDate
        })
        : []

    React.useEffect(() => {
        if(firstJourney && lastJourney) {
            const fromDate: any = new Date(lastJourney?.start?.moment)
            const toDate: any = new Date(firstJourney?.stop?.moment)
            const diffTime = Math.abs(toDate - fromDate)
            const maxSeconds = diffTime/1000

            dispatch(RX.context.actions.setJourneysSliderValue([0, maxSeconds, fromDate]))
            const startDate = new Date(fromDate)
            const minDate = new Date(fromDate).setSeconds(startDate.getSeconds() + 0)
            const maxDate = new Date(fromDate).setSeconds(startDate.getSeconds() + maxSeconds)

            dispatch(setJourneysFiltersDates([
                minDate,
                maxDate,
            ]))
        }
    }, [dispatch, firstJourney, lastJourney])

    if(!firstJourney || !lastJourney) return []

    let filteredJourneys: Array<JourneyModel>

    if(filterDates !== null) {
        const minDate = new Date(filterDates[0])
        const maxDate = new Date(filterDates[1])
        filteredJourneys = journeys.filter((journey) =>
            new Date(journey.start.moment) >= minDate &&
            new Date(journey.stop.moment) <= maxDate )
    } else {
        filteredJourneys = journeys
    }

    const journeysStop = filteredJourneys.map((item, index) => {
        if(index === 0) {
            return (
                <PointMarker
                    key={`point-marker-${firstJourney?.start?.lat}`}
                    lat={firstJourney?.start?.lat}
                    lng={firstJourney?.start?.lng}
                    icon={circleIcon}
                    start={false}
                />
            )
        } else if(journeys.length === (index + 1)) {
            return (
                <PointMarker
                    key={`point-marker-stop-${lastJourney?.stop?.lat}`}
                    lat={lastJourney?.stop?.lat}
                    lng={lastJourney?.stop?.lng}
                    icon={pinIcon}
                    start={false}
                />
            )
        } else {
            return (
                <Divv
                    lat={item?.stop?.lat}
                    lng={item?.stop?.lng}>
                    <PointMarker
                        key={`point-marker-pause-${item?.stop?.lat}`}
                        lat={item?.stop?.lat}
                        lng={item?.stop?.lng}
                        icon={journeyStopPin}
                        start={false}
                    />
                    <div
                        style={{
                            position       : 'absolute',
                            alignSelf      : 'center',
                            alignItems     : 'center',
                            justifyContent : 'center',
                            width          : '100%',
                            zIndex         : 99999,
                            top            : -31,
                            right          : spaces.textLine[2],
                        }}
                    >
                        <SectionSmallBold
                            sx={{
                                color: theme.palette.primary.main,
                            }}
                        >
                            {index}
                        </SectionSmallBold>
                    </div>
                </Divv>
            )
        }
    })


    const eventsMarkers = filteredEvents.map((event) => {
        return (
            <PointMarker
                key={`point-marker-event-${event?.lat + Math.random()}`}
                lat={event?.lat}
                lng={event?.lng}
                icon={eventIcon}
                start={false}
                style={{
                    zIndex: 9999,
                }}
            />
        )
    })

    const contactsMarkers = filteredContacts.map((contact) => {
        return (
            <PointMarker
                key={`point-marker-contact-${contact?.lat + Math.random}`}
                lat={contact?.lat}
                lng={contact?.lng}
                icon={contactIcon}
                start={false}
                style={{
                    zIndex: 9999,
                }}
            />
        )
    })

    const pois = showJourneysPoiValue ? positions.map((poi) => {
        return (
            <PointMarker
                key={`point-marker-poi-${poi?.lat + Math.random}`}
                lat={poi?.lat}
                lng={poi?.lng}
                icon={poiIcon}
                start={false}
                style={{
                    zIndex: 9999,
                }}
            />
        )
    }) : []

    if(journeysFocus && journeyId && journeyId !== 'filter') return []

    if(!journeysFocus) return []

    return [
        ...journeysStop,
        ...eventsMarkers,
        ...contactsMarkers,
        ...pois,

    ]
}
export default JourneysMap
