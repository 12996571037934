/* eslint-disable import/extensions */
import ro from './ro.json'
import it from './it.json'
import pl from './pl.json'
import de from './de.json'
import hu from './hu.json'


export const i18nLocales = {
    ro : { translation: ro },
    it : { translation: it },
    pl : { translation: pl },
    de : { translation: de },
    hu : { translation: hu },
}