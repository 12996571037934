import { tags } from '@safefleet/react-common'

const AUTO_REFRESH         = 'autorefresh'
const REFRESH              = 'refresh'
const UPDATE_PROFILE_PHOTO = 'update_profile_photo'

export const {
    IS_CREATING,
    IS_UPDATING,
    IS_GETTING,
    IS_DESTROYING,
    IS_UPLOADING,
    IS_DOWNLOADING,
    JOURNEY_WITH_ADDRESSES,
} = tags

export {
    REFRESH,
    AUTO_REFRESH,
    UPDATE_PROFILE_PHOTO,
}

export const DARK_MODE = 'isDarkMode'
export const PIN_LABEL = 'isVehicleNameOnPin'
