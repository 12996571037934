import { createSelector, Selector } from 'reselect'

import { app, AppState } from '@safefleet/react-common'
import { AppStateStatus } from '../../base'

const { getState, isInitialized, getCountry, getLanguage } = app

const getCurrentState: Selector<AppState, AppStateStatus> = createSelector(
    getState,
    (app) => app.state
)

const isActive: Selector<AppState, boolean> = createSelector(
    getCurrentState,
    (currentState) => currentState === 'active'
)

export { isInitialized, getCountry, getLanguage, getCurrentState, isActive }
