import { MainRoute } from '../base'
import VehicleList from '../components/vehicle-drawer/VehicleList'
import VehicleDetails from '../components/vehicle-drawer/VehicleDetails'
import VehicleListHeader from '../components/vehicle-drawer/VehicleListHeader'
import { VehicleHeader,JourneyHeader } from '../components/vehicle-drawer/VehicleHeader'
import VehicleSelection from '../components/for-map/VehicleSelection'
import Journeys from '../components/vehicle-drawer/Journeys'
import VehicleActivity from '../components/vehicle-drawer/VehicleActivity'
import FilteredJourneys from '../components/vehicle-drawer/FilteredJourneys'
import JourneysPolyline from '../components/for-map/JourneysPolyline'

const routes: MainRoute[] = [
    {
        path       : "/",
        left       : () => <VehicleList />,
        forMap     : () => null,
        leftHeader : () => <VehicleListHeader />,
    },{
        path        : "/:vehicleId",
        left        : () => <VehicleDetails />,
        forMap      : () => <VehicleSelection />,
        leftHeader  : () => <VehicleHeader />,
        hideDivider : true,
    },{
        path        : "/:vehicleId/activity",
        left        : () => <VehicleDetails />,
        forMap      : () => <VehicleSelection />,
        leftHeader  : () => <VehicleHeader />,
        hideDivider : true,
    },{
        path        : "/:vehicleId/journeys/",
        left        : () => <VehicleDetails />,
        forMap      : () => <JourneysPolyline />,
        leftHeader  : () => <VehicleHeader />,
        hideDivider : true,
    },{
        path        : "/:vehicleId/journeys/:journeyId",
        left        : () => <Journeys />,
        forMap      : () => <VehicleSelection />,
        leftHeader  : () => <JourneyHeader />,
        hideDivider : true,
    },{
        path        : "/:vehicleId/journeys/:journeyId/filter",
        left        : () => <FilteredJourneys />,
        forMap      : () => <VehicleSelection />,
        leftHeader  : () => <JourneyHeader />,
        hideDivider : true,
    },{
        path        : "/:vehicleId/journeys/filter",
        left        : () => <FilteredJourneys />,
        forMap      : () => <JourneysPolyline />,
        leftHeader  : () => <VehicleHeader />,
        hideDivider : true,
    },
    {
        path        : "/:vehicleId/activity",
        left        : () => <VehicleActivity />,
        forMap      : () => <VehicleSelection />,
        leftHeader  : () => <VehicleHeader />,
        hideDivider : true,
    },
]

export default routes
