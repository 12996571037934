import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Routes, Route, useLocation } from "react-router-dom"
import { app } from '../redux'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline'
import { VehicleModel, vehicles } from '@safefleet/react-common'
import { get, toLower, sortBy } from 'lodash'
import { tags } from '../redux'
import SpaAppBar from '../components/SpaAppBar'
import MapTopBar from '../components/MapTopBar'
import VehicleMap from '../components/VehicleMap'
import MapRightBottomButtons from '../components/MapRightBottomButtons'
import VehicleDrawer from '../components/vehicle-drawer'
import { MainRoute } from '../base'
import Filters from '../components/vehicle-drawer/Filters'
import * as redux from '../redux'
import JourneysSlider from '../components/JourneysSlider'
import JourneysOptions from '../components/JourneysOptions'
import CurrentPositionsOptions from '../components/CurrentPositionsOptions'
import AddressSearch from '../components/address-search/index'
import SearchButton from '../components/SearchButton'

const useActivateApp = () => {
    const dispatch = useDispatch()
    return React.useCallback(() => {
        dispatch(app.actions.initialize())
    }, [dispatch])
}

type HomeProps = {
    routes: MainRoute[],
}


const Home: React.FC<HomeProps> = ({ routes }) => {
    const { userVehicles,isGetting } = useMapStateToProps()
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const activeApp = useActivateApp()
    const panelStatus = useSelector(redux.context.selectors.getFiltersPanelStatus)
    const pathName = useLocation().pathname
    const isFocusedOnJourneys = pathName.split('/')?.[2] === 'journeys' || false
    const journeyId = pathName.split('/')?.[3]
    const isFocusedOnCurrentPositions = pathName === "/"

    React.useEffect(()=>{
        activeApp()
    },[activeApp])

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <SpaAppBar />
            <VehicleDrawer
                {...{
                    isGetting,
                    userVehicles,
                    mobileOpen,
                    handleDrawerToggle,
                    routes,
                }}
            />
            {
                isFocusedOnCurrentPositions && <AddressSearch />
            }

            <div
                style={{
            //TODO: improve styling
                    display       : "flex",
                    alignItems    : "stretch",
                    flexDirection : "column",
                    height        : "100vh",
                    width         : "100%",
                }}
            >
                <div
            //TODO: improve styling
                    style={{
                        flex       : 1,
                        alignItems : "flex-start",
                        width      : "100%",
                    }}
                >
                    <VehicleMap vehicles={isFocusedOnJourneys ? [] : userVehicles} />
                    <MapRightBottomButtons />
                    {
                        <Routes>
                            {routes.map((x, ix) => (
                                <Route key={ix} path={x.path} element={x.forMap()} />
                            ))}
                        </Routes>
                    }
                </div>
            </div>
            {isFocusedOnJourneys && (journeyId === "filter" || !journeyId) && (
                <>
                    <JourneysOptions />
                    <JourneysSlider />
                </>
            )}
            {isFocusedOnCurrentPositions && <SearchButton/>}
            {isFocusedOnCurrentPositions && <CurrentPositionsOptions />}
            <MapTopBar />
            {panelStatus && <Filters />}
        </Box>
    )
}

type VehiclesListScreenProps = {
    userVehicles : VehicleModel[],
    isGetting    : boolean,
}


const useMapStateToProps = (): VehiclesListScreenProps => {
    const userVehicles  = useSelector((state: any) => sortBy(
        vehicles.selectors.getAll(state),
        (vehicle) => toLower(get(vehicle, 'name'))
    ))
    const isGetting = useSelector((state : any) => vehicles.selectors.isPendingForTag(tags.IS_GETTING)(state))
    return {
        userVehicles,
        isGetting,
    }
}

export default Home
