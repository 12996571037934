const rssFeedAvailableSubDomains = [
    'alpha',
    'delta',
    'gamma',
    'citone',
    'electrica',
    'telekom',
    'veolia',
    'staging',
    'poland',
    'poland2',
    'italy',
]

export default rssFeedAvailableSubDomains