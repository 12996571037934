import * as React from 'react'
import * as RC from '@safefleet/react-common'
import { Interval,useNumberInterval } from '../timeline'
import { useFilteredJourneysByInterval } from '../../hooks/journeys'
import * as Utils from '../../utils'
import IdleTimeLine from './IdleTimeLine'
import IntervalLinesSummary, { useIdleIntervalLines } from './IntervalLinesSummary'

type WeekTimeLineProps = {
    journeys: RC.JourneyModel[],
    interval: Interval<Date>,
    showLegend?: boolean,
    summaryText: string,
}

const defaultDistance = 0

const WeekTimeLine: React.FC<WeekTimeLineProps> = ({
    journeys,
    interval,
    showLegend,
    summaryText,
}) => {
    const weekJourneys = useFilteredJourneysByInterval(journeys,interval)
    const lines = useIdleIntervalLines(weekJourneys)
    const numberInterval = useNumberInterval(interval)
    const weekDistance = weekJourneys ? weekJourneys.reduce((acc, journey)=>{
        return  acc + journey.distance
    }, defaultDistance) : defaultDistance
    const start = numberInterval.start - (3600000*18)
    const end = numberInterval.end
    return (
        <>
            <IntervalLinesSummary
                lines={lines}
                text={summaryText}
                showReverseForInterval={numberInterval}
                distance={weekDistance}
            />
            <IdleTimeLine
                lines={lines}
                domainStarts={start}
                domainEnds={end}
                checkpoint={86400000}
                headerLabelText = { (x: number) => Utils.toWeekDayStr((new Date(x)).getDay() as any) }
                showLegend={showLegend}
            />
        </>
    )
}
export default WeekTimeLine
