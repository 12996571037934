import React, { useState } from 'react'
import * as RC from '@safefleet/react-common'
import * as HC from '../../hooks/vehicle'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { currentLocale } from '../../settings'
import { useNavigate } from "react-router-dom"
import { useTheme } from '@mui/material/styles'
import IconCmp from '../ui/Icon'
import SpeedIcon from '../../assets/img/speed.svg'
import BatteryIcon from '../../assets/img/battery.svg'
import GasIcon from '../../assets/img/GasIcon.svg'
import OfflineIcon from '../../assets/img/OfflineIcon.svg'
import { SectionTitle, SectionText, SectionSmallGray, SectionSmallBold, SectionSmall } from '../Text'
import { spaces } from '../../theme'
import { i18n } from '@safefleet/react-common'

type VehicleListItemProps = {
    vehicle: RC.VehicleModel,
    onPress: () => any,
    index: number,
}


const Row: React.FC = ({ children }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {children}
        </Box>
    )
}

const VehicleListItem: React.FC<VehicleListItemProps> = ({ vehicle }) => {
    const makerModel = `${vehicle.maker} ${vehicle.model}`.replace('unknown', '')
    const {
        license_plate: plate,
        default_driver: driver,
    } = vehicle

    const theme = useTheme()
    const { on,off } = theme.custom.text
    const gray = theme.palette.secondary.main
    const [hover, setHover] = useState(false)

    const address = HC.useVehicleAddress(vehicle)
    const { speed } = HC.useVehicleExtraValues(vehicle)
    const status = HC.getStatus(vehicle)
    const locale = currentLocale()
    const momentVal = HC.getMomentValue(vehicle,locale,'PPP - pp')
    const batteryLevel = vehicle.current_info?.battery_soc
    const offline = vehicle.current_info === null

    const navigate = useNavigate()

    const onListItemPress = React.useCallback(() => {
        navigate(`${vehicle.vehicle_id}`)
    }, [navigate,vehicle.vehicle_id])

    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                backgroundColor: hover ? '#EDEDED' : undefined,
            }}
        >
            <ListItem button onClick={onListItemPress} alignItems="flex-start" sx={{
                display       : 'flex',
                flexDirection : 'column',
                alignItems    : 'stretch',
            }}>
                <Box sx={{
                    display        : 'flex',
                    minHeight      : '50px',
                    alignItems     : 'center',
                    flexDirection  : 'row',
                    justifyContent : 'space-between',
                }}>
                    <div style={{
                        display       : 'flex',
                        flexDirection : 'column',
                        marginBottom  : 10,
                    }}>
                        <SectionTitle>
                            {plate}
                        </SectionTitle>
                        <SectionText
                            sx={{
                                color     : gray,
                                marginTop : "3px",
                            }}
                        >
                            [{driver ? driver : 'n/a'}]
                        </SectionText>
                    </div>
                    <div
                        style={{
                            justifyContent : 'flex-start',
                            alignSelf      : 'flex-start',
                        }}
                    >
                        <SectionTitle
                            sx={{
                                color: gray,
                            }}
                        >
                            {makerModel || '-'}
                        </SectionTitle>
                    </div>
                </Box>
                <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', marginTop: "10px" }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <SectionSmallBold
                            sx={{
                                color: gray,
                            }}
                        >
                            {momentVal}
                        </SectionSmallBold>
                        <SectionSmallGray>
                            {address || ''}
                        </SectionSmallGray>
                    </Box>
                    <Box sx={{
                        display        : 'flex',
                        flexDirection  : 'column',
                        alignItems     : 'flex-end',
                        justifyContent : 'center',
                    }}>
                        {
                            !offline && (
                                <Row>
                                    <SectionSmall
                                        sx={{
                                            width       : '70px',
                                            textAlign   : 'right',
                                            marginRight : "5px",
                                            color       : status === 1 ? on:off,
                                        }}
                                    >
                                        {speed}
                                    </SectionSmall>
                                    <IconCmp
                                        source={SpeedIcon}
                                        size={13}
                                        alt={''}
                                    />
                                </Row>
                            )
                        }
                        {
                            offline ? (
                                <Row>
                                    <SectionSmallGray
                                        sx={{
                                            width       : '70px',
                                            textAlign   : 'right',
                                            marginRight : `${spaces.textLine[1]}px`,
                                            marginTop   : `${spaces.textLine[2]}px`,
                                        }}
                                    >
                                        {i18n.t('Offline')}
                                    </SectionSmallGray>
                                    <IconCmp
                                        source={OfflineIcon}
                                        size={13}
                                        alt={''}
                                    />
                                </Row>
                            ) : batteryLevel ? (
                                <Row>
                                    <SectionSmall
                                        sx={{
                                            width       : '70px',
                                            textAlign   : 'right',
                                            marginRight : `${spaces.textLine[1]}px`,
                                            marginTop   : `${spaces.textLine[2]}px`,
                                        }}
                                    >
                                        {batteryLevel.toFixed(2) + "%"}
                                    </SectionSmall>
                                    <IconCmp
                                        source={BatteryIcon}
                                        size={13}
                                        alt={''}
                                    />
                                </Row>
                            ) : vehicle.current_info?.fuel_level && vehicle.current_info?.fuel_level > 0 ? (
                                <Row>
                                    <SectionSmall
                                        sx={{
                                            width       : '70px',
                                            textAlign   : 'right',
                                            marginRight : `${spaces.textLine[1]}px`,
                                            marginTop   : `${spaces.textLine[2]}px`,
                                        }}
                                    >
                                        {vehicle.current_info?.fuel_level.toFixed(2) + "%"}
                                    </SectionSmall>
                                    <IconCmp
                                        source={GasIcon}
                                        size={13}
                                        alt={''}
                                    />
                                </Row>
                            ) : null
                        }
                    </Box>
                </Box>
            </ListItem>
            <Divider />
        </div>
    )
}

export default VehicleListItem
