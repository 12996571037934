import { createSelector } from 'reselect'
import { auth } from '@safefleet/react-common'

const {
    getState,
    getAuthServer,
    getInstanceServer,
    getCredentials,
    getJwtTokens,
    getSessionId,
    getContext,
    isAuthenticated,
    getServer,
} = auth

const isReauthenticating = createSelector(
    getState,
    (state) => state.reauth
)

export {
    getState,
    getAuthServer,
    getInstanceServer,
    getCredentials,
    getJwtTokens,
    getSessionId,
    getContext,
    isAuthenticated,
    isReauthenticating,
    getServer,
}
