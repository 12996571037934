import * as React from 'react'
import { get } from 'lodash'
import { Theme } from '@mui/material/styles'
import {
    i18n,
    utils as commonUtils,
    DriverModel,
    VehicleModel,
    presences,
} from '@safefleet/react-common'
import {
    differenceInSeconds,
    parse,
} from 'date-fns'
import constants, { VehicleStatusKey, PurposeKey } from '../constants'
import { useSelector } from 'react-redux'

export * from './intervals'

export function logApiContractMismatch(details: string) {
    console.log(`API contract mismatch: ${details}`) // eslint-disable-line
}
export const parseDate = (dateStr) => parse(dateStr, "yyyy-MM-dd'T'HH:mm:ssx", new Date())
const parseBool = (val: any) => (val ? JSON.parse(val) : false)
export const unfinishedFeatures = parseBool(process.env.REACT_APP_UNFINISHED_FEATURES)
export function colorFromVehicleStatus(status: VehicleStatusKey,theme: Theme) {
    const colors = theme.custom.markerColors
    switch (status) {
        case -1:
            // TODO: no gray in telematico colors
            return colors.offline
        case 0:
            // TODO: no gray in telematico colors
            return colors.off
        case 1:
            return colors.on
        default: {
            return colors.unexpected
        }
    }
}
export const driverName = (driver?: DriverModel, vehicle?: VehicleModel) => {
    const driverName = !driver ? get(vehicle, 'default_driver', '') : driver.name
    return driverName || i18n.t('Unknown Driver')
}

export const currentPurpose = (vehicle?: VehicleModel) => {
    const purpose = vehicle?.current_info?.purpose

    return purpose && (purpose === 'b' || purpose === 'p')
        ? i18n.t(constants.purpose[purpose])
        : i18n.t('Purpose Unspecified')
}

export type EdgePadding = {
    bottom?: number,
    top?: number,
    left?: number,
    right?: number,
}
export type StrictEdgePadding = {
    bottom: number,
    top: number,
    left: number,
    right: number,
}

export const timeDifference = (startMoment: string, stopMoment: string): number => {
    const start = commonUtils.parseDate(startMoment)
    const stop = commonUtils.parseDate(stopMoment)
    return differenceInSeconds(stop, start)
}

export const journeyPurpose = (purpose?: PurposeKey | null) => (
    purpose ? i18n.t(constants.purpose[purpose]) : i18n.t('Purpose Unspecified')
)

/**
 * Returns true if the vehicle's engine is on
 */
export function engineIsOn(vehicle: VehicleModel | undefined) {
    return vehicle?.current_info?.status === 1
}

export const getCurrentPresences = (vehicle: VehicleModel | undefined, state: any) => {
    const engineSince = vehicle?.current_info?.engine_since
    const moment = vehicle?.current_info?.moment
    if (!engineIsOn(vehicle) || !vehicle || !engineSince || !moment) {
        return []
    }


    const startMoment = commonUtils.parseDate(engineSince)
    const stopMoment = commonUtils.parseDate(moment)
    const vehicleId = vehicle.vehicle_id

    return presences.selectors.getForInterval({ startMoment, stopMoment, vehicleId })(state)
}

export const useGetJourneyPresences = (
    vehicle: VehicleModel | undefined,
    startMoment: Date | undefined,
    stopMoment: Date | undefined
) => {
    const vehicleId = vehicle?.vehicle_id || 0

    const selector = React.useMemo(()=> presences.selectors.getForInterval({
        startMoment : parseDate(startMoment),
        stopMoment  : parseDate(stopMoment),
        vehicleId,
    }),[startMoment, stopMoment, vehicleId] )

    return useSelector((state: any)=>{
        if (!vehicle || !startMoment || !stopMoment) {
            return []
        }

        return selector(state)
    })
}

export const isIMEI = (s:string) => {
    const etal = /^[0-9]{15}$/
    if (!etal.test(s)) { return false }
    let sum = 0
    let mul = 2
    const l = 14
    for (let i = 0; i < l; i += 1) {
        const digit = s.substring(l - i - 1, l - i)
        const tp = parseInt(digit, 10) * mul
        if (tp >= 10) { sum += (tp % 10) + 1 } else { sum += tp }
        if (mul === 1) { mul += 1 } else { mul -= 1 }
    }
    const chk = ((10 - (sum % 10)) % 10)
    if (chk !== parseInt(s.substring(14, 15), 10)) { return false }
    return true
}
