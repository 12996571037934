import * as React from 'react'
import { getChunks } from './base'


type Props = {
    segments: number[],
    labelText: (labelId: number) => string,
    style?: React.CSSProperties,
}

type LabelProps = {
    text: string,
}
//TODO: improve style
const textColor = '#C4C4C4'
const Label: React.FC<LabelProps> = ({ text }) =>
    <span style={ {
        color    : textColor,
        fontSize : 11,
    } }>{text}</span>

const headerContainer: React.CSSProperties = {
    width          : '100%',
    display        : 'flex',
    flexDirection  : 'row',
    justifyContent : 'space-around',
}
type SegmentProps = {
    domainLen: number,
    len: number,
}
const Segment: React.FC<SegmentProps>  = ({ domainLen, len }) =>
    <div style={{ flex: len*100/domainLen }} />


const HeaderLabels : React.FC<Props> = ({ segments,style,labelText }) => {
    const domainLen = segments.length >= 2
        ?  segments[segments.length - 1] - segments[0]
        : 0

    const chunks = getChunks(segments)
    const all = chunks.map((x,ix)=>{
        const isLast=ix === chunks.length - 1
        const seg = <Segment
            key={ix+'s'}
            domainLen={domainLen}
            len={x} />
        const label = () => <Label key={ix+'l'} text={labelText(segments[ix+1])} />
        return isLast ? [seg] : [ seg,label() ]
    }).flat()
    return (
        <div style={{ ...headerContainer,...style }} >
            {all}
        </div>
    )

}

export default HeaderLabels
