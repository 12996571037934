import React from "react"
import { VehicleModel } from '@safefleet/react-common'
import VehicleMarker from "./VehicleMarker"
import { useSelector } from "react-redux"
import { context } from "../redux"
import useSupercluster from "use-supercluster"
import { useLocation, useNavigate } from "react-router-dom"

const ClusterMarker = ( { children }: any ) => children

const MapClusters = (
    vehicles: VehicleModel[],
    zoom: number,
    bounds: number[]
) => {
    const { pathname } = useLocation()
    const journeyId = pathname.split('/')[3]
    const mapRef: any = useSelector(context.selectors.getMapRef)

    const navigate = useNavigate()

    const onChildClickCallback = React.useCallback((key: any) => {
        navigate(`${key*1}`)
    }, [navigate])

    // prepare points from vehicles (for useSuperCluster)
    const points = vehicles.reduce((acc: any,vehicle)=>{
        const cInfo = vehicle.current_info
        if(!cInfo){
            return acc
        }


        return acc.concat([
            {
                type       : "Feature",
                id         : vehicle.vehicle_id,
                properties : { cluster: false, vehicleId: vehicle.vehicle_id },
                vehicle    : vehicle,
                geometry   : {
                    type        : "Point",
                    coordinates : [
                        cInfo.lng,
                        cInfo.lat,
                    ],
                },
            },
        ])
    }, [])


    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: 200, maxZoom: 15 },      // TODO: should these be custom/default params / props
    })

    if(journeyId) {
        return []
    }

    return clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates
        const {
            cluster: isCluster,
            point_count: pointCount,
        } = cluster.properties

        const clusterMarkerSize = 60 + (pointCount / points.length) * 20

        if (isCluster) {
            return (
                <ClusterMarker
                    key={`cluster-${cluster.id}`}
                    lat={latitude}
                    lng={longitude}
                >
                    <div
                        className="cluster-marker-transparent"
                        style={{
                            width  : `${20 + clusterMarkerSize}px`,
                            height : `${20 + clusterMarkerSize}px`,
                        }}
                        onClick={() => {
                            const expansionZoom = Math.min(
                                supercluster.getClusterExpansionZoom(cluster.id),
                                20
                            )
                            mapRef.map.setZoom(expansionZoom)
                            mapRef.map.panTo({ lat: latitude, lng: longitude })
                        }}
                    >
                        <div
                            className="cluster-marker"
                            style={{
                                width  : `${clusterMarkerSize}px`,
                                height : `${clusterMarkerSize}px`,
                            }}
                        >
                            <span
                                style={{
                                    fontWeight : 'bold',
                                    fontSize   : 14,
                                }}
                            >
                                {pointCount}
                            </span>
                        </div>
                    </div>
                </ClusterMarker>
            )
        }

        return (
            <VehicleMarker
                key={`vehicleid-${cluster.properties.vehicleId}`}
                vehicle={cluster.vehicle}
                lat={latitude}
                lng={longitude}
                onClick={() => onChildClickCallback(cluster.properties.vehicleId)}
            />
        )
    })
}

export default MapClusters