import * as React from 'react'
import InnerLines from './InnerLines'
import InnerSegments from './InnerSegments'
import { IntervalLine,Interval,split } from './base'

type Props = {
    checkpoint: number, // in tiks
    domain: Interval<number>,
    innerSegmentsContainerStyle?: React.CSSProperties,
    lines: IntervalLine<number>[],
    offIntervalLine?: OffIntervalLine,
}

type OffIntervalLine = {
    color: string,
    height: number,
}
const Inner : React.FC<Props> = ({
    domain,
    innerSegmentsContainerStyle,
    checkpoint,
    lines,
}) => {
    const segments = split(domain.start,domain.end,checkpoint)
    return (
        <div style={{ position: 'relative',paddingTop: '5px',paddingBottom: '12px' }}>
            <div style={{
                position        : 'absolute',
                top             : 0,
                left            : 0,
                right           : 0,
                bottom          : 0,
                zIndex          : -1,
                backgroundColor : 'blue',
            }}>
                <InnerSegments segments={segments} style={innerSegmentsContainerStyle} />
            </div>
            <InnerLines domain={domain} lines={lines} />
        </div>
    )
}

export default Inner
