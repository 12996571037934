import googlePin from "../../assets/img/google-pin.svg"
import Icon from "../ui/Icon"
import { SectionRegularDenim, SectionTitle } from "../Text"
import { TopCell } from "../vehicle-drawer/VehicleDetails"
import { i18n } from "@safefleet/react-common"
import FlagIcon from "../../assets/img/flag.svg"

const SelectedPlace = ({ place, onEstimateRoute }) => {
    const row = {
        display       : 'flex',
        flexDirection : 'row',
    } as React.CSSProperties

    const Divider = () =>
        <div style={{ height: 1, backgroundColor: '#E0E0E0', width: '100%' }}/>


    const handleClickOnEsimateRoute = () => {
        onEstimateRoute({
            lat : place.coordinates.lat,
            lng : place.coordinates.lng,
        })
    }

    return (
        <div>
            <div style={{
                paddingTop    : 24,
                paddingBottom : 24,
                marginLeft    : 20,
                marginRight   : 20,
            }}>
                <div
                    style={{
                        display       : "flex",
                        flexDirection : "column",
                    }}
                >
                    <div
                        style={{
                            display       : "flex",
                            alignItems    : "center",
                            flexDirection : "row",
                            marginBottom  : 24,
                        }}
                    >
                        <Icon
                            source={googlePin}
                            size={18}
                            style={{ marginRight: 12 }}
                            alt={"google pin"}
                        />
                        <SectionTitle
                            style={{
                                whiteSpace   : "nowrap",
                                textOverflow : "ellipsis",
                                overflow     : "hidden",
                            }}
                        >
                            {place.full_address}
                        </SectionTitle>
                    </div>
                    <div style={row}>
                        <TopCell
                            label={i18n.t('Coordinates')}
                            value={
                                place?.coordinates?.lat?.toFixed(4)
                                + ", " +
                                 place?.coordinates?.lng?.toFixed(4)
                            }
                            isLeft={true} />
                        <TopCell
                            label={i18n.t('Country')}
                            value={place.country}
                            isLeft={false} />
                    </div>
                    <div style={row}>
                        <TopCell label={i18n.t('County')} value={place.county} isLeft={true} />
                        <TopCell
                            label={i18n.t('Locality')}
                            value={place.locality}
                            isLeft={false} />
                    </div>
                    <div style={row}>
                        <TopCell label={i18n.t('Street')} value={place.street} isLeft={true} />
                        <TopCell
                            label={i18n.t('Street number')}
                            value={place.street_number}
                            isLeft={false} />
                    </div>
                </div>
            </div>
            <Divider/>
            <div style={{ padding: 24 }}>
                <div style={{ ...row, cursor: 'pointer' }}>
                    <Icon
                        source={FlagIcon}
                        size={18}
                        alt={''}
                    />
                    <SectionRegularDenim
                        onClick={handleClickOnEsimateRoute}
                        style={{ marginLeft: 12 }}
                    >
                        {i18n.t('Estimate route')}
                    </SectionRegularDenim>
                </div>
            </div>
            <Divider/>
        </div>
    )
}

export default SelectedPlace
