import * as React from 'react'
import VehicleListItem from './VehicleListItem'
import { get,sortBy,includes, isEmpty, toLower } from 'lodash'
import { useSelector } from 'react-redux'
import { VehicleModel, vehicles } from '@safefleet/react-common'
import * as RX from '../../redux'
import LoadingContainer from './LoadingContainer'
import * as HC from '../../hooks/vehicle'

const searchFunc = (searchValue: string) => (
    e: VehicleModel
) => ((isEmpty(searchValue) || includes(toLower(e.name), toLower(searchValue))
|| includes(toLower(e.license_plate), toLower(searchValue))) && e?.device_serial_number)

const useWithFilter = () => {
    const { userVehicles } = useMapStateToProps()
    const filter  = useSelector((state: any) => RX.context.selectors.getSearchValue(
        state,{ screen: 'vehicles' }
    ))

    const onlyActiveVehicles = useSelector(RX.context.selectors.getOnlyActiveVehiclesFilter)
    return React.useMemo(()=>{
        const filterBy = searchFunc(filter)

        return userVehicles.filter(!onlyActiveVehicles
            ? filterBy
            : (vehicle) => filterBy(vehicle) && HC.getStatus(vehicle) === 1 )

    },[userVehicles,filter,onlyActiveVehicles])
}

type VehiclesListScreenProps = {
    userVehicles : VehicleModel[],
    isGetting    : boolean,
}
const useMapStateToProps = (): VehiclesListScreenProps => {
    const userVehicles  = useSelector((state: any) => sortBy(
        vehicles.selectors.getAll(state),
        (vehicle) => toLower(get(vehicle, 'name'))
    ))
    const isGetting = useSelector((state : any) => vehicles.selectors.isPendingForTag(
        RX.tags.IS_GETTING
    )(state))
    return {
        userVehicles,
        isGetting,
    }
}

const VehicleList: React.FC<{}> = () => {
    const filteredVehicles = useWithFilter()
    const isAutoRefresh = useSelector((state : any) => vehicles.selectors.isPendingForTag(
        RX.tags.AUTO_REFRESH
    )(state))
    const isGetting = useSelector((state : any) => vehicles.selectors.isPendingForTag(
        RX.tags.IS_GETTING
    )(state))

    if(!isAutoRefresh && isGetting) {
        return <LoadingContainer/>
    }

    return (
        <div>
            {filteredVehicles.map((vehicle, index) => (
                <VehicleListItem
                    vehicle={vehicle}
                    onPress={()=>{}}
                    index={index}
                    key={index}
                />
            )) }
        </div>
    )
}

export default VehicleList
