import { ActionCreator } from '@safefleet/react-common'

export const USER_CHANGED             = '@ context / USER_CHANGED'
export const VEHICLE_CHANGED          = '@ context / VEHICLE_CHANGED'
export const REFRESH_VEHICLE          = '@ context / REFRESH_VEHICLE'
export const SET_REPORT_INTERVAL      = '@ context / SET_REPORT_INTERVAL'
export const JOURNEYS_CHANGED         = '@ context / JOURNEYS_CHANGED'
export const SET_TRAJECTORY_INTERVAL  = '@ context / SET_TRAJECTORY_INTERVAL'
export const UPLOAD_FILE              = '@ context / UPLOAD_FILE'
export const SET_SEARCH_VALUE         = '@ context / SET_SEARCH_VALUE'
export const SET_SEARCH_DISPLAYED     = '@ context / SET_SEARCH_DISPLAYED'
export const SET_MAP_REF              = '@ context / SET_MAP_REF'
export const START_POLLING            = '@ context / START_POLLING'
export const STOP_POLLING             = '@ context / STOP_POLLING'
export const STOP_POLLING_TASK        = '@ context / STOP_POLLING_TASK'
export const POLLING_TASK_REQUESTED   = '@ context / POLLING_TASK_REQUESTED'
export const POLLING_TASK_FINISHED    = '@ context / POLLING_TASK_FINISHED'
export const SET_MAP_TYPE             = '@ context / SET_MAP_TYPE'
export const SET_FLEET_ID             = '@ context / SET_FLEET_ID'
export const SET_COMPANY_ID           = '@ context / SET_COMPANY_ID'
export const SET_DRAWER_STATUS        = '@ context / SET_DRAWER_STATUS'
export const SET_ADDRESS_SEARCH_DRAWER_STATUS        = '@ context / SET_ADDRESS_SEARCH_DRAWER_STATUS'
export const SET_ONLY_ACTIVE_VEHICLES = '@ context / SET_ONLY_ACTIVE_VEHICLES'
export const SET_FILTERS_PANEL_STATUS = '@ context / SET_FILTERS_PANEL_STATUS'
export const SET_JOURNEYS_SLIDER_VALUES = '@ context / SET_JOURNEY_SLIDER_VALUES'
export const SET_JOURNEYS_FILTERS_DATES = '@ context / SET_JOUNEYS_FILTERS_DATES'
export const SET_SHOW_JOURNEYS_POIS = '@ context / SET_SHOW_JOURNEYS_POIS'
export const SET_SELECTED_ADDRESS_FOR_ROUTE_ESTIMATION = '@ context / SET_SELECTED_ADDRESS_FOR_ROUTE_ESTIMATION'

export const setSelectedAddressForRouteEstimation = (address): SetSelectedAddressForRouteEstimation => ({
    type: SET_SELECTED_ADDRESS_FOR_ROUTE_ESTIMATION, payload: address,
})

export const setShowJourneysPois = (value): SetShowJourneysPois => ({
    type: SET_SHOW_JOURNEYS_POIS, payload: value,
})

export const setJourneysSliderValue = (values): SetJourneysSliderValues => ({
    type: SET_JOURNEYS_SLIDER_VALUES, payload: values,
})

export const setJourneysFiltersDates = (values): SetJourneysFiltersDates => ({
    type: SET_JOURNEYS_FILTERS_DATES, payload: values,
})

export const setOnlyActiveVehiclesFilter = (onlyActiveVehicles: boolean): SetOnlyActiveVehiclesFilter => ({
    type: SET_ONLY_ACTIVE_VEHICLES, payload: onlyActiveVehicles,
})
export const setDrawerStatus = (status): SetDrawerStatus => ({
    type: SET_DRAWER_STATUS, payload: status,
})

export const setAddressSearchDrawerStatus = (status): SetAddressSearchDrawerStatus => ({
    type: SET_ADDRESS_SEARCH_DRAWER_STATUS, payload: status,
})

export const setFiltersPanelStatus = (value) => ({
    type: SET_FILTERS_PANEL_STATUS, payload: value,
})

export const changeVehicle = (vehicleId: number | null): ChangeVehicleAction => ({
    type: VEHICLE_CHANGED, payload: { vehicleId: vehicleId || null },
})

export const setSearchValue = (
    screen: string | undefined,
    value: string
): SetSearchValueAction => ({ type: SET_SEARCH_VALUE, payload: { screen, value } })

export const setSearchDisplayed = (
    screen: string | undefined,
    displayed: boolean
): SetSearchDisplayAction => (
    { type: SET_SEARCH_DISPLAYED, payload: { screen, displayed } }
)

export const setTrajectoryInterval = (
    startMoment: Date,
    stopMoment: Date
): SetTrajectoryIntervalAction => ({
    type    : SET_TRAJECTORY_INTERVAL,
    payload : { startMoment, stopMoment },
})

export const clearTrajectoryInterval = (): SetTrajectoryIntervalAction => ({
    type    : SET_TRAJECTORY_INTERVAL,
    payload : null,
})

export const refreshVehicle = (
    vehicleId: number
): RefreshVehicleAction => ({ type: REFRESH_VEHICLE, payload: { vehicleId } })

export const setCompanyId = (
    companyId: number | 'mine'
): SetCompanyIdAction => ({ type: SET_COMPANY_ID, payload: { companyId } })

export const setFleetId = (
    fleetId: number | 'mine'
): SetFleetIdAction => ({ type: SET_FLEET_ID, payload: { fleetId } })

export const setReportInterval = (
    startMoment: Date,
    stopMoment: Date,
    filterShortJourneys: boolean
): SetReportIntervalAction => ({
    type    : SET_REPORT_INTERVAL,
    payload : { startMoment, stopMoment, filterShortJourneys },
})

export const changeJourneys = (
    journeyIds: string[]
): JourneysChangedAction => ({ type: JOURNEYS_CHANGED, payload: { journeyIds } })

export const setMapRef = (
    mapRef: any
) => ({ type: SET_MAP_REF, payload: mapRef })

export const setMapType = (
    mapType: string
) => ({ type: SET_MAP_TYPE, payload: mapType })

export const startPolling = () => ({ type: START_POLLING  })

export const stopPolling = () => ({ type: STOP_POLLING })

export type SetMapType = {
    type: typeof SET_MAP_TYPE,
    payload: "roadmap" | "terrain" | "satellite"
}

export type StartPolling = {
    type: typeof START_POLLING
}

export type StopPolling = {
    type: typeof STOP_POLLING
}

export type PoolingTaskRequested = {
    type: typeof POLLING_TASK_REQUESTED
}

export type PoolingTaskFinished = {
    type: typeof POLLING_TASK_FINISHED
}

export type SetMapRef = {
    type: typeof SET_MAP_REF,
    payload: any
}

export type SetDrawerStatus = {
    type: typeof SET_DRAWER_STATUS,
    payload: "open" | "close"
}

export type SetAddressSearchDrawerStatus = {
    type: typeof SET_ADDRESS_SEARCH_DRAWER_STATUS,
    payload: "open" | "close"
}

export type SetJourneysSliderValues = {
    type: typeof SET_JOURNEYS_SLIDER_VALUES,
    payload: Array<number>
}

export type SetShowJourneysPois = {
    type: typeof SET_SHOW_JOURNEYS_POIS,
    payload: boolean
}

export type SetJourneysFiltersDates= {
    type: typeof SET_JOURNEYS_FILTERS_DATES,
    payload: Array<Date> | null
}

export type SetOnlyActiveVehiclesFilter = {
    type: typeof SET_ONLY_ACTIVE_VEHICLES,
    payload: boolean
}

export type SetFiltersPanel = {
    type: typeof SET_FILTERS_PANEL_STATUS,
    payload: any
}

export type JourneysChangedAction = {
    type: typeof JOURNEYS_CHANGED,
    payload: {
        journeyIds: string[],
    }
}
export type SetTrajectoryIntervalAction = {
    type: typeof SET_TRAJECTORY_INTERVAL,
    payload: {
        startMoment: Date,
        stopMoment: Date,
    } | null
}
export type SetReportIntervalAction = {
    type: typeof SET_REPORT_INTERVAL,
    payload: {
        startMoment: Date,
        stopMoment: Date,
        filterShortJourneys: boolean,
    }
}
export type RefreshVehicleAction = {
    type: typeof REFRESH_VEHICLE,
    payload: {
        vehicleId: number | null,
    }
}
export type SetCompanyIdAction = {
    type: typeof SET_COMPANY_ID,
    payload: {
        companyId: number | 'mine',
    }
}
export type SetFleetIdAction = {
    type: typeof SET_FLEET_ID,
    payload: {
        fleetId: number | 'mine',
    }
}

export type ChangeVehicleAction = {
    type: typeof VEHICLE_CHANGED,
    payload: {
        vehicleId: number | null,
    }
}
export type ChangeUserAction = {
    type: typeof USER_CHANGED,
    payload: {
        userId: number | null,
    }
}
export type SetSearchValueAction = {
    type: typeof SET_SEARCH_VALUE,
    payload: {
        screen?: string,
        value?: string,
    },
}
export type SetSearchDisplayAction = {
    type: typeof SET_SEARCH_DISPLAYED,
    payload: {
        screen?: string,
        displayed: boolean,
    },
}

export type SetSelectedAddressForRouteEstimation = {
    type: typeof SET_SELECTED_ADDRESS_FOR_ROUTE_ESTIMATION,
    payload: {
        lat: number;
        lng: number;
    },
}

export const uploadFile: ActionCreator<{
    type: string | undefined,
    data: string | null| undefined
    tag: string | undefined,
}> = (payload) => (
    {
        type: UPLOAD_FILE,
        payload,
    }
)

export type ContextAction =
    | ChangeVehicleAction
    | ChangeUserAction
    | RefreshVehicleAction
    | SetReportIntervalAction
    | JourneysChangedAction
    | SetTrajectoryIntervalAction
    | SetSearchDisplayAction
    | SetSearchValueAction
    | SetFleetIdAction
    | SetCompanyIdAction
    | SetMapRef
    | StartPolling
    | StopPolling
    | PoolingTaskRequested
    | PoolingTaskFinished
    | SetMapType
    | SetDrawerStatus
    | SetFiltersPanel
    | SetJourneysSliderValues
    | SetJourneysFiltersDates
    | SetShowJourneysPois
    | SetOnlyActiveVehiclesFilter
    | SetAddressSearchDrawerStatus
    | SetSelectedAddressForRouteEstimation
