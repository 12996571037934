import * as React from 'react'
import Inner from './Inner'
import Header from './Header'
import { IntervalLine, Interval,unifyIntervals, sortIntervals, reverseIntervals } from './base'
import Legend,{ LegendLineDescription } from './Legend'
export type { Interval,IntervalLine } from './base'
export { unifyIntervals,sortIntervals,reverseIntervals } from './base'

type Props = {
    lines: IntervalLine<number>[],
    checkpoint: number, // in tics
    domain: Interval<number>,
    headerLabelText: (labelId: number) => string,
    reverseLineColor?: string,
    reverseLineText?: string,
    reverseLineHeight?: number,
    legendDotStyle?: React.CSSProperties,
    legendTextStyle?: React.CSSProperties,
    showLegend?: boolean,
}

function mapInterval<T>(f: ((x:Date) => T)) {
    return (interval: Interval<Date>): Interval<T> => ({
        start : f(interval.start),
        end   : f(interval.end),
    })
}

export const dateIntervalToNumber = mapInterval((x) => x.getTime())

export const useNumberInterval = (interval: Interval<Date>) => React.useMemo(()=>({
    start : interval.start.getTime(),
    end   : interval.end.getTime(),
}),[interval.start,interval.end])

const useLines = (
    rawLines: IntervalLine<number>[],
    domain: Interval<number>,
    reverseLineColor?: string,
    reverseLineText?: string,
    reverseLineHeight?: number
): IntervalLine<number>[] => React.useMemo(()=>{
    if(!reverseLineColor){
        return rawLines
    }
    const intervals = rawLines.map( (x) => x.intervals )
    const revIntervals = reverseIntervals(
        unifyIntervals(
            sortIntervals(intervals,(a,b) => a - b )
        ),
        domain
    )
    const reverseLine: IntervalLine<number> = {
        intervals : revIntervals,
        color     : reverseLineColor,
        name      : reverseLineText || '',
        height    : reverseLineHeight || 3,
    }
    return rawLines.concat(reverseLine)
},
[domain,rawLines,reverseLineText,reverseLineColor,reverseLineHeight])

const useLegendLines = (lines: IntervalLine<any>[]) => React.useMemo(()=>{
    return lines.map((x)=>({
        color : x.color,
        name  : x.name,
    } as LegendLineDescription))
},[lines])
const Timeline : React.FC<Props> = ({
    domain,
    checkpoint,
    headerLabelText,
    lines: rawLines,
    reverseLineColor,
    reverseLineText,
    reverseLineHeight,
    legendDotStyle,
    legendTextStyle,
    showLegend,
}) => {
    const lines = useLines(
        rawLines,
        domain,
        reverseLineColor,
        reverseLineText,
        reverseLineHeight
    )
    const legendLines = useLegendLines(lines)
    return (
        <div>
            <Header labelText={headerLabelText} domain={domain} checkpoint={checkpoint} />
            <Inner lines={lines} domain={domain} checkpoint={checkpoint} />
            {showLegend && <Legend
                lines={legendLines}
                dotStyle={legendDotStyle}
                textStyle={legendTextStyle}
            />}
        </div>
    )
}

export default Timeline
