import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import { auth, i18n } from '../redux'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'


function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.safefleet.eu/">
                Safefleet
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})


const useErrorContainer = () => {
    const [errorMsg, setErrorMsg] = React.useState(null as ([string,boolean] | null))
    const isError = errorMsg && errorMsg[1]

    const handleClose = (
        _: React.SyntheticEvent | React.MouseEvent,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return
        }

        setErrorMsg(null)
    }

    const ErrorContainer = () => ( <Snackbar open={!!errorMsg} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={isError ? "error" : "warning"} sx={{ width: '100%' }}>
            {errorMsg}
        </Alert>
    </Snackbar>
    )
    return { ErrorContainer,setErrorMsg }
}
export const SignOut = () => {
    const dispatch = useDispatch()
    const logout = () => {
        dispatch(auth.actions.requestLogout())
    }
    return (
        <Box
            sx={{
                marginTop     : 8,
                display       : 'flex',
                flexDirection : 'column',
                alignItems    : 'center',
            }}
        >
            <Button
                fullWidth
                onClick={logout}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                {i18n.t("Sign out")}
            </Button>
        </Box>
    )
}

export default function MainPage(){
    const isAuthenticated = useSelector(auth.selectors.isAuthenticated)
    const { ErrorContainer,setErrorMsg } = useErrorContainer()
    return (
        <Container component="main" maxWidth="xs">
            <ErrorContainer />
            {
                isAuthenticated
                    ? <SignOut />
                    : <SignIn setErrorMsg={setErrorMsg} />
            }
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    )

}
type SetErrorMsg = (msg: [string,boolean] | null) => any

type SignInProps = {
    setErrorMsg: SetErrorMsg
}
const SignIn: React.FC<SignInProps> = ({ setErrorMsg }) => {
    const dispatch = useDispatch()
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        const username = data.get('username')
        const password = data.get('password')
        if(username && password){
            dispatch(auth.actions.authenticateJWT({
                username        : username.toString(),
                password        : password.toString(),
                saveCredentials : false,
                instancePrefix  : window.location.hostname.split('.')[0],
            }))
        }
    }
    const notImplemented= () => setErrorMsg(['Not implemented',false])
    return (
        <Box
            sx={{
                marginTop     : 8,
                display       : 'flex',
                flexDirection : 'column',
                alignItems    : 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {i18n.t("Sign in")}
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label={i18n.t("Username")}
                    name="username"
                    autoComplete="username"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={i18n.t("Password")}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    {i18n.t("Sign in")}
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link href="#" variant="body2" onClick={notImplemented}>
                            {i18n.t("Forgot password?")}
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

