import { Paper } from "@mui/material"
import Drawer from "@mui/material/Drawer"
import { drawerWidth } from "../vehicle-drawer"
import InputBase from "@mui/material/InputBase"
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"
import { useCallback, useMemo, useRef, useState } from "react"
import * as RC from "@safefleet/react-common"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import PlacesList from "./PlacesList"
import SelectedPlaceComponent from "./SelectedPlace"
import * as RX from '../../redux'
import { useDispatch, useSelector } from "react-redux"
import EstimatedRoutes from "./EstimatedRoutes"
import { setSelectedAddressForRouteEstimation } from "../../redux/context/actions"

enum PlaceKeys {
    STREET_NUMBER = "street_number",
    STREET = "route",
    LOCALITY = "locality",
    COUNTY1 = "administrative_area_level_1",
    COUNTY2 = "administrative_area_level_2",
    COUNTRY = "country",
}

interface Place {
    street?: string;
    country?: string;
    locality?: string;
    county?: string;
    street_number?: string;
    full_address?: string;
    coordinates: {
        lat: number,
        lng: number
    }
}

interface EstimatedRouteParams {
    lat: number,
    lng: number
}

interface FilterProps {
    onChangeSearch: (value: string) => void;
    searchText: string;
}


const Filter: React.FC<FilterProps> = ({ onChangeSearch, searchText }) => {
    return (
        <Paper
            component="form"
            onSubmit={(e) => e.preventDefault()}
            sx={{
                display      : "flex",
                alignItems   : "center",
                width        : "100%",
                marginBottom : "5px",
                height       : "48px",
                boxShadow    : "0px 2px 10px rgba(0, 0, 0, 0.1)",
                borderRadius : "8px",
            }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                value={searchText}
                inputProps={{ "aria-label": RC.i18n.t("search google maps") }}
                onChange={(event) => {
                    onChangeSearch(event.target.value)
                }}
                placeholder={RC.i18n.t('Search for an address')}
            />
            <IconButton sx={{ p: "10px", color: "#C4C4C4" }} aria-label="search">
                <SearchIcon />
            </IconButton>
        </Paper>
    )
}

const AddressSearch = () => {
    const dispatch = useDispatch()
    const userVehicles  = Object.keys(useSelector(RX.vehicles.selectors.getAll)).map((item) => parseInt(item))
    const [searchText, setSearchText] = useState("")
    const [showEstimatedRoute, setShowEstimatedRoute] = useState(false)
    const [selectedPlace, setSelectedPlace] = useState<Place | null>(null)
    const [selectedCoordinates, setSelectedCoordinates] = useState<EstimatedRouteParams | undefined>()
    const drawerStatus = useSelector(RX.context.selectors.getAddressSearchDrawerStatus)

    const isDrawerToggleOpen = drawerStatus === 'open'
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey   : "AIzaSyCIJvz4C2LOwXQQ7XEipIw3T_4AJVdMwL0",
        debounce : 1000,
        options  : {
            language: "ro",
        },
    })
    const pageRef = useRef(0)
    const itemsPerPage = 1

    const getComponentValue = (key: string, componentes) => {
        return (componentes.findLast((cmp) => cmp.types.includes(key) )?.long_name) || ""
    }

    const getLocationDetails = (place_id: string, full_address: string) => {
        placesService?.getDetails(
            {
                placeId: place_id,
            },
            (placeDetails) => {
                const lat = placeDetails.geometry.location.lat()
                const lng = placeDetails.geometry.location.lng()

                dispatch(setSelectedAddressForRouteEstimation({ lat, lng }))

                const componentes = placeDetails.address_components
                const street_number = getComponentValue(
                    PlaceKeys.STREET_NUMBER,
                    componentes
                )
                const street = getComponentValue(PlaceKeys.STREET, componentes)
                const locality = getComponentValue(PlaceKeys.LOCALITY, componentes)
                const county1 = getComponentValue(PlaceKeys.COUNTY1, componentes)
                const county2 = getComponentValue(PlaceKeys.COUNTY2, componentes)
                const country = getComponentValue(PlaceKeys.COUNTRY, componentes)

                setSelectedPlace({
                    country       : country,
                    street_number : street_number,
                    locality      : locality,
                    county        : county1 || county2,
                    street        : street,
                    full_address  : full_address,
                    coordinates   : {
                        lat,
                        lng,
                    },
                })
            }
        )
    }

    const handleChangeValue = useCallback(
        (value: string) => {
            getPlacePredictions({ input: value })
            setSearchText(value)
            setSelectedPlace(null)
        },
        [setSearchText, getPlacePredictions]
    )

    const handleDrawerToggle = useCallback(() => {
        if(drawerStatus === 'open') {
            dispatch(setSelectedAddressForRouteEstimation(null))
            dispatch(RX.context.actions.setAddressSearchDrawerStatus('close'))
            setSelectedPlace(null)
            setSearchText('')
        } else {
            dispatch(RX.context.actions.setAddressSearchDrawerStatus('open'))
        }
    }, [dispatch, drawerStatus])

    const handleCloseEstimatedRouteResult = useCallback(() => {
        setShowEstimatedRoute(false)
    }, [])

    const fetchEstimatedRoute = useCallback((paramsCoordinates?: EstimatedRouteParams) => {
        const coordinates = paramsCoordinates || selectedCoordinates
        const itemsToSkip =  pageRef.current * itemsPerPage
        if(coordinates) {
            dispatch(RX.estimatedRoutes.actions.fetchRoutes({
                vehicleIds : userVehicles.slice(itemsToSkip, itemsToSkip + itemsPerPage),
                startLat   : coordinates?.lat,
                startLng   : coordinates?.lng,
            }))

            pageRef.current = pageRef.current + 1
        }


    }, [dispatch, selectedCoordinates, userVehicles])

    const handleEstimateRoute = async (coordinates: EstimatedRouteParams) => {
        setSelectedCoordinates(coordinates)
        setShowEstimatedRoute(true)
        fetchEstimatedRoute(coordinates)
    }

    const loadMore = useMemo(() => {
        return (pageRef.current * itemsPerPage) <= userVehicles.length ? fetchEstimatedRoute : false
    }, [fetchEstimatedRoute, userVehicles.length])

    return (
        <>
            <Drawer
                variant="persistent"
                sx={{
                    "display"            : { sm: "block" },
                    "& .MuiDrawer-paper" : { boxSizing: "border-box", width: drawerWidth },
                }}
                open={isDrawerToggleOpen}
                transitionDuration={70}
                anchor={"right"}
            >
                <div style={{ position: 'absolute', top: 85 ,right: 10 }}>
                    <IconButton
                        onClick={handleDrawerToggle}
                        sx={{ p: "10px", color: "#C4C4C4" }}
                        aria-label="directions"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div
                    style={{
                        height        : "100%",
                        display       : "flex",
                        flexDirection : "column",
                        flex          : 1,
                        marginTop     : 110,
                    }}
                >
                    <div
                        style={{
                            display : "flex",
                            padding : 24,
                        }}
                    >
                        <Filter onChangeSearch={handleChangeValue} searchText={searchText} />
                    </div>
                    <div
                        style={{ height: "5px", width: "100%", backgroundColor: "#EFEFEF" }}
                    />
                    {selectedPlace ? (
                        <SelectedPlaceComponent place={selectedPlace} onEstimateRoute={handleEstimateRoute}/>
                    ) : (
                        <PlacesList
                            loading={isPlacePredictionsLoading}
                            places={placePredictions}
                            getPlaceDetails={getLocationDetails}
                        />
                    )}
                </div>
            </Drawer>
            {
                showEstimatedRoute && (
                    <EstimatedRoutes onClose={handleCloseEstimatedRouteResult} loadMore={loadMore}/>
                )
            }
        </>
    )
}

export default AddressSearch
