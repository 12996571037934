import CircularProgress from '@mui/material/CircularProgress'

const LoadingContainer = () => {
    return (
        <div style={{
            display        : 'flex',
            height         : '100%',
            alignItems     : 'center',
            justifyContent : 'center',
            marginBottom   : 20,
            marginTop      : 20,
        }}>
            <CircularProgress disableShrink />
        </div>
    )
}

export default LoadingContainer