import { auth, ActionType, ActionCreator } from '@safefleet/react-common'
// import { app, ActionType, ActionCreator } from '@safefleet/react-common'

export const REAUTH_STATE_CHANGED : ActionType = '@ auth / REAUTH_STATE_CHANGED'
export const REFRESH_TOKEN : ActionType = '@ auth / REFRESH_TOKEN'
export const {
    LOGOUT,
    USER_LOGIN_REQUESTED,
    VEHICLE_LOGIN_REQUESTED,
    DRIVER_LOGIN_REQUESTED,
    LOGIN_FAILED,
    TWO_FA_REQUIRED,
    TWO_FA_FAILED,
    LOGIN_SUCCEEDED,
    SESSION_EXPIRED,
    AUTH_SERVER_UPDATED,
    INSTANCE_SERVER_UPDATED,

    authenticateJWT,
    updateAuthServer,
    getJwtTokens,
    resetAuthServer,
    updateInstanceServer,
    updateCredentials,
    logout,
    requestLogout,
} = auth

export const changeReauthState : ActionCreator<{ reauth: boolean }> = (payload) => (
    {
        type: REAUTH_STATE_CHANGED,
        payload,
    }
)
export const refreshToken : ActionCreator<{ accessToken: string,refreshToken: string }> = (payload) => (
    {
        type: REFRESH_TOKEN,
        payload,
    }
)
