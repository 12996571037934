import * as React from 'react'
import { useSelector,useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"
import * as RX from '../../redux'
import * as RC from '@safefleet/react-common'
import Divider from '@mui/material/Divider'
import { Interval } from '../timeline'
import {
    startOfWeek, endOfWeek, subDays,
    endOfDay,startOfDay,
} from 'date-fns'
import * as Utils from '../../utils'
import { BoldText } from '../Text'
import DayWorkingTimeLine from './DayWorkingTimeLine'
import DayIdleTimeLine from './DayIdleTimeLine'
import WeekTimeLine from './WeekTimeLine'
import { DrawerBottom } from './DrawerBottom'
import LoadingContainer from './LoadingContainer'

const initialInterval = (now: Date): Interval<Date> => ({
    start : startOfDay(now),
    end   : endOfDay(now),
})

export type IntervalKeys = 'today' | 'last24' | 'thisWeek' | 'lastWeek'
type Intervals = {[keys in IntervalKeys]: Interval<Date>}

export const getIntervals = (now: Date): Intervals => {
    return {
        today  : initialInterval(now),
        last24 : {
            start : subDays(now,1),
            end   : now,
        },
        thisWeek: {
            start : startOfWeek(now, { weekStartsOn: 1 }),
            end   : endOfWeek(now, { weekStartsOn: 1 }),
        },
        lastWeek: {
            start : startOfWeek(subDays(now, 7), { weekStartsOn: 1 }),
            end   : endOfWeek(subDays(now, 7), { weekStartsOn: 1 }),
        },
    }
}
type Props = {
    vehicle: RC.VehicleModel,
}


const getForVehicleIdSel = RX.journeys.selectors.getForVehicleId(true)
export const useJourneys = (start: Date,end: Date,vehicleId: number) => {
    const dispatch = useDispatch()
    React.useEffect(()=>{
        dispatch(RX.journeys.actions.fetchJourneys({
            vehicleId   : vehicleId,
            startMoment : start,
            stopMoment  : end,
        }))
    },[dispatch,start,end,vehicleId])

    const isGetting = useSelector(
        RX.journeys.selectors.isPendingForTag(RX.tags.IS_GETTING)
    )
    const journeys = useSelector(
        (state) => getForVehicleIdSel((state as any), { vehicleId })
    )
    return {
        isGetting,
        journeys,
    }
}

const Title: React.FC<{text:string}> = ({ text }) => {
    return (
        <div style={{
            display      : 'flex',
            alignItems   : 'flex-start',
            marginBottom : '20px',
        }}>
            <BoldText>
                {text}
            </BoldText>
        </div>
    )
}

export const VehicleActivity: React.FC<Props> = ({ vehicle }) => {
    //get the interval
    const intervals = React.useMemo( ()=>{
        return getIntervals(new Date())
    }, [])
    const [start,end] = [intervals.lastWeek.start,intervals.today.end]
    const { journeys } = useJourneys(start,end,vehicle.vehicle_id)

    const schedule = React.useMemo(
        ()=> Utils.toWorkingIntervals(vehicle.working_schedule),
        [vehicle.working_schedule]
    )

    const journeysIsGetting = useSelector(RX.journeys.selectors.isPendingForTag(RX.tags.IS_GETTING))

    return (
        <>
            {
                journeysIsGetting && (
                    <div style={{
                        width           : '100%',
                        height          : '80%',
                        position        : 'absolute',
                        zIndex          : 9999,
                        backgroundColor : 'rgba(255,255,255,0.85)',
                    }}>
                        <LoadingContainer />
                    </div>
                )
            }
            <div style={{ paddingBottom: 20,paddingTop: 20,paddingLeft: 10,paddingRight: 10 }}>
                <Title text={RC.i18n.t('Activity overview')} />
                <DayWorkingTimeLine
                    schedule={schedule}
                    journeys={journeys}
                    interval={intervals.today}
                    showLegend
                />
            </div>
            <Divider />
            <div style={{ paddingBottom: 20,paddingTop: 20,paddingLeft: 10,paddingRight: 10 }}>
                <Title text={RC.i18n.t('Recent activity')} />
                <DayIdleTimeLine
                    journeys={journeys}
                    last24Interval={intervals.last24}
                    todayInterval={intervals.today}
                />
                <br />
                <WeekTimeLine
                    journeys={journeys}
                    interval={intervals.thisWeek}
                    summaryText={RC.i18n.t('This Week')}
                />
                <br />
                <WeekTimeLine
                    journeys={journeys}
                    interval={intervals.lastWeek}
                    summaryText={RC.i18n.t('Last Week')}
                    showLegend
                />
            </div>
            <Divider />
            <DrawerBottom vehicle={vehicle} hide={'activity'} />
        </>
    )
}

type VehicleActivityContainerProps = {
}
export const VehicleActivityContainer: React.FC<VehicleActivityContainerProps> = () => {
    let params = useParams()
    const vehicleId = parseInt(params.vehicleId || '')
    const vehicle = useSelector(RX.vehicles.selectors.getForID(vehicleId))

    if(vehicle){
        return <VehicleActivity vehicle={vehicle} />
    }

    return <h1>VehicleId {params.vehicleId}</h1>
}

export default VehicleActivityContainer
