import * as React from 'react'
import { IntervalLine,Interval } from './base'

type LineProps = {
    key?: any,
    line: IntervalLine<number>,
    domain: Interval<number>,
}

const truncateInterval = (intervals: Interval<number>[],domain: Interval<number>) =>
    intervals.reduce((acc: Interval<number>[],x:Interval<number>)=>{
        if( x.start > x.end || x.end < x.start){
            return acc
        }
        const start = x.start < domain.start ? domain.start : x.start
        const end = x.end > domain.end ? domain.end : x.end
        return acc.concat([{ start,end }])
    },[])

type LineSegProps = {
    color: string,
    width: number,
    height: number,
}
const LineSeg: React.FC<LineSegProps> = ({
    color,
    width,
    height,
}) => {
    return <div style={{
        height       : height,
        flex         : width,
        background   : color,
        borderRadius : 5,
    }}/>
}
const Line: React.FC<LineProps> = ({
    key,
    domain,
    line:{
        intervals,
        color,
        height,
    },
}) => {
    const truncated = truncateInterval(intervals,domain)
    const dlen = domain.end - domain.start
    const getFlexLen = (x: number) => x * 100 / dlen

    const initial: [number,number,string][] = !truncated.length || truncated[0].start <= domain.start
        ? []
        : [[domain.start,truncated[0].start,'transparent']]

    const filled = truncated.reduce((acc,x: Interval<number>,ix)=>{
        const seg = [x.start,x.end,color] as [number,number,string]
        if(ix < truncated.length -1){
            return acc.concat([seg,[x.end,truncated[ix+1].start,'transparent']])
        }else{
            return acc.concat([seg,[x.end,domain.end,'transparent']])
        }
    },initial)

    const components = filled.map((x,ix) => {
        const len = x[1] - x[0]
        return <LineSeg height={height} key={ix} color={x[2]} width={getFlexLen(len)} />
    })
    return (
        <div style={{
            paddingTop    : key*1 === 0 ? '0px': '8px',
            display       : 'flex',
            flexDirection : 'row',
        }}>
            {components}
        </div>
    )
}
type Props = {
    lines: IntervalLine<number>[],
    domain: Interval<number>,
}

const InnerLines : React.FC<Props> = ({ lines,domain }) => {
    return (
        <>
            {
                lines.map( (x,ix) =>
                    <Line domain={domain} key={ix} line={x} />)
            }
        </>
    )
}

export default InnerLines
