import * as rc from '@safefleet/react-common'
import { AnyAction } from 'redux'
import { SET_VEHICLES } from './actions'

export const initialState = {
    ...rc.vehicles.initialState,
}

export function reducer(state = rc.vehicles.initialState, action: AnyAction ) {
    switch (action.type) {
        case SET_VEHICLES: {
            return {
                ...state,
                entities: action.payload,

            }
        }
        default:{
            return rc.vehicles.reducer(state,action)
        }
    }
}
