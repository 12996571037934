import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import SatelliteAltIcon from '@mui/icons-material/Satellite'
import TerrainIcon from '@mui/icons-material/Terrain'
import Popover from '@mui/material/Popover'
import { useDispatch, useSelector } from 'react-redux'
import { setMapType } from '../redux/context/actions'
import MapIcon from '@mui/icons-material/Map'
import layersImg from '../assets/img/layers.svg'
import centerImg from '../assets/img/center.svg'
import fullscreenImg from '../assets/img/fullscreen.svg'
import Icon from './ui/Icon'
import { context, vehicles } from '../redux'
import { get, sortBy, toLower } from 'lodash'
import { useFitBoundsWithPadding } from '../hooks/map'

declare global {
    interface Document {
        mozCancelFullScreen?: () => Promise<void>;
        msExitFullscreen?: () => Promise<void>;
        webkitExitFullscreen?: () => Promise<void>;
        mozFullScreenElement?: Element;
        msFullscreenElement?: Element;
        webkitFullscreenElement?: Element;
        onwebkitfullscreenchange?: any;
        onmsfullscreenchange?: any;
        onmozfullscreenchange?: any;
    }

    interface HTMLElement {
        msRequestFullScreen?: () => Promise<void>;
        mozRequestFullScreen?: () => Promise<void>;
        webkitRequestFullScreen?: () => Promise<void>;
    }
}

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign     : 'center',
    color         : theme.palette.text.secondary,
    padding       : theme.spacing(1),
    pointerEvents : 'auto',
}))

type MapRightBottomButtonsProps = {
}

type RoundButtonProps = {
    xs?: number,
    children?: any,
    onPress?: any
}

type RoundButtonIconContentProps = {
    children?: any,
}

type PopOverItemProps = {
    children?: any,
    onPress: () => void
}

const RoundButton: React.FC<RoundButtonProps> = ({ xs, onPress, children }) => (
    <Grid item xs={xs} >
        <Item sx={{
            padding      : 0,
            borderRadius : 3,
        }}>
            <Button onClick={onPress} variant="contained" color="inherit" sx={{
                width           : '100%',
                height          : '100%',
                display         : 'flex',
                borderRadius    : 3,
                justifyContent  : 'normal',
                padding         : 1,
                backgroundColor : 'white',
                minWidth        : 0,
                zIndex          : 2147483647,
            }}>{children}</Button>
        </Item>
    </Grid>
)

const RoundButtonIconContent: React.FC<RoundButtonIconContentProps> = ({ children }) => (
    <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flex: 1 }}>
        {children}
    </div>
)

const PopOverItem: React.FC<PopOverItemProps> = ({ onPress, children }) => (
    <Button onClick={onPress} disableElevation variant="contained" color="inherit" sx={{
        width           : '100%',
        height          : '100%',
        display         : 'flex',
        justifyContent  : 'normal',
        padding         : 2,
        backgroundColor : 'white',
        minWidth        : 0,
        boxShadow       : 0,
    }}>
        {children}
    </Button>
)

const MapRightBottomButtons: React.FC<MapRightBottomButtonsProps> = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const dispatch = useDispatch()
    const mapRef = useSelector(context.selectors.getMapRef)
    const userVehicles  = useSelector((state: any) => sortBy(
        vehicles.selectors.getAll(state),
        (vehicle) => toLower(get(vehicle, 'name'))
    ))
    const fitBoundsWithPadding = useFitBoundsWithPadding()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(anchorEl) {
            return setAnchorEl(null)
        }

        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const setMap = React.useCallback((type) => {
        dispatch(setMapType(type))
    }, [dispatch])

    function isFullscreen(element: HTMLElement) {
        return (
            (document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement) === element
        )
    }

    const resetPosition = React.useCallback(() => {
        if (mapRef) {
            const points = userVehicles.reduce((acc: any,item)=>{
                const cInfo = item.current_info
                if(!cInfo){
                    return acc
                }


                return acc.concat(
                    {
                        lat : item.current_info?.lat,
                        lng : item.current_info?.lng,
                    }
                )
            }, [])

            fitBoundsWithPadding(points)
        }
    }, [fitBoundsWithPadding, mapRef, userVehicles])

    const setFullScreen = React.useCallback(() => {
        const elementToSendFullscreen = mapRef.map.getDiv().firstChild

        const options = {
            navigationUI: 'show',
        }
        if(isFullscreen(elementToSendFullscreen)) {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
            }
        } else {
            if (elementToSendFullscreen.requestFullscreen) {
                elementToSendFullscreen.requestFullscreen(options)
            } else if (elementToSendFullscreen.webkitRequestFullScreen) {
                elementToSendFullscreen.webkitRequestFullScreen(options)
            } else if (elementToSendFullscreen.mozRequestFullScreen) {
                elementToSendFullscreen.mozRequestFullScreen(options)
            } else if (elementToSendFullscreen.msRequestFullScreen) {
                elementToSendFullscreen.msRequestFullScreen(options)
            }
        }
    }, [mapRef])

    return (
        <Box sx={{
            position       : 'absolute',
            bottom         : 15,
            right          : 15,
            pointerEvents  : 'none',
            padding        : 2,
            flexDirection  : 'row',
            justifyContent : 'space-between',
            zIndex         : 999,
        }} >
            <Grid container direction="column" spacing={2} sx={{
                display        : 'flex',
                justifyContent : 'end',
            }}>
                <RoundButton onPress={handleClick}>
                    <RoundButtonIconContent>
                        <Icon
                            source={layersImg}
                            size={15}
                            alt={'layers'}
                            style={{ margin: 2 }}
                        />
                    </RoundButtonIconContent>
                </RoundButton>
                <RoundButton onPress={resetPosition}>
                    <RoundButtonIconContent>
                        <Icon
                            source={centerImg}
                            size={15}
                            alt={'layers'}
                            style={{ margin: 2 }}
                        />
                    </RoundButtonIconContent>
                </RoundButton>
                <RoundButton onPress={setFullScreen}>
                    <RoundButtonIconContent>
                        <Icon
                            source={fullscreenImg}
                            size={15}
                            alt={'layers'}
                            style={{ margin: 2 }}
                        />
                    </RoundButtonIconContent>
                </RoundButton>
            </Grid>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical   : 'bottom',
                    horizontal : 'left',
                }}
                transformOrigin={{
                    vertical   : 'bottom',
                    horizontal : 'right',
                }}
                sx={{
                    zIndex: 2147483647,
                }}
            >
                <PopOverItem onPress={() => setMap('roadmap')}>
                    <MapIcon
                        sx={{ fontSize: 18 }}
                    />
                </PopOverItem>
                <PopOverItem onPress={() => setMap('satellite')}>
                    <SatelliteAltIcon
                        sx={{ fontSize: 18 }}
                    />
                </PopOverItem>
                <PopOverItem onPress={() => setMap('terrain')}>
                    <TerrainIcon
                        sx={{ fontSize: 18 }}
                    />
                </PopOverItem>
            </Popover>
        </Box>
    )
}

export default MapRightBottomButtons
