import { createStore as createReduxStore, applyMiddleware, Reducer } from 'redux'
import { createLogger } from 'redux-logger'
import { persistStore, persistCombineReducers } from 'redux-persist'
import webStorage from "redux-persist/lib/storage"
import * as RC from '@safefleet/react-common'

import createSagaMiddleware from 'redux-saga'

import { fork, all } from 'redux-saga/effects'

import { map, compact, mapValues, pickBy, identity } from 'lodash'

import { AppAction, AuthState, PreferencesState } from '@safefleet/react-common'
import * as reduxModules from './index'

const reducers = pickBy(mapValues(reduxModules, 'reducer'), identity)
const sagas = compact(map(reduxModules, 'saga'))

const persistedReducers = [
    'auth',
    'preferences',
]

const persistConfig = {
    key       : 'root',
    timeout   : 10 * 1000,
    storage   : webStorage,
    whitelist : persistedReducers,
}

const appReducer = persistCombineReducers(persistConfig, reducers)

const rootReducer = (state: { auth: AuthState, _persist: any, preferences: PreferencesState }, action: AppAction) => {
    if (action.type === reduxModules.auth.actions.LOGOUT) {
        const { auth, _persist, preferences } = state
        const { authServer, credentials } = auth  // Do not delete auth server & credentials at logout

        return appReducer({ auth: { authServer, credentials }, preferences, _persist }, action)
    }

    return appReducer(state, action)
}
function* rootSaga() {
    yield all(map(sagas, (saga) => fork(saga)))
}

function createStore(reducer: Reducer) {
    const middleware: any[] = []

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        const logger = createLogger({
            level     : 'info',
            collapsed : true,
            timestamp : true,
            duration  : true,
        })
        middleware.push(logger)
    }

    const sagaMiddleware = createSagaMiddleware()
    middleware.push(sagaMiddleware)

    const store = createReduxStore(
        reducer,
        undefined,
        applyMiddleware(...middleware)
    )

    sagaMiddleware.run(rootSaga)

    RC.auth.jwtProvider(store)

    return store
}

export const store = createStore(rootReducer)
export const persistor = persistStore(store)
