import React  from 'react'
import { useSelector } from 'react-redux'
import { map,first, toArray, uniqBy } from 'lodash'
import { ui } from '../redux'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { ToastModel } from '@safefleet/react-common'


const Alert: any = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={(ref as any)} variant="filled" {...props} />
})

const ToastsList: React.FC = () => {
    const items = useSelector(ui.getToasts)
    const filteredItems = uniqBy(toArray(items), 'message')

    const mapIntentToSeverity = ({ intent }:ToastModel) => {
        switch(intent){
            case 'danger':
            case 'error':
                return 'error'
            case 'primary':
                return 'info'
            case 'default':
                return undefined
            default:
                return intent
        }

    }
    const child = first(map(filteredItems, (toast) =>{
        const { id,message } = toast
        return (
            <Alert key={id} severity={mapIntentToSeverity(toast)} sx={{ width: '100%' }}>
                {message}
            </Alert>
        )
    }))

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={!!filteredItems.length}
        >
            {child}
        </Snackbar>
    )
}


export default ToastsList
