import React from 'react'
import IconCmp from '../ui/Icon'
import * as redux from '../../redux'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import MostRecentJourneysList from './MostRecentJourneysList'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import JourneyDetails from './JourneyDetails'
import LoadingContainer from './LoadingContainer'
import Divider from '@mui/material/Divider'
import { DrawerBottom } from './DrawerBottom'
import { SectionRegular } from '../Text'
import FiltersIcon from '../../assets/img/filtersIcon.svg'
import { setFiltersPanelStatus } from '../../redux/context/actions'
import { spaces } from '../../theme'
import { useTheme } from '@mui/material'
import JourneysStatistics from './JourneysStatistics'
import { i18n } from '../../redux'

const JourneysList = ({ journeys }) => {
    const journeysIsGetting = useSelector(redux.journeys.selectors.isPendingForTag(redux.tags.JOURNEY_WITH_ADDRESSES))
    if (journeysIsGetting) {
        return (
            <LoadingContainer />
        )
    }

    if (journeys && journeys.length > 0) {
        return (
            <>
                <MostRecentJourneysList
                    journeys={journeys}
                />
                <JourneysStatistics
                    journeys={journeys}
                />
            </>
        )
    } else {
        return <div />
    }

}

const Journeys = () => {
    const theme = useTheme()
    let params = useParams()
    const dispatch = useDispatch()
    const journeyId = params.journeyId
    const vehicleId = parseInt(params.vehicleId || '0')
    const vehicle = useSelector(redux.vehicles.selectors.getForID(vehicleId))

    const journeys = useSelector((state: any) => redux.journeys.selectors.getForVehicleId(true)(state, { vehicleId }))
    const isInitialized = useSelector(redux.app.selectors.isInitialized)

    React.useEffect(() => {
        if(isInitialized) {
            dispatch(redux.journeys.actions.fetchJourneysWithAddresses({
                vehicleId   : vehicleId,
                startMoment : startOfDay(new Date()),
                stopMoment  : endOfDay(new Date()),
            }))
        }
    }, [dispatch, isInitialized, vehicleId])

    const panelStatus = useSelector(redux.context.selectors.getFiltersPanelStatus)

    const showFilters = React.useCallback(() => {
        dispatch(setFiltersPanelStatus(!panelStatus))
    }, [dispatch, panelStatus])

    React.useEffect(() => {
        if(journeyId) {
            dispatch(setFiltersPanelStatus(false))
        }

        return () => {
            dispatch(setFiltersPanelStatus(false))
        }
    }, [dispatch, journeyId])

    if (journeyId) {
        return (
            <JourneyDetails />
        )
    }

    return (
        <div
            style={{ paddingBottom: 50 }}
        >
            <div
                style={{
                    backgroundColor : theme.custom.periodSelector.background,
                    padding         : `${spaces.headerLine[1]}px`,
                    display         : 'flex',
                    flexDirection   : 'row',
                    justifyContent  : 'space-between',
                    alignItems      : 'center',
                }}
            >
                <SectionRegular>
                    {i18n.t('Showing most recent journeys')}
                </SectionRegular>
                <div
                    onClick={() => showFilters()}
                    style={{
                        backgroundColor : theme.custom.filters.background,
                        padding         : "8px",
                        display         : 'flex',
                        flexDirection   : 'row',
                        justifyContent  : 'center',
                        alignItems      : 'center',
                        borderRadius    : `${spaces.textLine[1]}px`,
                        paddingLeft     : `${spaces.headerLine[2]}px`,
                        paddingRight    : `${spaces.headerLine[2]}px`,
                        cursor          : 'pointer',
                    }}
                >
                    <IconCmp
                        source={FiltersIcon}
                        size={16}
                        alt={''}
                    />
                    <SectionRegular
                        sx={{
                            marginLeft: `${spaces.textLine[1]}px`,
                        }}
                    >
                        {i18n.t('Date & filters')}
                    </SectionRegular>
                </div>
            </div>
            <JourneysList
                journeys={journeys}
            />
            <Divider />
            <DrawerBottom vehicle={vehicle} hide={'journeys'} />
        </div>
    )
}

export default Journeys
