import { app, AppState, AppAction } from '@safefleet/react-common'
import { AppStateStatus } from '../../base'
import { STATE_CHANGED, ACTIVATED } from './actions'

const initialState: AppState = {
    ...app.initialState,
    state: 'active',
}

export function reducer(state = initialState, action: AppAction<undefined | { state: AppStateStatus }>): AppState {
    switch (action.type) {
        case STATE_CHANGED: {
            if (!action.payload) {
                throw new Error('Payload is required in STATE_CHANGED actions.')
            }

            return {
                ...state,
                state: action.payload.state,
            }
        }

        case ACTIVATED: {
            return {
                ...state,
                state: 'active',
            }
        }

        default:
            return app.reducer(state, action)
    }
}
