import { SectionText } from '../components/Text'
import optionsIcon from '../assets/img/options-icon.svg'
import arrowDownIcon from '../assets/img/arrow-down-icon.svg'
import IconCmp from '../components/ui/Icon'
import React from 'react'
import * as RX from '../redux'
import { useSelector } from 'react-redux'
import { spaces } from '../theme'
import { i18n } from '../redux'

type OptionsProps = {
    children?: any,
    height?: any,
}

const MapOptionsButton: React.FC<OptionsProps> = ({ children,height }) => {
    const [optionsPanelStatus, setOptionsPanelStatus] = React.useState(false)
    const drawerStatus = useSelector(RX.context.selectors.getDrawerStatus)
    const isDrawerOpen = drawerStatus === 'open'

    const getPaddingLeft = React.useCallback(() => {
        return isDrawerOpen ? 430 : 30
    }, [isDrawerOpen])

    return (
        <>
            <div
                onClick={() => setOptionsPanelStatus(!optionsPanelStatus)}
                style={{
                    position : 'fixed',
                    left     : getPaddingLeft(),
                    top      : 100,
                }}
            >
                <div
                    style={{
                        backgroundColor : 'white',
                        borderRadius    : spaces.textLine[1],
                        padding         : spaces.textLine[0],
                        flexDirection   : 'row',
                        alignItems      : 'center',
                        display         : 'flex',
                        cursor          : 'pointer',
                        boxShadow       : `0px 2px ${spaces.textLine[0]}px 0px #0000001A`,

                    }}
                >
                    <IconCmp
                        source={optionsIcon}
                        size={20}
                        alt={''}
                    />
                    <SectionText
                        sx={{
                            marginLeft  : "7px",
                            marginRight : "17px",
                        }}
                    >
                        {i18n.t('Options')}
                    </SectionText>
                    <IconCmp
                        source={arrowDownIcon}
                        size={10}
                        alt={''}
                    />
                </div>
            </div>
            {
                optionsPanelStatus && (
                    <div
                        style={{
                            position    : 'absolute',
                            paddingLeft : getPaddingLeft(),
                            top         : 145,
                        }}
                    >
                        <div
                            style={{
                                backgroundColor : 'white',
                                borderRadius    : spaces.textLine[1],
                                padding         : spaces.headerLine[2],
                                display         : 'flex',
                                flexDirection   : 'column',
                                overflow        : 'scroll',
                                ...(height ? { height }:{}),
                            }}
                        >
                            {children}
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default MapOptionsButton
