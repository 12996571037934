import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Icon from './ui/Icon'
import thunderboltIcon from '../assets/img/thunderbolt.svg'
import reportsIcon from '../assets/img/reports.svg'
import deviceIcon from '../assets/img/device.svg'
import highwayIcon from '../assets/img/highway.svg'
import { useTheme } from '@mui/material/styles'
import { unfinishedFeatures } from '../utils'


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign     : 'center',
    color         : theme.palette.text.secondary,
    padding       : theme.spacing(1),
    pointerEvents : 'auto',
}))

type MapTopBarProps = {
}

type RoundButtonProps = {
    xs?: number,
    children?: any,
}

type RoundButtonIconContentProps = {
    children?: any
}

const RoundButton: React.FC<RoundButtonProps> = ({ xs, children }) => (
    <Grid item xs={xs} >
        <Item sx={{
            borderRadius : 16,
            padding      : 0,
        }}>
            <Button variant="contained" color="inherit" sx={{
                width           : '100%',
                height          : '100%',
                display         : 'flex',
                justifyContent  : 'normal',
                borderRadius    : 16,
                padding         : 1,
                flex            : 1,
                backgroundColor : 'white',
                minWidth        : 0,
            }}>{children}</Button>
        </Item>
    </Grid>
)

const RoundButtonIconContent: React.FC<RoundButtonIconContentProps> = ({ children }) => (
    <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flex: 1 }}>
        {children}
    </div>
)



const MapTopBar: React.FC<MapTopBarProps> = () => {
    const theme = useTheme()
    if(!unfinishedFeatures){
        return null
    }
    return (
        <Box sx={{
            position       : 'absolute',
            top            : theme.custom.appBar.height,
            width          : '100%',
            pointerEvents  : 'none',
            padding        : 2,
            flexDirection  : 'row',
            justifyContent : 'space-between',
        }} >
            <Grid container spacing={2} sx={{
                justifyContent: 'flex-end',
            }}>
                <RoundButton>
                    <RoundButtonIconContent>
                        <Icon
                            source={thunderboltIcon}
                            size={12}
                            alt={'thunderbolt'}
                            style={{ marginLeft: 4, marginRight: 4 }}
                        />
                    </RoundButtonIconContent>
                </RoundButton>
                <RoundButton >
                    <RoundButtonIconContent>
                        <Icon
                            source={reportsIcon}
                            size={18}
                            alt={'layers'}
                            style={{ margin: 1 }}
                        />
                    </RoundButtonIconContent>
                </RoundButton>
                <RoundButton >
                    <RoundButtonIconContent>
                        <Icon
                            source={deviceIcon}
                            size={18}
                            alt={'device'}
                            style={{ margin: 2 }}
                        />
                    </RoundButtonIconContent>
                </RoundButton>
                <RoundButton >
                    <RoundButtonIconContent>
                        <Icon
                            source={highwayIcon}
                            size={18}
                            alt={'highway'}
                            style={{ margin: 2 }}
                        />
                    </RoundButtonIconContent>
                </RoundButton>
            </Grid>

        </Box>
    )
}

export default MapTopBar
