import * as React from 'react'

export type LegendLineDescription = {
    name?: string,
    color: string,
}

type LegendLineProps = ExtraStyleProps & LegendLineDescription
type ExtraStyleProps = {
    dotStyle?: React.CSSProperties,
    textStyle?: React.CSSProperties,
}
type Props = {
    lines: LegendLineDescription[],
} & ExtraStyleProps

const LegendLine: React.FC<LegendLineProps> = ({
    color,
    name,
    dotStyle: dotStyleProp,
    textStyle: textStyleProp,
})=>{
    const dotWidth = '14px'
    const dotStyle: React.CSSProperties = {
        backgroundColor : color,
        borderRadius    : 5,
        width           : dotWidth,
        height          : dotWidth,
        marginRight     : '4px',
        marginLeft      : '10px',
        ...dotStyleProp,
    }
    const textStyle: React.CSSProperties = {
        color,
        fontSize: 11,
        ...textStyleProp,
    }
    return (
        <div style={{ display: 'flex',alignItems: 'center' }}>
            <div style={dotStyle} />
            <div style={textStyle} >{name || 'n/a'}</div>
        </div>
    )
}
const Legend : React.FC<Props> = ({
    lines,
    dotStyle,
    textStyle,
}) => {
    return (
        <div style={{
            display        : 'flex',
            alignItems     : 'center',
            justifyContent : 'flex-end',
            flexWrap       : 'wrap',
            marginTop      : '10px',
        }}>
            {lines.map((x,ix) => <LegendLine {...{
                ...x,
                dotStyle,
                textStyle,
                key: ix,
            }} />)}
        </div>
    )
}

export default Legend
