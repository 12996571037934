import * as React from 'react'
import { currentLocale } from '../../settings'
import * as HC from '../../hooks/vehicle'
import Divider from '@mui/material/Divider'
import { i18n, VehicleModel } from '@safefleet/react-common'
import constants from '../../constants'
import clock from '../../assets/img/square-clock.svg'
import engine from '../../assets/img/square-engine.svg'
import navigation from '../../assets/img/square-navigation.svg'
import gaspump from '../../assets/img/square-gas-pump.svg'
import battery from '../../assets/img/square-battery.svg'
import IconCmp from '../ui/Icon'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { SectionTitle,SectionSmallGray,SectionRegularGray, SectionText, style } from '../Text'
import { useSelector,useDispatch } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import * as RX from '../../redux'
import { get, includes, isEmpty, isNumber } from 'lodash'
import { DrawerBottom } from './DrawerBottom'
import VehicleActivity from './VehicleActivity'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/material/styles'
import { ReactComponent as Activity } from '../../assets/img/activity.svg'
import { ReactComponent as Caricon } from '../../assets/img/caricon.svg'
import { ReactComponent as Map } from '../../assets/img/map.svg'
import Journeys from './Journeys'
import LoadingContainer from './LoadingContainer'
import { spaces } from '../../theme'

const IMMOBILIZER = 'immobilizer'
const COMBUSTION_TYPE = ['Gasoline', 'Diesel', 'LPG']

type VehicleDetailsProps = {
    vehicle: VehicleModel,
}

//TODO: ugly hack
const removeUnknown = (str: string) => {
    var re = new RegExp('unknown', 'g')
    return str.replace(re, '')
}

type TopCellProps = {
    children?: React.ReactElement,
    label: string,
    value: any,
    isLeft: boolean,
    direction?: 'column' | 'row',
    valueStyle?: Object
    style?: Object
}
//TODO: extract this in other component
export const TopCell: React.FC<TopCellProps> = ({
    children,
    label,
    value,
    isLeft,
    direction = 'column',
    valueStyle = {},
    style={},
}) => {
    return (
        <div style={{
            display       : 'flex',
            flexDirection : direction,
            flex          : 1,
            alignItems    : isLeft ? 'flex-start' : 'flex-end',
            paddingBottom : 10,
            ...style,
        }}>
            <SectionTitle style={{ textAlign: isLeft ? 'left' : 'right' }}>
                {label}
            </SectionTitle>
            <SectionRegularGray
                sx={{
                    marginLeft: direction === 'column' ? 0 : `${spaces.textLine[2]}px`,
                    ...valueStyle,
                }}
            >
                {value} {children}
            </SectionRegularGray>
        </div>
    )
}

type TopCellReverseProps = {
    label: string,
    value: any,
    isLeft: boolean,
}
//TODO: extract this in other component
export const TopCellReverse: React.FC<TopCellReverseProps> = ({ label,value,isLeft }) => {
    return (
        <div style={{
            display       : 'flex',
            flexDirection : 'column',
            flex          : 1,
            alignItems    : isLeft ? 'flex-start' : 'flex-end',
            paddingBottom : spaces.headerLine[2],
        }}>
            <SectionSmallGray>
                {label.toUpperCase()}
            </SectionSmallGray>
            <SectionText
                sx={{
                    marginTop: `${spaces.textLine[1]}px`,
                }}
            >
                {value}
            </SectionText>
        </div>
    )
}

type TopProps = {
    vehicle: VehicleModel,
}


const Top: React.FC<TopProps> = ({ vehicle }) => {
    const makerModel = removeUnknown(`${vehicle.maker} ${vehicle.model}`).trim()
    const driver = vehicle.default_driver
    const { speed } = HC.useVehicleExtraValues(vehicle)
    const row = {
        display       : 'flex',
        flexDirection : 'row',
    } as React.CSSProperties
    return (
        <div style={{
            paddingTop: spaces.headerLine[2],
            ...styles.horizontalMargins,
        }}>
            <div style={row}>
                <TopCellReverse label={i18n.t('Driver')} value={driver ? `[${driver}]` : '-'} isLeft={true} />
                <TopCellReverse label={i18n.t('License Plate')} value={vehicle.license_plate} isLeft={false} />
            </div>
            <div style={row}>
                <TopCellReverse label={i18n.t('Car Model')} value={makerModel} isLeft={true} />
                <TopCellReverse label={i18n.t('Speed')} value={speed} isLeft={false} />
            </div>
        </div>
    )
}

type RowDetailsProps = {
    icon: any,
    label: any,
    details: any,
    alt?: string,
}
const RowDetails: React.FC<RowDetailsProps> = ({ icon, label, details, alt }) => {
    return (
        <div style={{
            display       : 'flex',
            flexDirection : 'row',
            marginTop     : `${spaces.headerLine[1]}px`,
        }}>
            <IconCmp
                source={icon}
                size={40}
                alt={alt || ''}
            />
            <div style={{
                marginLeft     : `${spaces.headerLine[1]}px`,
                display        : 'flex',
                flexDirection  : 'column',
                alignItems     : 'flex-start',
                justifyContent : 'space-between',
            }}>
                <SectionTitle >{label}</SectionTitle>
                <SectionSmallGray>{details}</SectionSmallGray>
            </div>
        </div>
    )
}

type DetailsWithIconsProps = {
    vehicle: VehicleModel,
}
const DetailsWithIcons: React.FC<DetailsWithIconsProps> = ({ vehicle }) => {
    const engineSince = HC.engineValue(vehicle)[1]
    const status = HC.getStatus(vehicle)
    const statusStr = status != null ? i18n.t(constants.vehicleStatus[status.toString()]) : 'n/a'
    const { on,off } = useTheme().custom.text
    const color={ color: status === 1 ? on : off }
    const locale = currentLocale()
    const momentVal = HC.getMomentValue(vehicle, locale, 'PPP - pp')
    const lat = vehicle.current_info?.lat
    const lng = vehicle.current_info?.lng
    const coords = lat && lng ? `${lat.toFixed(5)}, ${lng.toFixed(5)}` : null
    const tankPercentage = useTankOrBatteryLevel(vehicle)
    const fuelLevel = vehicle.current_info?.fuel_level
    const batteryLevel = vehicle.current_info?.battery_soc
    const isElectric = useFuelType(vehicle.vehicle_id) === 'Electric'
    const levelFromCurrentInfo = isElectric ? batteryLevel : fuelLevel

    return (
        <div style={styles.horizontalMargins} >
            <RowDetails
                icon={engine}
                alt="engine"
                label={<span>
                    {i18n.t('Engine')} <span style={color}>{statusStr?.toUpperCase()}</span>
                    {engineSince && ` ${i18n.t('since')} `}
                </span>}
                details={engineSince || '-'}
            />
            <RowDetails
                icon={clock}
                alt="clock"
                label={i18n.t('Last device messages')}
                details={momentVal}
            />
            <RowDetails
                icon={navigation}
                alt="nav"
                label={i18n.t('Coordinates')}
                details={coords}
            />
            <RowDetails
                icon={isElectric? battery : gaspump}
                alt="nav"
                label={isElectric?i18n.t('Battery level'):i18n.t('Fuel level')}
                details={tankPercentage || (levelFromCurrentInfo != null ? levelFromCurrentInfo.toFixed(2) + '%' : '-')}
            />
        </div>
    )
}

type DetailsProps = {
    vehicle: VehicleModel,
}
const useFuelType = (vehicleId: number) => useSelector(RX.fleetAttributes.selectors.getAttribute(
    vehicleId, 'fuel_type'
))?.value

const useBatteryCapacity = (vehicleId: number) => useSelector(RX.fleetAttributes.selectors.getAttribute(
    vehicleId, 'battery_capacity'
))?.value

const useTankCapacity = (vehicleId: number): number | undefined | null =>
    useSelector(RX.fleetAttributes.selectors.getAttribute(
        vehicleId, 'tank_capacity'
    ))?.value
const useSecondaryTankCapacity = (vehicleId: number): number | undefined | null =>
    useSelector(RX.fleetAttributes.selectors.getAttribute(
        vehicleId, 'secondary_tank_capacity'
    ))?.value
const useOtherTankCapacity = (vehicleId: number): number | undefined | null =>
    useSelector(RX.fleetAttributes.selectors.getAttribute(
        vehicleId, 'other_tank_capacity'
    ))?.value

const useCompoundedTankCapacity = (vehicleId: number) => {
    const tankCapacity =  useTankCapacity(vehicleId)
    const secondaryTankCapacity =  useSecondaryTankCapacity(vehicleId)
    const otherTankCapacity =  useOtherTankCapacity(vehicleId)

    return (tankCapacity || 0) + (secondaryTankCapacity || 0) + (otherTankCapacity || 0)
}
const useTankOrBatteryLevel = (
    vehicle: VehicleModel
) => {
    const fuelType = useFuelType(vehicle.vehicle_id)
    const batteryCapacity = useBatteryCapacity(vehicle.vehicle_id)
    const compoundedTankCapacity = useCompoundedTankCapacity(vehicle.vehicle_id)
    return React.useMemo(() => {
        const isElectric = fuelType === 'Electric'
        if (isElectric) {
            if (vehicle?.current_info?.battery_soc) {
                if (batteryCapacity) {
                    const availableBattery = vehicle.current_info.battery_soc * batteryCapacity / 100
                    return `${vehicle.current_info.battery_soc.toFixed(2)}% - ${availableBattery.toFixed(2)} kWh`
                }
                return `${vehicle.current_info.battery_soc.toFixed(2)}%`
            }
        } else if (includes(COMBUSTION_TYPE, fuelType)) {
            if (vehicle?.current_info?.fuel_level) {
                if (compoundedTankCapacity) {
                    const availableFuel  = vehicle.current_info.fuel_level * compoundedTankCapacity / 100
                    return `${vehicle.current_info.fuel_level.toFixed(2)}% - ${availableFuel.toFixed(2)} /
                    ${compoundedTankCapacity.toFixed(2)} L`
                }
                return `${vehicle.current_info.fuel_level.toFixed(2)}%`
            }
        }
    }, [
        batteryCapacity,
        compoundedTankCapacity,
        vehicle,
        fuelType,
    ])
}
const Details: React.FC<DetailsProps> = ({ vehicle }) => {
    const row = {
        display       : 'flex',
        flexDirection : 'row',
    } as React.CSSProperties
    const dispatch = useDispatch()
    const [showImmobModal, setShowImmoModal] = React.useState(false)
    const devSettings = useSelector(RX.deviceSettings.selectors.getForID(vehicle.vehicle_id))
    const odometer = useSelector(RX.odometer.selectors.getForID(vehicle.vehicle_id))
    const fuelType = useFuelType(vehicle.vehicle_id)
    const address = HC.useVehicleAddress(vehicle)
    const isElectric = fuelType === 'Electric'
    const immobilizerStatus =
        devSettings?.immobilizer.value !== undefined ?
            get(devSettings, [IMMOBILIZER, 'value']) : devSettings?.immobilizer

    const immobilizerValue = immobilizerStatus == null ? 'N/A' : immobilizerStatus ? 'ON' : 'OFF'


    const switchToggled = () => {
        setShowImmoModal(true)
    }

    const toggleImmobilizer = () => {
        setShowImmoModal(false)
        const data = {
            name  : IMMOBILIZER,
            value : !immobilizerStatus,
        }
        dispatch(RX.deviceSettings.actions.updateDeviceSettings(
            {
                vehicleId : vehicle.vehicle_id,
                tag       : IMMOBILIZER,
                data,

            }
        ))
    }

    const requestImmobilizer = React.useCallback(() => {
        dispatch(RX.deviceSettings.actions.fetchDeviceSettings({ vehicleId: vehicle.vehicle_id, name: IMMOBILIZER }))
    }, [dispatch, vehicle.vehicle_id])

    const requestOdometer = React.useCallback(() => {
        dispatch(RX.odometer.actions.fetchVehicleOdometer({ vehicleId: vehicle.vehicle_id }))
    }, [dispatch, vehicle.vehicle_id])

    const getGpsSatellites = React.useCallback(() => {
        return vehicle?.current_info?.satellites
    }, [vehicle])

    const getGpsSignal = React.useCallback(() => {
        let signalIndicator = ""
        let signalColor = ""
        const pdop = vehicle.current_info?.pdop || 100

        if (pdop <= 2) {
            signalIndicator = i18n.t('Excellent')
            signalColor = 'green'
        } else if (pdop <= 5) {
            signalIndicator = i18n.t('Good')
            signalColor = 'darkcyan'
        } else if (pdop <= 10) {
            signalIndicator = i18n.t('Moderate')
            signalColor = 'orange'
        } else if (pdop <= 20) {
            signalIndicator = i18n.t('Acceptable')
            signalColor = 'orangered'
        } else {
            signalIndicator = i18n.t('Weak')
            signalColor = 'red'
        }

        return (
            <TopCell
                label={i18n.t('GPS Signal')}
                value={<span style={{ color: signalColor }}>{signalIndicator}</span>}
                isLeft={false}
            />
        )

    }, [vehicle.current_info?.pdop])

    React.useEffect(() => {
        requestImmobilizer()
        requestOdometer()
    }, [requestImmobilizer, requestOdometer])

    const odometerValue =
        isEmpty(odometer) ||
            !isNumber(odometer?.odometer) ?
            'N/A' : HC.distance(odometer?.odometer)

    const connected = get(vehicle, 'current_info.charging_cable_connected', undefined)
    const range = get(vehicle, 'current_info.vrob', undefined)
    const batteryCharging = vehicle?.current_info?.battery_charging || false
    const batterySoc = vehicle?.current_info?.battery_soc
    const validAxleWeights = React.useMemo(() => {
        return vehicle?.current_info?.axle_weights?.filter((axle_weight) => axle_weight)
    }, [vehicle?.current_info?.axle_weights])
    const axleWeightsCol1 = validAxleWeights &&
    validAxleWeights.filter((_, index) => index % 2 ===0)
    const axleWeightsCol2 = validAxleWeights &&
    validAxleWeights.filter((_, index) => index % 2 !==0)

    return (
        <div style={{
            paddingTop: 25,
            ...styles.horizontalMargins,
        }}>
            {
                vehicle?.current_info?.satellites != null ?  (
                    <div style={row}>
                        <TopCell
                            label={i18n.t('GPS Satellites')}
                            value={getGpsSatellites()}
                            isLeft={true}
                        />
                        {getGpsSignal()}
                    </div>
                ): null
            }
            <div style={row}>
                <TopCell label={i18n.t('Immobilizer state')} value={immobilizerValue} isLeft={true} >
                    {immobilizerStatus != null ?
                        <Switch
                            checked={immobilizerValue === 'ON' ? true : false}
                            onChange={switchToggled}
                            size="small"
                            inputProps={{ 'aria-label': 'controlled' }}
                        /> :
                        <></>
                    }
                </TopCell>
                <TopCell label={i18n.t('Odometer')} value={odometerValue} isLeft={false} />
            </div>
            {
                isElectric ? (
                    <>
                        <div style={row}>
                            <TopCell label={i18n.t('Battery SoC')} value={batterySoc + " %"} isLeft={true} />
                            <TopCell label={i18n.t('Range on battery')} value={range + " km"} isLeft={false} />
                        </div>
                        <div style={row}>
                            <TopCell
                                label={i18n.t('Charging cable status')}
                                value={connected ? "connected" : "not connected"}
                                isLeft={true}
                            />
                            <TopCell
                                label={i18n.t('Battery charging status')}
                                value={batteryCharging ? "charging" : "not charging"}
                                isLeft={false}
                            />
                        </div>
                        <div style={row}>
                        </div>
                    </>
                ) : null
            }
            <div style={row}>
                {
                    vehicle?.current_info?.accel_pedal ? (
                        <TopCell
                            label={i18n.t('Acceleration pedal position')}
                            value={vehicle?.current_info?.accel_pedal + '%'}
                            isLeft={true} />
                    ) : null
                }
                {
                    vehicle?.current_info?.axle_weight ? (
                        <TopCell
                            label={i18n.t('Axle weight')}
                            value={vehicle?.current_info?.axle_weight + 'kg'}
                            isLeft={false} />
                    ) : null
                }
            </div>

            <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    {
                        axleWeightsCol1 &&
                    axleWeightsCol1.map((axle_weight) => (
                        <TopCell
                            label={i18n.t('Axle weight')}
                            value={axle_weight + 'kg'}
                            isLeft={true} />
                    ))
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    {
                        axleWeightsCol2 &&
                    axleWeightsCol2.map((axle_weight) => (
                        <TopCell
                            label={i18n.t('Axle weight')}
                            value={axle_weight + 'kg'}
                            isLeft={false} />
                    ))
                    }
                </div>
            </div>
            <div style={row}>
                {
                    vehicle?.current_info?.battery_voltage ? (
                        <TopCell
                            label={i18n.t('Engine starter battery voltage')}
                            value={vehicle?.current_info?.battery_voltage + "V"}
                            isLeft={true} />
                    ) : null
                }
            </div>
            <div style={row}>
                {
                    address && (
                        <TopCell
                            label={i18n.t('Vehicle location')}
                            value={address}
                            isLeft={true} />
                    )
                }
            </div>
            <Modal
                open={showImmobModal}
                onClose={() => {setShowImmoModal(false)}}
                aria-describedby="parent-modal-description"
            >
                <Box sx={{
                    position  : 'absolute',
                    top       : '50%',
                    left      : '50%',
                    transform : 'translate(-50%, -50%)',
                    width     : 400,
                    bgcolor   : 'background.paper',
                    border    : '2px solid #000',
                    boxShadow : 24,
                    pt        : 2,
                    px        : 4,
                    pb        : 3,
                }}>
                    <p id="parent-modal-description">
                        {i18n.t('Are you sure you want to change immobilizer state?')}
                        <Button
                            sx={{
                                position : 'absolute',
                                top      : '70%',
                                left     : '60%',
                            }}
                            onClick={() => {setShowImmoModal(false)}}
                        >
                            {(i18n.t('Cancel')).toUpperCase()}
                        </Button>
                        <Button
                            sx={{
                                position : 'absolute',
                                top      : '70%',
                                left     : '80%',
                            }}
                            onClick={() => toggleImmobilizer()}
                        >
                            {(i18n.t('Save')).toUpperCase()}
                        </Button>
                    </p>
                </Box>
            </Modal>
        </div>
    )
}

const VehicleDetailsInfo: React.FC<VehicleDetailsProps> = ({
    vehicle,
}) => {
    return (
        <>
            <DetailsWithIcons vehicle={vehicle} />
            <Details vehicle={vehicle} />
            <Divider />
            <DrawerBottom vehicle={vehicle} />
        </>
    )
}

function a11yProps(index: number) {
    return {
        "id"            : `full-width-tab-${index}`,
        'aria-controls' : `full-width-tabpanel-${index}`,
    }
}

const AntTabs = styled(Tabs)(({ theme }) => ({
    "borderBottom"         : '1px solid #e8e8e8',
    '& .MuiTabs-indicator' : {
        backgroundColor: theme.custom.text.button,
    },
    "height": "65px",
}))

const AntTab = styled((props: any) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        "textTransform"              : 'none',
        "minWidth"                   : 0,
        [theme.breakpoints.up('sm')] : {
            minWidth: 0,
        },
        "marginRight" : theme.spacing(1),
        "padding"     : 0,
        ...style(theme).sectionText,
        '&:hover'     : {
            opacity: 1,
        },
        '&.Mui-selected': {
            ...style(theme).sectionTitle,
            color: theme.custom.text.button,
        },
    })
)

const TabContent = ({ value, vehicle }) => {
    let component: any = undefined

    switch(value) {
        case 0:
            component = <VehicleDetailsInfo vehicle={vehicle}/>
            break
        case 1:
            component = <Journeys />
            break
        case 2:
            component = <VehicleActivity/>
            break
    }

    return component
}

const getTabNameByIndex = (index: number) => {
    let tabName: string = ''

    switch(index) {
        case 1:
            tabName = 'journeys'
            break
        case 2:
            tabName = 'activity'
            break
    }

    return tabName
}

const getTabIndexByName = (index: string) => {
    let tabIndex: number = 0

    switch(index) {
        case 'journeys':
            tabIndex = 1
            break
        case 'activity':
            tabIndex = 2
            break
    }

    return tabIndex

}

export const VehicleDetails: React.FC<VehicleDetailsProps> = ({
    vehicle,
}) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const vehicleId = pathname.split('/')[1]
    const [value, setValue] = React.useState(0)
    const tabIndex = getTabIndexByName(pathname.split('/')[2])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
        const newPath = getTabNameByIndex(newValue)
        navigate(`/${vehicleId}/${newPath}`)
    }

    React.useEffect(() => {
        setValue(tabIndex)
    }, [tabIndex])

    const isTabActive = React.useCallback((tab) => {
        return value === tab
    }, [value])

    const tabActiveColor = theme.custom.tab.active
    const tabInactiveColor = theme.custom.tab.inactive

    return (
        <div >
            <Top vehicle={vehicle} />
            <Box sx={{ width: '100%' }}>
                <AntTabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <AntTab
                        icon={<Caricon
                            fill={isTabActive(0) ? tabActiveColor : tabInactiveColor}
                        />}
                        iconPosition="start"
                        label={i18n.t("Current info")}
                        {...a11yProps(0)}
                    />
                    <AntTab
                        icon={<Map
                            fill={isTabActive(1) ? tabActiveColor : tabInactiveColor}
                        />}
                        iconPosition="start"
                        label={i18n.t("Journeys")}
                        {...a11yProps(1)} />
                    <AntTab
                        icon={<Activity
                            fill={isTabActive(2) ? tabActiveColor : tabInactiveColor}
                        />}
                        iconPosition="start"
                        label={i18n.t("Activity")}
                        {...a11yProps(2)} />
                </AntTabs>
            </Box>
            <TabContent
                vehicle={vehicle}
                value={value}
            />
        </div>
    )
}
type VehicleDetailsContainerProps = {
}
export const VehicleDetailsContainer: React.FC<VehicleDetailsContainerProps> = () => {
    let params = useParams()
    const vehicleId = parseInt(params.vehicleId || '')
    const vehicle = useSelector(RX.vehicles.selectors.getForID(vehicleId))

    return vehicle ?
        <VehicleDetails vehicle={vehicle} /> :
        <LoadingContainer/>
}

export default VehicleDetailsContainer


const styles = {
    horizontalMargins: {
        margin: `0px ${spaces.headerLine[1]}px`,
    } as React.CSSProperties,
}
