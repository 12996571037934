import * as React from 'react'
import { getChunks } from './base'

type Props = {
    segments: number[],
    style?: React.CSSProperties,
}

//TODO: improve style
const borderColor = '#C4C4C4'
const headerContainer: React.CSSProperties = {
    height        : '10px',
    //backgroundColor : '#FAFAFA',
    width         : '100%',
    borderBottom  : `1px solid ${borderColor}`,
    display       : 'flex',
    flexDirection : 'row',
}

type SegmentProps = {
    isLast: boolean,
    domainLen: number,
    len: number,
}
const Segment: React.FC<SegmentProps>  = ({
    isLast,
    domainLen,
    len,
}) => {
    return(
        <div style={{
            flex        : len*100/domainLen,
            borderRight : !isLast ? `1px solid ${borderColor}`: undefined,
        }} />
    )
}
const HeaderSegments : React.FC<Props> = ({ style,segments }) => {
    const domainLen = segments.length >= 2
        ?  segments[segments.length - 1] - segments[0]
        : 0

    const chunks = getChunks(segments)
    return (
        <div style={{ ...headerContainer,...style }} >
            {chunks.map((x,ix) => <Segment
                key={ix}
                isLast={ix === chunks.length - 1}
                domainLen={domainLen}
                len={x} />)}
        </div>
    )

}

export default HeaderSegments
