import { useRef,useEffect } from 'react'
import { isEqual } from "lodash"
// Hook
export const usePrevious = <T>(value: T): T | null => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
    const ref = useRef(null as T | null)
  // Store current value in ref
    useEffect(() => {
        ref.current = value
    }, [value]) // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
    return ref.current
}

export const useKeepReference = <T>(value: T): T  => {
    const ref = useRef(value)
    const isDeepEqual = isEqual(ref.current,value)
    // Store current value in ref
    useEffect(() => {
        if(!isDeepEqual){  // change the ref only if deeps equals
            ref.current = value
        }
    }, [isDeepEqual,value]) // Only re-run if value changes

    return isDeepEqual ? ref.current : value
}
