import React from 'react'
import SectionedJourneyItem from "./SectionedJourneyItem"

const SectionedJourneyList = ({ startDate, endDate, journeys }) => {

    return (
        <div style={{ paddingBottom: 50 }}>
            {
                journeys.reduce((acc, journey, index) => {
                    const startNumber = acc.startNumber + journeys?.[index-1]?.data?.length || 0
                    return {
                        startNumber : startNumber,
                        array       : acc.array.concat(
                            <SectionedJourneyItem
                                date={journey.date}
                                data={journey.data}
                                startNumber={startNumber}
                            />
                        ),
                    }
                }, {
                    startNumber : 0,
                    array       : [],
                }).array
            }
        </div>
    )
}

export default SectionedJourneyList